import axios from "axios"
import { CallStatus, ICall } from "../../../types/Call"
import { IPaginatedResponse } from "../../../types/pagination"

export async function fetchCalls(
    urlEndpoint: string = "/calls/",
    includeInternal: boolean = true,
    usePagination: boolean = true,
    page?: number,
    tags?: string[]
): Promise<IPaginatedResponse<ICall>> {
    const params = new URLSearchParams({
        include_internal: includeInternal.toString(),
    })

    if (usePagination && page !== undefined) {
        params.append("page", page.toString())
    }

    if (tags) {
        tags.forEach((tag) => params.append("tags", tag))
    }

    const response = await axios.get(
        process.env.REACT_APP_API_DOMAIN + urlEndpoint,
        {
            params,
        }
    )
    const data: IPaginatedResponse<ICall> = response.data

    data.records = data.records.map(setDefaultValues)
    return data
}

const defaultCallAttributes: Partial<ICall> = {
    title: "Untitled Call",
    parties: [],
    transcript_turns: [],
    annotations: {},
    status: CallStatus.Failed,
}

function setDefaultValues(call: Partial<ICall>): ICall {
    return { ...defaultCallAttributes, ...call } as ICall
}
