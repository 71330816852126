import { ICall } from "../types/Call"

export async function sendFollowUpEmailForCall(
    message: string,
    call: ICall
): Promise<void> {
    const participantEmails: string[] =
        call?.parties
            .map((party) => party.email ?? "")
            .filter((email) => email.trim()) ?? []
    const callTitle = call?.title
    const defaultSubject = callTitle
        ? `Follow up from our call: ${callTitle}`
        : "Follow up"

    const { subject, body } = extractSubjectAndBodyFromMessage(
        message,
        defaultSubject
    )
    const mailToLink = createEmailLink(participantEmails, subject, body)

    window.open(mailToLink, "_blank")
}

export async function sendFollowUpEmail(message: string): Promise<void> {
    const defaultSubject = "Follow up"
    const { subject, body } = extractSubjectAndBodyFromMessage(
        message,
        defaultSubject
    )
    const mailToLink = createEmailLink([], subject, body)
    window.open(mailToLink, "_blank")
}

export function createEmailLink(
    participantEmails?: string[],
    subject?: string,
    body?: string
): string {
    if (!(subject?.length || body?.length)) {
        throw new Error(
            "At least one of participantEmails, subject, or body must be provided"
        )
    }

    const encodedEmails = participantEmails
        ? participantEmails.map((email) => encodeURIComponent(email)).join(",")
        : ""
    const encodedSubject = subject ? encodeURIComponent(subject) : ""
    const encodedMessage = body ? encodeURIComponent(body) : ""
    return `mailto:${encodedEmails}?subject=${encodedSubject}&body=${encodedMessage}`
}

export function extractSubjectAndBodyFromMessage(
    message: string,
    defaultSubject: string
): {
    subject: string
    body: string
} {
    const subjectRegex = /^subject:(.*)$/im
    const match = message.match(subjectRegex)
    let subject = ""
    let body = ""

    if (match && match.index !== undefined) {
        subject = match[1].trim()
        body = message.slice(match.index + match[0].length).trim()
    } else {
        subject = defaultSubject
        body = message.trim()
    }

    return { subject, body }
}
