import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IParticipant } from "../../types/Call"
import { getParticipantDisplayName } from "../../utils/getParticipantDisplayName"
import { UserCircle } from "../UserCircle"
import {
    IconDefinition,
    faEarthAmericas,
    faEnvelope,
} from "@fortawesome/free-solid-svg-icons"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { crmIconFromUrl } from "../crm/utils/crmIconFromUrl"
import { LogoLink } from "../common/LogoLink"

export function Participant(props: { participant: IParticipant }) {
    const participant = props.participant
    const name = getParticipantDisplayName(participant)
    return (
        <div className="flex space-x-2 w-full overflow-clip">
            <UserCircle user={participant} size="40px" />

            <div className="flex min-w-0 w-full">
                <div className="min-w-0 space-y-1">
                    <div>
                        <div className="font-bold text-lg text-ellipsis overflow-clip">
                            {name}
                        </div>
                        <div className="text-base text-ellipsis overflow-clip">
                            {participant.profile?.job_title}
                        </div>
                    </div>
                    <div>
                        <ParticipantField
                            field={participant.email}
                            icon={faEnvelope}
                        />
                        <ParticipantField
                            field={participant.profile?.location}
                            icon={faEarthAmericas}
                        />
                    </div>
                </div>
            </div>

            {participant.profile?.crm_url && (
                <LogoLink
                    url={participant.profile.crm_url}
                    logo={
                        <FontAwesomeIcon
                            icon={crmIconFromUrl(participant.profile.crm_url)}
                        />
                    }
                />
            )}

            {participant.profile?.linkedin_url && (
                <LogoLink
                    url={participant.profile.linkedin_url}
                    logo={<FontAwesomeIcon icon={faLinkedin} />}
                />
            )}
        </div>
    )
}

function ParticipantField(props: {
    field: string | null | undefined
    icon: IconDefinition
}) {
    if (!props.field) {
        return <></>
    }
    return (
        <div className="flex text-gray-600 text-sm space-x-1">
            <FontAwesomeIcon
                className="text-gray-400 mt-[2px]"
                icon={props.icon}
            />
            <span className="overflow-clip text-ellipsis">{props.field}</span>
        </div>
    )
}
