import { faLandmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export function CompanyLogo(props: {
    image_url: string | null
    className?: string
}) {
    if (props.image_url === null) {
        return (
            <div
                className={`flex items-center justify-center h-10 w-10 rounded-md bg-gray-300 text-white text-xl ${props.className}`}
            >
                <FontAwesomeIcon icon={faLandmark} />
            </div>
        )
    }

    return (
        <img
            src={props.image_url}
            className={`inline-block h-10 w-10 object-scale-down rounded-md ${props.className}`}
            alt="Company logo"
        />
    )
}
