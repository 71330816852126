import { useEffect, useState } from "react"
import Registration from "./Registration"
import { useNavigate } from "react-router-dom"

export default function OrgView() {
    const navigate = useNavigate()
    const [accountSetup, setAccountSetup] = useState(false)

    useEffect(() => {
        if (accountSetup) {
            navigate("/home")
        }
    }, [accountSetup, navigate])

    if (accountSetup) {
        // We're going to redirect to /calls, so we don't need to render
        // anything
        return null
    }

    return <Registration onSuccess={() => setAccountSetup(true)} />
}
