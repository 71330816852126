import { useMutation } from "@tanstack/react-query"
import axios from "axios"
import { useNotification } from "../../../providers/NotificationProvider"
import { NotificationType } from "../../common/Notifcations"

interface UrlResult {
    url: string
    media_type: "audio" | "video"
    filename: string
}

interface UploadResult {
    urlResult: UrlResult
    filename: string
}

export const useFileUpload = (
    onUpload: (mediaType: "audio" | "video", filename: string) => void
) => {
    const { addNotification } = useNotification()

    const uploadMutation = useMutation<UploadResult, Error, File>({
        mutationFn: async (file: File) => {
            const urlResult: UrlResult = await getPresignedUrl(file.name)
            await axios.put(urlResult.url, file, {
                headers: {
                    "Content-Type": "binary/octet-stream",
                },
            })

            return { urlResult, filename: file.name }
        },
        onSuccess: (data: UploadResult) => {
            onUpload(data.urlResult.media_type, data.urlResult.filename)
        },
        onError: (error: Error) => {
            const errorMessage = axios.isAxiosError(error)
                ? error.response?.data["detail"]
                : error.message
            addNotification(
                "Failed to upload file",
                errorMessage,
                NotificationType.Error
            )
        },
    })

    const handleDrop = (acceptedFiles: File[]) => {
        acceptedFiles.forEach((file) => {
            uploadMutation.mutate(file)
        })
    }

    return {
        handleDrop,
        uploadStatus: uploadMutation.status,
        filename: uploadMutation.data?.filename,
    }
}

async function getPresignedUrl(filename: string) {
    const { name, extension } = getNameAndExtension(filename)
    const ts = new Date().getTime()
    const encodedFileName = encodeURIComponent(`${name}-${ts}.${extension}`)
    const result = await axios.get(
        `${process.env.REACT_APP_API_DOMAIN}/media/${encodedFileName}/url?method=put`
    )
    return result.data
}

function getNameAndExtension(filename: string): {
    name: string
    extension: string
} {
    const lastDotIndex = filename.lastIndexOf(".")
    if (lastDotIndex === -1) {
        throw new Error("Filename has no extension")
    }

    const name = filename.substring(0, lastDotIndex)
    const extension = filename.substring(lastDotIndex + 1)

    return { name, extension }
}
