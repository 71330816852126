import _ from "lodash"
import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { CrmTable, ICrmObjectReference } from "../crm/types/Crm"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { crmIconFromUrl, iconFromCrmTable } from "../crm/utils/crmIconFromUrl"
import { HashLink } from "react-router-hash-link"
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons"
import { getHeapInstance } from "../../utils/heap"

const CrmTableOrder = [
    CrmTable.Deals,
    CrmTable.Companies,
    CrmTable.Contacts,
    CrmTable.Notes, // added for completion, shouldn't be used
    CrmTable.Users, // added for completion, shouldn't be used
]

export function CrmRecords(props: { callId: string }) {
    const {
        data: crmObjects,
        isPending,
        isError,
        error,
    } = useQuery<ICrmObjectReference[]>({
        queryKey: [`calls/${props.callId}/crm/objects`],
        queryFn: async () => {
            const response = await axios.get(
                process.env.REACT_APP_API_DOMAIN +
                    `/calls/${props.callId}/crm/objects`
            )
            const crmObjects = response.data as ICrmObjectReference[]
            return _.orderBy(
                crmObjects,
                [
                    "has_synced",
                    (crmObject) => CrmTableOrder.indexOf(crmObject.crm_table),
                    "name",
                ],
                ["desc", "asc", "asc"]
            )
        },
    })

    const noCrmConntected =
        isError && axios.isAxiosError(error) && error.response?.status === 412

    return (
        <div className="flex gap-2 w-full items-start h-8">
            <h3 className="text-base font-bold flex-none">CRM Records:</h3>
            {isPending ? (
                <div className="h-full w-48 animate-pulse bg-slate-100 rounded" />
            ) : (
                <div className="flex gap-1 overflow-hidden overflow-x-auto">
                    {crmObjects?.map((crmObject) => (
                        <CrmObjectLink
                            key={crmObject.crm_object_id}
                            crmObject={crmObject}
                        />
                    ))}
                    {!crmObjects?.length && (
                        <span className="space-x-2">
                            <span>None</span>
                            {noCrmConntected && <MissingCRMIntegration />}
                        </span>
                    )}
                </div>
            )}
        </div>
    )
}

function MissingCRMIntegration() {
    return (
        <HashLink smooth to="/settings#crm">
            <span
                className="text-sm rounded px-2 py-1 whitespace-nowrap overflow-hidden overflow-ellipsis
                text-gray-600 border-gray-200 bg-gray-50 hover:bg-gray-200 border transition-colors"
            >
                Connect CRM to enable sync
            </span>
        </HashLink>
    )
}

function CrmObjectLink(props: { crmObject: ICrmObjectReference }) {
    const displayName = props.crmObject.name || props.crmObject.email
    return (
        <a
            className="flex items-center gap-1 w-fit max-w-full px-2 py-1 rounded text-sm
                     text-gray-800 border-gray-200 bg-gray-50 hover:bg-gray-200 border transition-colors"
            href={props.crmObject.crm_object_url}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
                getHeapInstance()?.track("crm-record-link-click", {
                    crm_table: props.crmObject.crm_table,
                    has_synced: props.crmObject.has_synced,
                })
            }}
        >
            <FontAwesomeIcon
                icon={crmIconFromUrl(props.crmObject.crm_object_url)}
            />
            <FontAwesomeIcon
                icon={iconFromCrmTable(props.crmObject.crm_table)}
            />
            <span className="whitespace-nowrap overflow-hidden overflow-ellipsis inline-block">
                {displayName}
            </span>
            {props.crmObject.has_synced && (
                <div
                    className="bg-green-100 ml-1 text-green-600 rounded-full flex flex-none"
                    data-tooltip-id="tooltip-explanation"
                    data-tooltip-content={`Successfully synced to '${displayName}'`}
                >
                    <FontAwesomeIcon size="lg" icon={faCircleCheck} />
                </div>
            )}
        </a>
    )
}
