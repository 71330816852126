import _ from "lodash"
import { IParticipant, IParticipantStats } from "../../types/Call"
import { getParticipantDisplayName } from "../../utils/getParticipantDisplayName"
import { UserCircle } from "../UserCircle"
import { useState } from "react"
import clsx from "clsx"
import colors from "tailwindcss/colors"
import { useAnimationDelay } from "../../utils/animationDelay"

const TALK_TIME_THRESHOLD = 10 // seconds

export function TalkStats(props: { participants: IParticipant[] }) {
    return (
        <div className="bg-white border rounded-lg p-3 text-base space-y-4 overflow-auto">
            <TalkTimeTable participants={props.participants} />
        </div>
    )
}

interface IParticipantWithStats extends IParticipant {
    // Once we've mapped null stats to their default values, we can guarantee stats is not null
    stats: IParticipantStats
}

function getParticipantsWithStats(
    participants: IParticipant[]
): IParticipantWithStats[] {
    return participants
        .filter(
            (participant) =>
                participant.stats &&
                participant.stats.talk_time_in_seconds > TALK_TIME_THRESHOLD
        )
        .map((participant) => participant as IParticipantWithStats)
}

function TalkTimeTable(props: { participants: IParticipant[] }) {
    const [highlightedIdx, setHighlightedIdx] = useState<number | null>(null)

    if (props.participants.length === 0) {
        return <div>No talk stats to display</div>
    }

    let participants = getParticipantsWithStats(props.participants)

    // participants sorted by talk time
    participants = _.orderBy(
        participants,
        (participant) => participant.stats.talk_time_in_seconds,
        "desc"
    )

    const maxWordsPerMinute = _.max(
        participants.map(
            (participant) => participant.stats.talk_speed_in_words_per_minute
        )
    )!

    // We need to define the talk time bar in its own cell as it will span multiple rows
    const talkTimeStackedBarCell = (
        <td
            rowSpan={participants.length}
            className="w-8 h-0 px-4" // We just need to set any height for the bar's h-full to work
        >
            <TalkTimeStackedBar
                proportions={participants.map(
                    (participant) => participant.stats.talk_time_percentage
                )}
                initialProportion={100 / props.participants.length}
                highlightedIdx={highlightedIdx}
                setHighlightedIdx={setHighlightedIdx}
            />
        </td>
    )

    return (
        <table className="w-full border-separate border-spacing-y-2">
            <thead className="text-left text-lg">
                <th>Participant</th>
                <th colSpan={3} className="w-52">
                    Talk Time
                </th>
                <th colSpan={2} className="w-52">
                    <span>Talk speed </span>
                    <span className="text-gray-400 text-base">(words/m)</span>
                </th>
            </thead>
            <tbody>
                {participants.map((participant, idx) => (
                    <TalkTimeRow
                        participant={participant}
                        color={colorForIndex(idx)}
                        isHighlighted={highlightedIdx === idx}
                        setIsHighlighted={(isHighlighted: boolean) =>
                            setHighlightedIdx(isHighlighted ? idx : null)
                        }
                        maxWordsPerMinute={maxWordsPerMinute}
                        talkTimeStackedBarCell={
                            // We add this cell which spans all table rows to the first row only
                            idx === 0 ? talkTimeStackedBarCell : null
                        }
                    />
                ))}
            </tbody>
        </table>
    )
}

function TalkTimeRow(props: {
    participant: IParticipantWithStats
    color: string
    isHighlighted: boolean
    setIsHighlighted: (isHighlighted: boolean) => void
    maxWordsPerMinute: number
    talkTimeStackedBarCell: JSX.Element | null
}) {
    const participant = props.participant
    const stats = participant.stats
    const maxWpm = props.maxWordsPerMinute
    const wordsPerMinutePct =
        maxWpm === 0 ? 0 : (stats.talk_speed_in_words_per_minute / maxWpm) * 100

    return (
        <tr key={props.participant.id}>
            <HighlightableCell
                className="rounded-l-xl"
                isHighlighted={props.isHighlighted}
                setIsHighlighted={props.setIsHighlighted}
            >
                <ParticipantCell
                    participant={participant}
                    color={props.color}
                    isHighlighted={props.isHighlighted}
                />
            </HighlightableCell>
            <HighlightableCell
                className="w-4 text-right"
                isHighlighted={props.isHighlighted}
                setIsHighlighted={props.setIsHighlighted}
            >
                {displayTalkTime(stats.talk_time_in_seconds)}
            </HighlightableCell>
            <HighlightableCell
                className="w-4 text-right rounded-r-xl"
                isHighlighted={props.isHighlighted}
                setIsHighlighted={props.setIsHighlighted}
            >
                {displayPercentageString(stats.talk_time_percentage)}
            </HighlightableCell>
            {props.talkTimeStackedBarCell}
            <HighlightableCell
                className="w-4 text-right rounded-l-xl"
                isHighlighted={props.isHighlighted}
                setIsHighlighted={props.setIsHighlighted}
            >
                {displayWordsPerMinute(stats.talk_speed_in_words_per_minute)}
            </HighlightableCell>
            <HighlightableCell
                className="text-right rounded-r-xl"
                isHighlighted={props.isHighlighted}
                setIsHighlighted={props.setIsHighlighted}
            >
                <HorizontalChartBar
                    widthPct={wordsPerMinutePct}
                    color={props.color}
                    isHighlighted={props.isHighlighted}
                />
            </HighlightableCell>
        </tr>
    )
}

function displayTalkTime(seconds: number): string {
    // Display talk time in mins
    return `${Math.round(seconds / 60)}m`
}

function displayPercentageString(percentage: number): string {
    return `${Math.round(percentage)}%`
}

function displayWordsPerMinute(wordsPerMinute: number): string {
    return Math.round(wordsPerMinute).toString()
}

function ParticipantCell(props: {
    participant: IParticipant
    color: string
    isHighlighted: boolean
}) {
    const participant = props.participant
    return (
        <div className="flex space-x-2 items-center">
            <div
                // Add border around the UserCircle in the user's colour
                className={clsx(
                    "flex items-center justify-center rounded-full transition-colors",
                    props.isHighlighted ? "opacity-100" : "opacity-70"
                )}
                style={{
                    backgroundColor: props.color,
                    width: "40px",
                    height: "40px",
                    minWidth: "40px",
                    minHeight: "40px",
                }}
            >
                <UserCircle user={participant} size="34px" />
            </div>
            <div className="flex flex-col">
                <span className="font-bold">
                    {getParticipantDisplayName(participant)}
                </span>
                {participant.email && (
                    <span className="text-sm text-gray-600">
                        {participant.email}
                    </span>
                )}
            </div>
        </div>
    )
}

function HighlightableCell(props: {
    children: React.ReactNode
    className?: string
    isHighlighted: boolean
    setIsHighlighted: (isHighlighted: boolean) => void
}) {
    // A cell that sets its highlighted state based on mouse over,
    // and applies a background colour when it is highlighted

    // We need to use this inside TalkTimeRow rather than (more simply) setting the highlight logic
    // on the row element, because we have the talkTimeStackedBarCell which spans multiple rows
    // This means we need to set highlight logic on individual cells (excluding talkTimeStackedBarCell)

    return (
        <td
            className={clsx(
                props.className,
                "p-2 transition-colors ease-out",
                props.isHighlighted && "bg-gray-100"
            )}
            onMouseOver={() => props.setIsHighlighted(true)}
            onMouseLeave={() => props.setIsHighlighted(false)}
        >
            {props.children}
        </td>
    )
}

const TalkTimeStackedBar = (props: {
    proportions: number[]
    initialProportion: number
    highlightedIdx: number | null
    setHighlightedIdx: (idx: number | null) => void
}) => {
    // Animate the bar proportions sizing a brief delay after the page loads
    const isAnimationTriggered = useAnimationDelay()
    return (
        <div className="flex-col h-full w-6 bg-gray-200 overflow-hidden rounded-lg">
            {props.proportions.map((percentage, index) => {
                const isHighlighted = index === props.highlightedIdx
                return (
                    <div
                        key={index}
                        style={{
                            height: isAnimationTriggered
                                ? `${percentage}%`
                                : `${props.initialProportion}%`,
                            backgroundColor: colorForIndex(index),
                        }}
                        className={clsx(
                            "w-full transition-all ease-in-out duration-500",
                            !isHighlighted && "opacity-70"
                        )}
                        onMouseOver={() => props.setHighlightedIdx(index)}
                        onMouseLeave={() => props.setHighlightedIdx(null)}
                    />
                )
            })}
        </div>
    )
}

const HorizontalChartBar = (props: {
    widthPct: number
    color: string
    isHighlighted: boolean
}) => {
    // Animate the bar appearing a brief delay after the page loads
    const isAnimationTriggered = useAnimationDelay()
    return (
        <div>
            <div
                className={clsx(
                    "h-6 rounded-lg transition-width duration-500 ease-in-out",
                    !props.isHighlighted && "opacity-70"
                )}
                style={{
                    backgroundColor: props.color,
                    width: isAnimationTriggered ? `${props.widthPct}%` : "0%",
                }}
            ></div>
        </div>
    )
}

function colorForIndex(index: number): string {
    const availableColors = [
        colors.violet[500],
        colors.cyan[500],
        colors.orange[500],
        colors.yellow[500],
        colors.emerald[500],
    ]
    return availableColors[index % availableColors.length]
}
