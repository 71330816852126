import AsyncSelect from "react-select/async"
import { ICrmContact } from "./types/Crm"
import { useCallback } from "react"

export type SelectedOption = { label: string; value: string }

interface SelectCrmContactProps {
    loadContacts: (inputValue: string) => Promise<ICrmContact[]>
    setSelectedValue: (value: any) => void
    selectedContacts?: SelectedOption[]
    className?: string
}

export function SelectCrmContact(props: SelectCrmContactProps) {
    const loadContacts = props.loadContacts
    const loadOptions = useCallback(
        async (inputValue: string) => {
            const contacts = await loadContacts(inputValue)
            return contacts.map(contactToOption)
        },
        [loadContacts]
    )

    return (
        <AsyncSelect
            className={props.className}
            cacheOptions
            isMulti
            placeholder="Search contacts..."
            loadOptions={loadOptions}
            value={props.selectedContacts}
            onChange={(value) => {
                props.setSelectedValue(value)
            }}
        />
    )
}

export function contactToOption(contact: ICrmContact): SelectedOption {
    return {
        // contact was found by email
        // so it's safe to assume that primaryEmail is not null
        value: contact.unifiedFields.primaryEmail!,
        label: getContactDisplayName(contact),
    }
}

function getContactDisplayName(contact: ICrmContact) {
    const email = contact.fields?.email?.trim()
    const name = contact.name?.trim()

    if (email && name) {
        return `${name} (${email})`
    } else if (email) {
        return email
    } else if (name) {
        return name
    } else {
        return contact.id
    }
}
