import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faSpinner,
    faCircle,
    faXmark,
    faLock,
} from "@fortawesome/free-solid-svg-icons"
import { CallStatus } from "../types/Call"

interface StatusProps {
    status: CallStatus
    reason?: string | null
}

export default function StatusIcon(props: StatusProps) {
    if (props.status === CallStatus.Cancelled) {
        return (
            <FontAwesomeIcon
                className={"text-gray-400 font-bold"}
                icon={faCircle}
                data-tooltip-id="tooltip-explanation"
                data-tooltip-content={
                    props.reason
                        ? `Call not processed: ${props.reason}`
                        : "Call not processed"
                }
            />
        )
    }

    if (props.status === CallStatus.Failed) {
        return (
            <FontAwesomeIcon
                className={"text-red-700 font-bold"}
                icon={faXmark}
                data-tooltip-id="tooltip-explanation"
                data-tooltip-content="Failed to process call"
            />
        )
    }

    if (props.status === CallStatus.InProgress || !props.status) {
        return (
            <FontAwesomeIcon
                className={"text-blue-700 font-bold"}
                icon={faSpinner}
                data-tooltip-id="tooltip-explanation"
                data-tooltip-content="Processing call"
                spin
            />
        )
    }

    return null
}

export function LockIcon() {
    return (
        <FontAwesomeIcon
            className={"text-gray-400 font-bold"}
            icon={faLock}
            data-tooltip-id="tooltip-explanation"
            data-tooltip-content={
                "This call is private and can only be accessed by the participants"
            }
        />
    )
}
