import { IParticipant } from "../../types/Call"
import { UserCircle } from "../UserCircle"
import {
    getParticipantDisplayName,
    getShortParticipantDisplayName,
} from "../../utils/getParticipantDisplayName"
import { partyComparatorByRelevance } from "./utils/partyComparators"

interface ParticipantsListProps {
    parties: IParticipant[]
    currentDomain?: string
}

export function CallPageParticipantsList(props: ParticipantsListProps) {
    if (!props.parties || props.parties.length === 0) {
        return <span>No one attended</span>
    }

    const parties = props.parties.sort(
        partyComparatorByRelevance(props.currentDomain)
    )

    return <ParticipantsList parties={parties} />
}

export function HorizontalParticipantList(props: { parties: IParticipant[] }) {
    return (
        <div className="flex flex-row flex-wrap gap-2">
            {props.parties.map((participant) => (
                <ParticipantWithName key={participant.id} party={participant} />
            ))}
        </div>
    )
}

function ParticipantWithName(props: { party: IParticipant }) {
    return (
        <div className="flex flex-row shrink-0 text-gray-900 space-x-1 items-center">
            <UserCircle user={props.party} size="22px" />
            <span>{getParticipantDisplayName(props.party)}</span>
        </div>
    )
}

function ParticipantsList(props: { parties: IParticipant[] }) {
    const maxUserCircles = 3
    const circledParties = props.parties.slice(0, maxUserCircles)

    const partyNames = props.parties
        .map((party) => getShortParticipantDisplayName(party))
        .join(", ")

    const otherCount = Math.max(0, props.parties.length - maxUserCircles)

    return (
        <div
            className="flex flex-row space-x-0.5 w-fit items-center overflow-hidden"
            data-tooltip-id="tooltip-explanation"
            data-tooltip-content={otherCount > 0 ? partyNames : ""}
        >
            {circledParties.map((party) => (
                <UserCircle key={party.id} user={party} size="22px" />
            ))}
            {otherCount > 0 && (
                <span className="rounded-full flex items-center justify-center w-6 h-6 text-gray-600 text-base">
                    {`+${otherCount}`}
                </span>
            )}
            {otherCount === 0 && (
                <span className="pl-2 overflow-hidden text-ellipsis">
                    {partyNames}
                </span>
            )}
        </div>
    )
}
