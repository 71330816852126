import { DateTime, Interval } from "luxon"

export enum DEAL_CLOSE_DATE_RANGE {
    LastMonth = "last_month",
    LastQuarter = "last_quarter",
    LastYear = "last_year",
    ThisMonth = "this_month",
    ThisQuarter = "this_quarter",
    ThisYear = "this_year",
    NextMonth = "next_month",
    NextQuarter = "next_quarter",
    NextYear = "next_year",
}

export function getTimeInterval(range: DEAL_CLOSE_DATE_RANGE): Interval {
    const now = DateTime.local()

    switch (range) {
        case DEAL_CLOSE_DATE_RANGE.LastMonth:
            return Interval.fromDateTimes(
                now.minus({ months: 1 }).startOf("month"),
                now.minus({ months: 1 }).endOf("month")
            )

        case DEAL_CLOSE_DATE_RANGE.LastQuarter:
            return Interval.fromDateTimes(
                now.minus({ quarters: 1 }).startOf("quarter"),
                now.minus({ quarters: 1 }).endOf("quarter")
            )

        case DEAL_CLOSE_DATE_RANGE.LastYear:
            return Interval.fromDateTimes(
                now.minus({ years: 1 }).startOf("year"),
                now.minus({ years: 1 }).endOf("year")
            )

        case DEAL_CLOSE_DATE_RANGE.ThisMonth:
            return Interval.fromDateTimes(
                now.startOf("month"),
                now.endOf("month")
            )

        case DEAL_CLOSE_DATE_RANGE.ThisQuarter:
            return Interval.fromDateTimes(
                now.startOf("quarter"),
                now.endOf("quarter")
            )

        case DEAL_CLOSE_DATE_RANGE.ThisYear:
            return Interval.fromDateTimes(
                now.startOf("year"),
                now.endOf("year")
            )

        case DEAL_CLOSE_DATE_RANGE.NextMonth:
            return Interval.fromDateTimes(
                now.plus({ months: 1 }).startOf("month"),
                now.plus({ months: 1 }).endOf("month")
            )

        case DEAL_CLOSE_DATE_RANGE.NextQuarter:
            return Interval.fromDateTimes(
                now.plus({ quarters: 1 }).startOf("quarter"),
                now.plus({ quarters: 1 }).endOf("quarter")
            )

        case DEAL_CLOSE_DATE_RANGE.NextYear:
            return Interval.fromDateTimes(
                now.plus({ years: 1 }).startOf("year"),
                now.plus({ years: 1 }).endOf("year")
            )

        default:
            throw new Error("Invalid date range type")
    }
}
