import { IInferredCrmFieldValue } from "../components/crm/types/Crm"
import { IAnnotation } from "./Annotation"

interface ParticipantProfile {
    job_title?: string
    location?: string
    photo_url?: string
    linkedin_url?: string
    crm_url?: string
}

export interface IParticipantStats {
    talk_time_in_seconds: number
    talk_time_percentage: number
    talk_speed_in_words_per_minute: number
}

export interface IParticipant {
    id: number
    name?: string | null
    email?: string | null
    phone_number?: string | null
    host?: boolean | null
    attendee?: boolean | null
    invitee?: boolean | null
    profile?: ParticipantProfile
    stats?: IParticipantStats | null
}

export type ParticipantContact = Pick<
    IParticipant,
    "name" | "email" | "phone_number"
>

export interface ITranscriptTurn {
    party_id: number
    timestamp: string
    turn_text: string
    turn_start: number
    turn_end: number
}

export enum MediaProvider {
    Gong = "gong",
    Zoom = "zoom",
    Recall = "recall",
    Manual = "manual_upload",
    Hubspot = "hubspot",
    Aircall = "aircall",
    SalesforceDialer = "salesforce_dialer",
}

export enum CallStatus {
    InProgress = "in_progress",
    Completed = "completed",
    Failed = "failed",
    Cancelled = "cancelled",
}

export interface IOrganization {
    domain: string
}

export interface ICompanyPreview {
    id: string
    domain: string
    name: string | null
    image_url: string | null
}

export interface ICallTag {
    id: string
    name: string
}

interface ICallMetadata {
    id: string
    title: string
    start_time: string
    duration: number | null
    companies: ICompanyPreview[]
    parties: IParticipant[]
    status: CallStatus
    status_reason: string | null
    language?: string | null
    media_provider: MediaProvider
    transcript_turns: ITranscriptTurn[]
    summary?: string
    is_private: boolean
    can_view: boolean
    tags?: ICallTag[]
}

export interface ICall extends ICallMetadata {
    annotations: Record<string, IAnnotation[]> | null
    inferred_crm_field_values?: IInferredCrmFieldValue[]
}

export interface ICallPreview extends ICallMetadata {
    summary?: string
}

export interface IShareOptions {
    expiry_date: string
    enable_ask_glyphic: boolean
    share_annotations: boolean
    share_custom_insights: boolean
    share_talk_stats: boolean
}

interface ISharedCallOptions extends IShareOptions {
    shared_by_name: string
    shared_by_email: string
}

export interface ISharedCall extends ICall {
    share_options: ISharedCallOptions
}

export interface INotes {
    sections: string[]
    text: string
}
