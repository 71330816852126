export function ObstaclesRisk(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M8 5.25V8.6875M8 11.4375H8.00733V11.4448H8V11.4375Z"
                stroke="#EF4444"
                strokeWidth="1.30952"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle cx="8" cy="8" r="7" stroke="#EF4444" strokeWidth="2" />
        </svg>
    )
}

export function ObstaclesMajor(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="18"
            height="17"
            viewBox="0 0 18 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M9 6.25V9.6875M9 12.4375H9.00642V12.4448H9V12.4375Z"
                stroke="#EAB308"
                strokeWidth="1.375"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.87582 15.3613C1.43444 15.3613 0.532639 13.8009 1.25375 12.5519L7.37732 1.93677C8.09843 0.687743 9.90203 0.687743 10.6231 1.93677L16.7467 12.5519C17.467 13.8009 16.5652 15.3613 15.1246 15.3613H2.87582Z"
                stroke="#EAB308"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export function ObstaclesConcerns(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M7.3125 7.3125L7.35008 7.29417C7.46763 7.23544 7.59954 7.21164 7.73019 7.22556C7.86085 7.23948 7.98478 7.29056 8.08731 7.37274C8.18983 7.45491 8.26667 7.56475 8.3087 7.68924C8.35074 7.81373 8.35622 7.94766 8.3245 8.07517L7.6755 10.6748C7.64355 10.8024 7.64886 10.9365 7.6908 11.0611C7.73274 11.1858 7.80955 11.2958 7.91213 11.3781C8.0147 11.4604 8.13872 11.5115 8.2695 11.5255C8.40027 11.5394 8.53229 11.5156 8.64992 11.4567L8.6875 11.4375M8 4.5625H8.00733V4.56983H8V4.5625Z"
                stroke="#84CC16"
                strokeWidth="1.375"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle cx="8" cy="8" r="7" stroke="#84CC16" strokeWidth="2" />
        </svg>
    )
}

export function ObstaclesNoIssues(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M5.25 8.6875L7.3125 10.75L10.75 5.9375"
                stroke="#22C55E"
                strokeWidth="1.375"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle cx="8" cy="8" r="7" stroke="#22C55E" strokeWidth="2" />
        </svg>
    )
}
