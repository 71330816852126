import { useNavigate } from "react-router-dom"

// Component that supports both efficient SPA useNavigate navigation on left click
// and opening a new tab on right click or ctrl+click

export function NavigateLink(props: {
    href: string
    children: React.ReactNode
    className?: string
    onClick?: () => void
}) {
    const navigate = useNavigate()

    function handleClick(e: React.MouseEvent<HTMLAnchorElement>) {
        e.preventDefault() // Disallow default <a> href navigation
        e.stopPropagation() // If this is a child of another clickable element, don't trigger that parent's click

        if (e.ctrlKey || e.metaKey) {
            window.open(props.href, "_blank")
        } else {
            navigate(props.href)
        }
        props.onClick?.()
    }
    return (
        // We use an <a> tag to allow the browser right click menu
        <a href={props.href} onClick={handleClick} className={props.className}>
            {props.children}
        </a>
    )
}
