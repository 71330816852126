import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ICompany } from "../../types/Company"
import { faSlack } from "@fortawesome/free-brands-svg-icons"
import { PrimaryButton, SecondaryButton } from "../common/Buttons"
import { useEffect, useState } from "react"
import { IChannel } from "../../types/Slack"
import axios from "axios"
import { Modal } from "../common/Modal"
import Select from "react-select"
import clsx from "clsx"
import { faLinkSlash } from "@fortawesome/free-solid-svg-icons"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"
import { useIntegrations } from "../../hooks/useIntegrations"
import { getHeapInstance } from "../../utils/heap"
import { CircleLoadingSpinner } from "../common/CircleLoadingSpinner"

export function CompanyOptions(props: { company: ICompany }) {
    return (
        <div className="flex space-x-4 justify-center items-center">
            <SlackChannelButton company={props.company} />
        </div>
    )
}

function SlackChannelButton(props: { company: ICompany }) {
    const { addNotification } = useNotification()
    const { hasSlack } = useIntegrations()
    const [linkedChannelName, setLinkedChannelName] = useState<string | null>(
        null
    )
    const [isChannelNameLoading, setIsChannelNameLoading] = useState(false)
    const isLinked = !!linkedChannelName
    const [showPopup, setShowPopup] = useState(false)
    const channel_id = props.company.slack_channel_id

    useEffect(() => {
        async function getLinkedChannelName(channelId: string | null) {
            setLinkedChannelName(null)
            if (!channelId) return

            setIsChannelNameLoading(true)
            try {
                const { data } = await axios.get(
                    `${process.env.REACT_APP_API_DOMAIN}/slack/channels/${channelId}`
                )
                setLinkedChannelName(data.name)
            } finally {
                setIsChannelNameLoading(false)
            }
        }
        getLinkedChannelName(channel_id)
    }, [channel_id])

    async function unlinkChannel() {
        try {
            await axios.put(
                `${process.env.REACT_APP_API_DOMAIN}/companies/${props.company.domain}/slack-channel`,
                { channel_id: null }
            )
            setLinkedChannelName(null)
            addNotification(
                "Slack channel unlinked successfully",
                "",
                NotificationType.Success
            )
        } catch (e) {
            addNotification(
                "Failed to unlink Slack channel",
                "",
                NotificationType.Error
            )
        }
    }

    return (
        <>
            <SecondaryButton
                className="group font-normal text-sm"
                disabled={!hasSlack || isChannelNameLoading}
                data-tooltip-id="tooltip-explanation"
                data-tooltip-content={
                    hasSlack
                        ? ""
                        : "Connect Slack in Glyphic settings to use this feature"
                }
                onClick={() => {
                    if (isLinked) {
                        getHeapInstance()?.track("deal-room-unlinked")
                        unlinkChannel()
                    } else {
                        getHeapInstance()?.track("deal-room-link-popup-opened")
                        setShowPopup(true)
                    }
                }}
            >
                <span
                    className={clsx(
                        "space-x-2",
                        isLinked && "group-hover:hidden"
                    )}
                >
                    <FontAwesomeIcon icon={faSlack} size="lg" />
                    <span>
                        {isChannelNameLoading
                            ? "Loading ..."
                            : linkedChannelName || "Link Deal Room"}
                    </span>
                </span>
                {isLinked && !isChannelNameLoading && (
                    <span className="space-x-2 hidden group-hover:inline">
                        <FontAwesomeIcon icon={faLinkSlash} size="1x" />
                        <span>{`Unlink ${linkedChannelName}`}</span>
                    </span>
                )}
            </SecondaryButton>

            <Modal isOpen={showPopup} onClose={() => setShowPopup(false)}>
                <LinkSlackChannelPopup
                    company={props.company}
                    dismissPopup={() => setShowPopup(false)}
                    setLinkedChannelName={setLinkedChannelName}
                />
            </Modal>
        </>
    )
}

function LinkSlackChannelPopup(props: {
    company: ICompany
    dismissPopup: () => void
    setLinkedChannelName: (channelName: string) => void
}) {
    const { addNotification } = useNotification()
    const [newChannelName, setNewChannelName] = useState<string>(
        getSuggestedChannelName(props.company)
    )
    const [slackChannels, setSlackChannels] = useState<IChannel[]>([])
    const [slackChannelsLoading, setSlackChannelsLoading] = useState(false)
    const [selectedChannel, setSelectedChannel] = useState<IChannel | null>(
        null
    )
    const [isCreatingChannel, setIsCreatingChannel] = useState(false)

    useEffect(() => {
        async function getSlackChannels() {
            setSlackChannelsLoading(true)
            const { data } = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/slack/channels`
            )
            setSlackChannels(data)
            setSlackChannelsLoading(false)
        }
        getSlackChannels()
    }, [])

    async function createChannel() {
        setIsCreatingChannel(true)
        getHeapInstance()?.track("deal-room-created")
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_DOMAIN}/companies/${props.company.domain}/slack-channel`,
                { channel_name: newChannelName }
            )
            const channel: IChannel = response.data
            props.setLinkedChannelName(channel.name)
            props.dismissPopup()
            addNotification(
                "Slack channel created successfully",
                "",
                NotificationType.Success
            )
        } catch (e) {
            const errorMessage =
                (axios.isAxiosError(e) && e.response?.data["detail"]) || ""
            addNotification(
                "Failed to create Slack channel",
                errorMessage,
                NotificationType.Error
            )
        } finally {
            setIsCreatingChannel(false)
        }
    }

    async function linkToChannel(channelId: string) {
        getHeapInstance()?.track("deal-room-linked")
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_API_DOMAIN}/companies/${props.company.domain}/slack-channel`,
                { channel_id: channelId }
            )
            const channel: IChannel = response.data
            props.setLinkedChannelName(channel.name)
            props.dismissPopup()
            addNotification(
                "Slack channel linked successfully",
                "",
                NotificationType.Success
            )
        } catch (e) {
            addNotification(
                "Failed to link Slack channel",
                "",
                NotificationType.Error
            )
        }
    }

    return (
        <div className="p-10 flex-col space-y-2 items-center font-normal">
            <div className="font-semibold">
                Link this company to a deal room in Slack
            </div>
            <div className="pb-4 text-gray-500 max-w-xl">
                Linked Slack channels will receive summaries of new calls, and
                allow you to chat with Ask Glyphic about this company from
                within Slack.
            </div>

            <div className="flex items-center space-x-2 w-full">
                <span>Create a new channel:</span>
                <input
                    type="text"
                    className="flex flex-grow h-9 focus:outline-none border-[1px]  border-gray-300 rounded-md px-2"
                    placeholder={"Enter channel name"}
                    value={`# ${newChannelName}`} // Prepend "# "
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setNewChannelName(e.target.value.slice(2)) // Strip "# "
                    }}
                />
                <PrimaryButton
                    onClick={createChannel}
                    disabled={isCreatingChannel}
                    className="w-32 flex justify-center"
                >
                    {isCreatingChannel ? (
                        <CircleLoadingSpinner />
                    ) : (
                        "Create channel"
                    )}
                </PrimaryButton>
            </div>
            <div className="flex w-full justify-center">or</div>
            <div className="flex items-center space-x-2">
                <span>Link an existing channel: </span>
                <Select
                    className="basic-single w-64"
                    classNamePrefix="select"
                    onChange={(option) => setSelectedChannel(option)}
                    isLoading={slackChannelsLoading}
                    isSearchable={true}
                    name="channel"
                    options={slackChannels}
                    getOptionValue={(ch) => ch.id}
                    getOptionLabel={(ch) => `# ${ch.name}`}
                    menuPortalTarget={document.body}
                    styles={{
                        menuPortal: (base) => ({
                            ...base,
                            zIndex: 100,
                        }),
                    }}
                />
                <PrimaryButton
                    onClick={() => linkToChannel(selectedChannel!.id)}
                    className="w-32"
                    disabled={!selectedChannel}
                >
                    Link channel
                </PrimaryButton>
            </div>
        </div>
    )
}

function getSuggestedChannelName(company: ICompany) {
    const nameOrDomain = company.name || company.domain.split(".")[0]

    const name = nameOrDomain
        .trim()
        .toLowerCase()
        .replace(/[^\w\s]/g, "") // remove punctuation
        .replace(/\s+/g, " ") // replace extra spaces with single space
        .replace(/ /g, "-") // replace spaces with hyphens

    return `deal-${name}`
}
