export interface IError {
    code?: number
    message?: string
}

export function ErrorPage(props: { error: IError }) {
    const errorCode = props.error.code || "Unknown"
    return (
        <div className="flex flex-col items-center justify-center pt-48 px-20 space-y-4">
            <h1 className="text-9xl font-bold text-delta">
                Error: {errorCode}
            </h1>
            <h2 className="text-2xl">{props.error.message}</h2>
        </div>
    )
}
