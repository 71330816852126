import React, { KeyboardEventHandler, useEffect, useRef, useState } from "react"
import axios from "axios"
import validator from "validator"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLink, faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons"
import { faPaperPlane, faTrashCan } from "@fortawesome/free-regular-svg-icons"

import { PrimaryButton, SecondaryButton } from "../common/Buttons"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"
import CreatableSelect from "react-select/creatable"
import { IParticipant, IShareOptions } from "../../types/Call"
import DividerLine from "../common/DividerLine"
import _ from "lodash"
import ToggleButton from "../common/ToggleButton"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useUser } from "../../providers/UserProvider"
import { getHeapInstance } from "../../utils/heap"

interface ShareOptionsState {
    enableAskGlyphic: boolean
    shareAnnotations: boolean
    shareCustomInsights: boolean
    shareTalkStats: boolean
}

export function ShareCallButton(props: {
    callId: string
    participants: IParticipant[]
}) {
    const [showPopup, setShowPopup] = useState(false)
    const popupRef = useRef<HTMLDivElement>(null)

    const handleButtonClick = () => {
        setShowPopup((prev) => !prev)
    }

    // Close the popup if the user clicks outside of it
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                popupRef.current &&
                !popupRef.current.contains(event.target as Node)
            ) {
                setShowPopup(false)
            }
        }

        document.removeEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [showPopup, popupRef])

    return (
        <div className="md:relative" ref={popupRef}>
            <button
                className="flex flex-col justify-center items-center text-sm text-gray-600 hover:text-gray-800"
                onClick={handleButtonClick}
            >
                <FontAwesomeIcon
                    icon={faArrowUpFromBracket}
                    className="w-4 h-4"
                />
                <span className="hidden md:block">Share</span>
            </button>
            {showPopup && (
                <ShareCallPopup
                    callId={props.callId}
                    participantEmails={getParticipantEmails(props.participants)}
                />
            )}
        </div>
    )
}

function ShareCallPopup(props: {
    callId: string
    participantEmails: string[]
}) {
    const { addNotification } = useNotification()
    const queryClient = useQueryClient()
    const user = useUser()

    const [expiryDays, setExpiryDays] = useState<number>(30)
    const [emailsToShare, setEmailsToShare] = useState<string[]>(
        _.uniq(props.participantEmails).filter((email) => email !== user?.email)
    )
    const expiryDaysMin = 1
    const expiryDaysMax = 365
    const sendEmailsEnabled = emailsToShare.length > 0
    const [startTimestamp, setStartTimestamp] = useState<string | null>(null)

    const [shareOptions, setShareOptions] = useState<ShareOptionsState>({
        enableAskGlyphic: false,
        shareAnnotations: false,
        shareCustomInsights: false,
        shareTalkStats: false,
    })

    const { data: currentSharedCall, status } = useQuery<IShareOptions>({
        queryKey: [props.callId, "shared_call_options"],
        queryFn: async () => {
            const response = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/calls/${props.callId}/share`
            )
            if (response.status === 204) {
                // Call has not been shared before
                return null
            }
            return response.data
        },
    })

    useEffect(() => {
        // Check if the query was successful and the data is loaded
        if (status === "success" && currentSharedCall) {
            // Map the IShareOptions keys to ShareOptionsState keys and set the state
            setShareOptions({
                enableAskGlyphic: currentSharedCall.enable_ask_glyphic,
                shareAnnotations: currentSharedCall.share_annotations,
                shareCustomInsights: currentSharedCall.share_custom_insights,
                shareTalkStats: currentSharedCall.share_talk_stats,
            })
        }
    }, [currentSharedCall, status])

    const handleOptionChange = (
        option: keyof ShareOptionsState,
        checked: boolean
    ) => {
        setShareOptions((prevOptions: ShareOptionsState) => ({
            ...prevOptions,
            [option]: checked,
        }))
    }

    function handleExpiryDaysChange(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const newValue = event.target.value
        if (
            parseInt(newValue) >= expiryDaysMin &&
            parseInt(newValue) <= expiryDaysMax
        ) {
            setExpiryDays(parseInt(newValue))
        }
    }

    const handleSendEmailsClicked = useMutation({
        mutationFn: async () => {
            const sharedCallUrl = await generateSharedCall(
                props.callId,
                expiryDays,
                emailsToShare,
                shareOptions,
                startTimestamp || undefined
            )
            addNotification(
                "Call shared!",
                `This call will be accessible to anyone for the next ${expiryDays} days at: ${sharedCallUrl}`,
                NotificationType.Success
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [props.callId, "shared_call_options"],
            })
        },
        onError: (error) => {
            addNotification(
                "Failed to share call!",
                `${error}`,
                NotificationType.Error
            )
        },
    })

    const handleCopyLinkClicked = useMutation({
        mutationFn: async () => {
            copyAsyncResultToClipboard(async () => {
                const text = await generateSharedCall(
                    props.callId,
                    expiryDays,
                    [],
                    shareOptions,
                    startTimestamp || undefined
                )
                queryClient.invalidateQueries({
                    queryKey: [props.callId, "shared_call_options"],
                })
                return text
            })
            addNotification(
                "Shareable link copied!",
                `This call will be accessible to anyone with the copied link for the next ${expiryDays} days.`,
                NotificationType.Success
            )
        },
        onError: (error) => {
            addNotification(
                "Failed to share call!",
                `${error}`,
                NotificationType.Error
            )
        },
    })

    return (
        <div className="z-10 text-base absolute right-0 min-w-max mt-2 py-3 px-3 bg-white border rounded-lg shadow-lg">
            <div className="space-y-2">
                <div>Share the call recording, summary and transcript.</div>
                <DividerLine />

                <ShareOptions
                    options={shareOptions}
                    onOptionChange={handleOptionChange}
                />
                <div className="inline-flex items-center gap-1 justify-center">
                    <span>Share recording from </span>
                    <TimestampInput
                        setTimestamp={setStartTimestamp}
                        timestamp={startTimestamp}
                    />
                    <span> onwards.</span>
                </div>

                <DividerLine />
                <EmailShareSection
                    emails={emailsToShare}
                    setEmails={setEmailsToShare}
                />
                <div>
                    <span>This link will expire in </span>
                    <input
                        className="border rounded-lg px-1 text-right w-14"
                        type="number"
                        min={expiryDaysMin}
                        max={expiryDaysMax}
                        value={expiryDays}
                        onChange={handleExpiryDaysChange}
                    />
                    <span> days.</span>
                </div>
                <DividerLine />
                <div className="flex justify-end space-x-2">
                    <UnshareButton
                        callId={props.callId}
                        disabled={!currentSharedCall}
                    />
                    <SecondaryButton
                        className="space-x-2"
                        onClick={() => handleCopyLinkClicked.mutate()}
                    >
                        <FontAwesomeIcon icon={faLink} />
                        <span>
                            {currentSharedCall ? "Update link" : "Copy link"}
                        </span>
                    </SecondaryButton>
                    <PrimaryButton
                        className="space-x-2"
                        disabled={!sendEmailsEnabled}
                        onClick={() => handleSendEmailsClicked.mutate()}
                    >
                        <FontAwesomeIcon icon={faPaperPlane} />
                        <span>Send emails</span>
                    </PrimaryButton>
                </div>
            </div>
        </div>
    )
}

function UnshareButton(props: { callId: string; disabled: boolean }) {
    const queryClient = useQueryClient()
    const { addNotification } = useNotification()

    const deleteShare = useMutation({
        mutationFn: async (variables: { callId: string }) => {
            return await axios.delete(
                `${process.env.REACT_APP_API_DOMAIN}/calls/${variables.callId}/share`
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [props.callId, "shared_call_options"],
            })
            addNotification(
                "Call unshared.",
                "This call will no longer be accessible to external users.",
                NotificationType.Success
            )
        },
        onError: (error) => {
            addNotification(
                "Failed to unshare call",
                "",
                NotificationType.Error
            )
        },
    })

    return (
        <SecondaryButton
            className="space-x-2"
            onClick={() => deleteShare.mutate({ callId: props.callId })}
            disabled={props.disabled}
        >
            <FontAwesomeIcon icon={faTrashCan} />
            <span>Unshare</span>
        </SecondaryButton>
    )
}

function ShareOptions(props: {
    options: ShareOptionsState
    onOptionChange: (option: keyof ShareOptionsState, checked: boolean) => void
}) {
    return (
        <div className="space-y-2">
            <span className="flex space-x-2">
                <ToggleButton
                    checked={props.options.shareAnnotations}
                    onChange={(checked) =>
                        props.onOptionChange("shareAnnotations", checked)
                    }
                />
                <span>Share Qualification</span>
            </span>
            <span className="flex space-x-2">
                <ToggleButton
                    checked={props.options.shareCustomInsights}
                    onChange={(checked) =>
                        props.onOptionChange("shareCustomInsights", checked)
                    }
                />
                <span>Share Custom Insights</span>
            </span>
            <span className="flex space-x-2">
                <ToggleButton
                    checked={props.options.shareTalkStats}
                    onChange={(checked) =>
                        props.onOptionChange("shareTalkStats", checked)
                    }
                />
                <span>Share Talk Stats</span>
            </span>
            <span className="flex space-x-2">
                <ToggleButton
                    checked={props.options.enableAskGlyphic}
                    onChange={(checked) =>
                        props.onOptionChange("enableAskGlyphic", checked)
                    }
                />
                <span>Enable Ask Glyphic</span>
            </span>
        </div>
    )
}

function EmailShareSection(props: {
    emails: string[]
    setEmails: React.Dispatch<React.SetStateAction<string[]>>
}) {
    interface SelectValue {
        readonly label: string
        readonly value: string
    }

    const createSelectValue = (label: string) => ({
        label,
        value: label,
    })

    const { addNotification } = useNotification()
    const [inputValue, setInputValue] = useState("")
    const [value, setValue] = useState<readonly SelectValue[]>(
        props.emails.map(createSelectValue)
    )
    const setEmails = props.setEmails

    useEffect(() => {
        const emails: string[] = _.uniq(
            value.map((selectValue) => selectValue.value)
        )
        setEmails(emails)
    }, [value, setEmails])

    const handleKeyDown: KeyboardEventHandler = (event) => {
        if (!inputValue) return
        switch (event.key) {
            case "Enter":
            case "Tab":
            case ",":
            case " ":
                enterValue()
                event.preventDefault()
        }
    }

    function enterValue() {
        if (!inputValue) return

        if (validator.isEmail(inputValue)) {
            setValue((prev) =>
                _.uniqBy([...prev, createSelectValue(inputValue)], "value")
            )
            setInputValue("")
        } else {
            addNotification(
                `Invalid email "${inputValue}"`,
                "Please enter a valid email address",
                NotificationType.Error
            )
        }
    }

    return (
        <div className="text-base space-y-2">
            <div>Share this call via email.</div>
            <CreatableSelect
                className="w-72"
                components={{
                    DropdownIndicator: null,
                }}
                inputValue={inputValue}
                isClearable
                isMulti
                onBlur={enterValue}
                menuIsOpen={false}
                onChange={(newValue) => setValue(newValue)}
                onInputChange={(newValue) => setInputValue(newValue)}
                onKeyDown={handleKeyDown}
                placeholder="Enter space-separated email addresses"
                value={value}
                // Override default styling of CreatableSelect
                styles={{
                    multiValue: (styles, { data }) => {
                        return {
                            ...styles,
                            borderRadius: "8px",
                            backgroundColor: "#F3F4F6",
                        }
                    },
                    control: (styles, state) => ({
                        ...styles,
                        borderRadius: "8px",
                    }),
                }}
            />
        </div>
    )
}

type SetTimestampFunction = (formattedInput: string) => void

interface TimestampInputProps {
    setTimestamp: SetTimestampFunction
    timestamp: string | null
}

function TimestampInput(props: TimestampInputProps) {
    const [isValid, setIsValid] = useState(true)

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value
        const filteredInput = input.replace(/\D/g, "")
        let formattedInput = ""
        if (filteredInput.length >= 2) {
            formattedInput = `${filteredInput.slice(
                0,
                -2
            )}:${filteredInput.slice(-2)}`
        } else {
            formattedInput = filteredInput
        }
        props.setTimestamp(formattedInput)
        const regex = /^(\d*:[0-9][0-9]|\d+)$/
        setIsValid(regex.test(formattedInput))
        if (input === "") {
            setIsValid(true)
        }
    }

    return (
        <div>
            <input
                className={`border rounded-lg px-2 py-1 justify-center inline-flex w-16 text-right ${
                    isValid ? "" : "border-red-500"
                }`}
                type="string"
                onChange={handleInputChange}
                placeholder="00:00"
                value={props.timestamp || undefined}
            />
        </div>
    )
}

function getParticipantEmails(participants: IParticipant[]): string[] {
    // Returns list of participant emails
    // If participants do not have an email, they are not returned

    let emails: string[] = []
    participants.forEach((p) => {
        if (p.email) {
            emails.push(p.email)
        }
    })
    return emails
}

async function generateSharedCall(
    call_id: string,
    expiryDays: number,
    emailsToNotify: string[],
    shareOptions: ShareOptionsState,
    startTimestamp?: string
): Promise<string> {
    getHeapInstance()?.track("share-call-clicked")
    const response = await axios.put(
        `${process.env.REACT_APP_API_DOMAIN}/calls/${call_id}/share`,
        {
            expiry_days: expiryDays,
            emails_to_notify: emailsToNotify,
            start_time: startTimestamp,
            enable_ask_glyphic: shareOptions.enableAskGlyphic,
            share_annotations: shareOptions.shareAnnotations,
            share_custom_insights: shareOptions.shareCustomInsights,
            share_talk_stats: shareOptions.shareTalkStats,
        }
    )
    return response.data.url
}

function copyAsyncResultToClipboard(fetchAsyncResult: () => Promise<string>) {
    // See https://wolfgangrittner.dev/how-to-use-clipboard-api-in-firefox/
    if (typeof ClipboardItem && navigator.clipboard.write) {
        // NOTE: Safari locks down the clipboard API to only work when triggered
        //   by a direct user interaction. You can't use it async in a promise.
        //   But! You can wrap the promise in a ClipboardItem, and give that to
        //   the clipboard API.
        //   Found this on https://developer.apple.com/forums/thread/691873
        const text = new ClipboardItem({
            "text/plain": fetchAsyncResult().then(
                (text) => new Blob([text], { type: "text/plain" })
            ),
        })
        navigator.clipboard.write([text])
    } else {
        // NOTE: Firefox has support for ClipboardItem and navigator.clipboard.write,
        //   but those are behind `dom.events.asyncClipboard.clipboardItem` preference.
        //   Good news is that other than Safari, Firefox does not care about
        //   Clipboard API being used async in a Promise.
        fetchAsyncResult().then((text) => navigator.clipboard.writeText(text))
    }
}
