import { useEffect, useState } from "react"

export const useAnimationDelay = (delayMs = 200) => {
    // A simple timer util used for triggering animations that should occur a
    // brief delay after the component's initial render
    const [isAnimationTriggered, setIsAnimationTriggered] = useState(false)

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsAnimationTriggered(true)
        }, delayMs)
        return () => clearTimeout(timeout)
    }, [delayMs])

    return isAnimationTriggered
}
