export enum SearchResultType {
    CALL = "call",
    COMPANY = "company",
}

export interface ISearchResultHighlightElement {
    value: string
    type: "text" | "hit"
}

export interface ISearchResultHighlight {
    field_name: string
    path: string
    score: number
    texts: ISearchResultHighlightElement[]
}

export interface ISearchResult {
    id: string
    score: number
    highlight: ISearchResultHighlight[]
    type: SearchResultType
}

export interface ICallSearchResultMetadata {
    title: string | null
    start_time: string | null
}

export interface ICallSearchResult extends ISearchResult {
    call_metadata: ICallSearchResultMetadata
}

export interface ICompanySearchResult extends ISearchResult {
    primary_domain: string
    name: string | null
    image_url: string | null
}
