import { IAnnotation, IAnnotationSection } from "../../types/Annotation"
import { getFormattedAnnotationNotes } from "../crm/utils/getFormattedNotes"
import { CopyButton } from "../common/CopyButton"
import { getHeapInstance } from "../../utils/heap"
import { SimpleCard } from "../common/SimpleCard"
import clsx from "clsx"

interface AnnotationsProps {
    callTitle: string
    annotationSections: IAnnotationSection[]
    selectAnnotation: (a: IAnnotation) => void
    selectedAnnotation?: IAnnotation
    goToTimestamp: (timestamp: string) => void
    participants: any[]
}

export function Annotations(props: AnnotationsProps) {
    return (
        <div className="space-y-4">
            {props.annotationSections.map((annotationSection) => (
                <AnnotationSection
                    key={annotationSection.key}
                    callTitle={props.callTitle}
                    annotationSection={annotationSection}
                    selectAnnotation={props.selectAnnotation}
                    selectedAnnotation={props.selectedAnnotation}
                    goToTimestamp={props.goToTimestamp}
                />
            ))}
        </div>
    )
}

function AnnotationSection(props: {
    callTitle: string
    annotationSection: IAnnotationSection
    selectAnnotation: (a: IAnnotation) => void
    selectedAnnotation?: IAnnotation
    goToTimestamp: (timestamp: string) => void
}) {
    if (props.annotationSection.annotations.length === 0) {
        return <MissingAnnotations heading={props.annotationSection.heading} />
    }

    return (
        <SimpleCard className="px-2 py-3">
            <div className="flex-grow text-base space-y-2 w-full">
                <div className="flex flex-row justify-between w-full items-center px-2">
                    <div className="flex justify-between w-full">
                        <h2 className="text-gray-900 text-xl font-bold leading-7">
                            {props.annotationSection.heading}
                        </h2>{" "}
                        <CopyButton
                            content={getFormattedAnnotationNotes(
                                props.callTitle,
                                props.annotationSection
                            )}
                        />
                    </div>
                </div>
                {props.annotationSection.annotations.map((annotation) => {
                    const id = `${annotation.start}-${annotation.end}`
                    return (
                        <Annotation
                            key={id}
                            id={id}
                            annotation={annotation}
                            clickHandler={props.selectAnnotation}
                            isSelected={props.selectedAnnotation === annotation}
                            goToTimestamp={props.goToTimestamp}
                        />
                    )
                })}
            </div>
        </SimpleCard>
    )
}

function MissingAnnotations({ heading }: { heading: string }) {
    return (
        <SimpleCard className="p-3">
            <div className="w-full flex-grow items-start text-base text-gray-600 space-y-3">
                <div className="flex flex-row justify-between items-center">
                    <div className="flex justify-between w-full">
                        <h2 className="text-xl font-bold leading-7">
                            {heading}
                        </h2>
                    </div>
                </div>
                <div className="font-normal">Not covered</div>
            </div>
        </SimpleCard>
    )
}

function Annotation(props: {
    id: string
    annotation: IAnnotation
    clickHandler: (annotation: IAnnotation) => void
    isSelected: boolean
    goToTimestamp: (timestamp: string) => void
}) {
    const annotation = props.annotation
    const timestamp = annotation.segment_data[0]?.timestamp || ""

    return (
        <div
            id={props.id}
            className={clsx(
                "rounded-lg flex space-x-6 p-2 mr-auto hover:bg-gray-100 hover:cursor-pointer",
                { "bg-gray-100": props.isSelected }
            )}
            onClick={() => {
                props.clickHandler(annotation)
                props.goToTimestamp!(timestamp)
                getHeapInstance()?.track("clicked-annotation", {
                    tag: annotation.tag,
                    start: annotation.start,
                    end: annotation.end,
                })
            }}
        >
            <div className="flex flex-row grow gap-3">
                <div className="bg-sun w-1 rounded-lg"></div>
                <div className="flex flex-col flex-1 whitespace-pre-wrap space-y-3">
                    {annotation.summary?.split("\n").map((line) => (
                        <span
                            className="text-gray-900 text-base font-normal leading-normal"
                            key={line}
                        >
                            {line}
                        </span>
                    ))}
                </div>
            </div>

            <div className="items-center flex justify-top">
                <div className="flex-none mr-2 text-slate-400">{timestamp}</div>
            </div>
        </div>
    )
}
