import { ReactNode } from "react"

export interface SetupSuggestionProps {
    name: string
    children: ReactNode
    onDismiss: () => void
}

export function SetupSuggestion({ children, onDismiss }: SetupSuggestionProps) {
    return (
        <div className="relative">
            <DismissButton onDismiss={onDismiss} />
            <div className="bg-white border rounded-lg p-2">{children}</div>
        </div>
    )
}

interface DismissButtonProps {
    onDismiss: () => void
}

function DismissButton({ onDismiss }: DismissButtonProps) {
    return (
        <button
            className="absolute top-0 right-0 border w-6 h-6 flex items-center justify-center rounded-full -mt-2 -mr-2 bg-slate-100 hover:bg-slate-200"
            onClick={onDismiss}
        >
            &#10005;
        </button>
    )
}
