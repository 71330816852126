export function LoadingPulse(props: { rows: number; color?: string }) {
    const color = props.color || "bg-slate-200"
    const pulseDivs = Array.from({ length: props.rows }, (_, index) => (
        <div key={index} className={`h-2 ${color} rounded`}></div>
    ))

    return (
        <div className="w-full">
            <div className="animate-pulse flex space-x-4">
                <div className="flex-1 space-y-6 py-1">{pulseDivs}</div>
            </div>
        </div>
    )
}
