import { createQueryKeys } from "@lukemorales/query-key-factory"
import axios from "axios"
import {
    ICrmDealPipeline,
    ICrmDealStage,
    ICrmUser,
} from "../../components/crm/types/Crm"

export const crmQueries = createQueryKeys("crm", {
    users: () => ({
        queryKey: ["crm/users"],
        queryFn: async () => {
            const { data } = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/crm/users`
            )
            return data as ICrmUser[]
        },
    }),
    dealStages: () => ({
        queryKey: ["crm/deal_stages"],
        queryFn: async () => {
            const { data } = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/crm/deal_stages`
            )
            return data as ICrmDealStage[]
        },
    }),
    dealPipelines: () => ({
        queryKey: ["crm/deal_pipelines"],
        queryFn: async () => {
            const { data } = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/crm/deal_pipelines`
            )
            return data as ICrmDealPipeline[]
        },
    }),
})
