import {
    Scatter,
    Tooltip,
    XAxis,
    ScatterChart,
    YAxis,
    ResponsiveContainer,
    TooltipProps,
} from "recharts"
import { ICompany } from "../../types/Company"
import colors from "tailwindcss/colors"
import {
    NameType,
    ValueType,
} from "recharts/types/component/DefaultTooltipContent"
import { DateTime } from "luxon"
import { HashLink } from "react-router-hash-link"
import { useLocation } from "react-router-dom"
import { EmailThread } from "../../types/EmailThread"

interface Event {
    id: string
    type: string
    date: number
    tooltip?: string
    y: number
}

export function Timeline(props: {
    company: ICompany
    emailThreads: EmailThread[]
}) {
    const past: Event[] = props.company.calls.map((call) => ({
        id: call.id,
        type: "Call",
        date: new Date(call.start_time).getTime(),
        tooltip: call.title,
        y: 0,
    }))
    const upcoming: Event[] = props.company.prep_sheets.map((prep) => ({
        id: prep._id,
        type: "Upcoming call",
        date: new Date(prep.call_info.start_time).getTime(),
        tooltip: prep.call_info.title,
        y: 0,
    }))
    const today: Event[] = [
        { id: "today", type: "Today", date: Date.now(), y: 0 },
    ]

    const allEmails = props.emailThreads.flatMap((thread) => thread.messages)
    const emails: Event[] = allEmails.map((email) => ({
        id: email.email_thread_id,
        type: "Email",
        date: new Date(email.sent_at_utc).getTime(),
        tooltip: email.subject,
        y: 0,
    }))

    if (past.length === 0 && upcoming.length === 0 && emails.length === 0) {
        // No events to show
        return <></>
    }

    return (
        <div className="pt-2">
            {/* Need to hack width to 99% for responsiveness to work, see https://github.com/recharts/recharts/issues/172 */}
            <ResponsiveContainer height={60} width={"99%"}>
                <ScatterChart>
                    <XAxis
                        padding={{ left: 10, right: 10 }}
                        type="number"
                        dataKey="date"
                        scale="auto"
                        domain={["auto", "auto"]}
                        tickFormatter={(unixTime) =>
                            DateTime.fromMillis(unixTime).toFormat("dd LLL")
                        }
                        tickSize={12}
                    />
                    <YAxis type="number" dataKey="y" hide={true} />
                    <Tooltip content={<CustomTooltip />} />
                    <Scatter data={emails} shape={<CirclePoint />} />
                    <Scatter data={today} shape={<TodayMarker />} />
                    <Scatter
                        data={past}
                        shape={
                            <DateSquare
                                fill="fill-gray-900"
                                textColor="white"
                            />
                        }
                    />
                    <Scatter
                        data={upcoming}
                        shape={
                            <DateSquare fill="fill-papyrus" textColor="black" />
                        }
                    />
                </ScatterChart>
            </ResponsiveContainer>
        </div>
    )
}

const DateSquare = (props: any) => {
    const cx: number = props.cx
    const cy: number = props.cy
    const event: Event = props.payload

    const date = DateTime.fromMillis(event.date).toFormat("dd")

    const width = 24

    const location = useLocation()

    return (
        <HashLink
            smooth
            replace // Don't add to browser history
            to={`${location.pathname}?tab=activity#${event.id}`}
        >
            <g className="cursor-pointer">
                <rect
                    x={cx - width / 2}
                    y={cy - width / 2}
                    width={width}
                    height={width}
                    rx={4}
                    stroke="white"
                    strokeWidth={1}
                    className={props.fill}
                />
                <text
                    x={cx}
                    y={cy}
                    dy={4}
                    textAnchor="middle"
                    fill={props.textColor}
                    fontSize={12}
                    className="font-bold"
                >
                    {date}
                </text>
            </g>
        </HashLink>
    )
}

const TodayMarker = (props: any) => {
    const { x, y } = props

    return (
        <g>
            {/* Vertical Line */}
            <line
                x1={x}
                y1={y + 10}
                x2={x}
                y2={y - 10}
                stroke={colors.gray[600]}
                strokeWidth={1}
            />

            {/* Text "Today" */}
            <text
                x={x}
                y={y - 12}
                fill={colors.gray[600]}
                fontSize={12}
                textAnchor="middle"
                className="font-bold"
            >
                TODAY
            </text>
        </g>
    )
}

const CirclePoint = (props: any) => {
    const cx: number = props.cx
    const cy: number = props.cy
    const event: Event = props.payload

    const radius = 6

    const location = useLocation()

    return (
        <HashLink
            smooth
            replace // Don't add to browser history
            to={`${location.pathname}?tab=activity#${event.id}`}
        >
            <g className="cursor-pointer">
                <circle cx={cx} cy={cy} r={radius} fill={props.fill} />
            </g>
        </HashLink>
    )
}

const CustomTooltip = ({
    active,
    payload,
    label,
}: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length > 0) {
        const date = DateTime.fromMillis(payload[0].payload.date).toFormat(
            "dd LLL yyyy"
        )
        const eventType: string = payload[0].payload.type
        const eventInfo: string | undefined = payload[0].payload.tooltip
        return (
            <div className="bg-gray-900 text-white rounded-md p-2 text-sm">
                <p>
                    {date} : {eventType}
                </p>
                {eventInfo && <p className="font-bold">{eventInfo}</p>}
            </div>
        )
    }

    return null
}
