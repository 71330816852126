import { ICallPreview } from "../../types/Call"
import { ICallPrepData } from "../../types/CallPrep"
import { EmailThread } from "../../types/EmailThread"

interface BaseActivityItem {
    type: string
    id: string
    date: number
}

export interface PrepSheetActivityItem extends BaseActivityItem {
    type: "prep-sheet"
    prepSheet: ICallPrepData
}

export function createPrepSheetActivity(
    prep: ICallPrepData
): PrepSheetActivityItem {
    return {
        id: prep._id,
        type: "prep-sheet",
        prepSheet: prep,
        date: new Date(prep.call_info.start_time).getTime(),
    }
}

export interface CallActivityItem extends BaseActivityItem {
    type: "call"
    call: ICallPreview
}

export function isCallActivity(item: ActivityItem): item is CallActivityItem {
    return item.type === "call"
}

export function isEmailActivity(
    item: ActivityItem
): item is EmailThreadActivityItem {
    return item.type === "email"
}

export function createCallActivity(call: ICallPreview): CallActivityItem {
    return {
        id: call.id,
        type: "call",
        call: call,
        date: new Date(call.start_time).getTime(),
    }
}

export interface EmailThreadActivityItem extends BaseActivityItem {
    type: "email"
    emailThread: EmailThread
}

export function createEmailThreadActivity(
    thread: EmailThread
): EmailThreadActivityItem {
    console.assert(
        thread.messages.length,
        "Cannot create activity with empty email threads"
    )

    return {
        id: thread.email_thread_id,
        type: "email",
        emailThread: thread,
        date: new Date(
            thread.messages[thread.messages.length - 1].sent_at_utc
        ).getTime(),
    }
}

export type ActivityItem =
    | PrepSheetActivityItem
    | CallActivityItem
    | EmailThreadActivityItem
