import {
    ColumnDef,
    getCoreRowModel,
    getFilteredRowModel,
    useReactTable,
    FilterFn,
} from "@tanstack/react-table"
import { DateTime } from "luxon"
import { useMemo } from "react"
import { ICall, ICompanyPreview, IParticipant } from "../../types/Call"
import { getFormattedDateTime } from "../../utils/datetime"
import DetailsTableHeaderGroup from "./DetailsTableHeaderGroup"
import CallDetailsRow from "./CallDetailsRow"
import CallTitle from "./CallTitle"
import { CompanyList } from "./CompanyList"
import { CallPageParticipantsList } from "./ParticipantsList"
import { FilterTerm } from "./types/FilterTerm"
import { multiTermFilter } from "./utils/multiTermFilter"
import { ScreenSize, screenLargerOrEqualTo } from "../../utils/screenSize"
import { TagsPreview } from "./TagsPreview"

interface CallDetailsTableProps {
    calls: ICall[]
    filterTerms: FilterTerm[]
    currentDomain?: string
    pageSize: number | undefined
}

export default function CallDetailsTable(props: CallDetailsTableProps) {
    const columns = useMemo<ColumnDef<ICall, any>[]>(() => {
        return [
            {
                id: "_search",
                accessorFn: (row) => getSearchString(row),
                enableGlobalFilter: true,
            },
            {
                id: "title",
                accessorFn: (row) => ({
                    title: row.title,
                    status: row.status,
                    status_reason: row.status_reason,
                    is_private: row.is_private,
                    language: row.language,
                }),
                header: "Call Title",
                enableGlobalFilter: false,
                cell: (info) => <CallTitle {...info.getValue()} />,
            },
            {
                id: "tags",
                accessorFn: (row) => row.tags,
                header: "Tags",
                enableGlobalFilter: false,
                cell: (info) => <TagsPreview tags={info.getValue() || []} />,
            },
            {
                id: "companies",
                accessorFn: (row) => row.companies,
                header: "Companies",
                enableGlobalFilter: false,
                cell: (info) => (
                    <CompanyList companies={info.getValue() || []} />
                ),
            },
            {
                accessorKey: "parties",
                header: "Participants",
                enableGlobalFilter: false,
                cell: (info) => (
                    <CallPageParticipantsList
                        parties={info.getValue()}
                        currentDomain={props.currentDomain}
                    />
                ),
            },
            {
                accessorFn: (row) => getFormattedDateTime(row.start_time),
                header: "Date",
                enableGlobalFilter: false,
                size: 40,
            },
        ]
    }, [props.currentDomain])

    const showAllColumns: boolean = screenLargerOrEqualTo(ScreenSize.md)

    const table = useReactTable({
        data: props.calls,
        columns,

        manualPagination: true,
        enableSorting: false,

        initialState: {
            pagination: {
                pageSize: props.pageSize,
            },
        },

        state: {
            columnVisibility: {
                _search: false,
                companies: showAllColumns,
                parties: showAllColumns,
                tags: showAllColumns,
            },
            globalFilter: props.filterTerms,
        },
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),

        globalFilterFn: filterRowWrapper,
    })

    let emptyMessage = null
    if (table.getRowModel().rows.length === 0) {
        emptyMessage =
            props.calls.length === 0
                ? "No calls to display at this moment"
                : "No calls match your filter"
    }

    return (
        <div>
            <div className="text-base">
                <table className="p-2 table-fixed w-full border-separate border-spacing-y-2">
                    <thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <DetailsTableHeaderGroup
                                key={headerGroup.id}
                                headerGroup={headerGroup}
                            />
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map((row) => (
                            <CallDetailsRow key={row.id} row={row} />
                        ))}
                    </tbody>
                </table>
            </div>
            {emptyMessage && (
                <div className="mt-4 text-center">
                    <span className="italic">{emptyMessage}</span>
                </div>
            )}
        </div>
    )
}

function getSearchString(call: ICall): string {
    return [
        call.title,
        call.status,
        call.language,
        getCompaniesSearchString(call.companies || []),
        getTagsSearchString(call.tags?.map((tag) => tag.name) || []),
        getDateSearchString(call.start_time),
        getPartiesSearchString(call.parties),
    ].join(" ")
}

function getTagsSearchString(tags: string[]): string {
    return tags.join(" ")
}

function getCompaniesSearchString(companies: ICompanyPreview[]): string {
    const companiesNames = companies.map((company) => company.name).join(" ")
    const companiesDomains = companies
        .map((company) => company.domain)
        .join(" ")

    let searchString = companiesNames + " " + companiesDomains
    searchString += companies.length > 0 ? " external" : " internal"
    return searchString
}

function getDateSearchString(timestamp: string): string {
    const callDateTime = DateTime.fromISO(timestamp)
    const long = callDateTime.toLocaleString(DateTime.DATE_HUGE)
    const simple = callDateTime.toRelativeCalendar()
    return `${long} ${simple}`
}

function getPartiesSearchString(parties: IParticipant[]): string {
    return parties
        .map((party) => `${party.name ?? ""} ${party.email}`)
        .join(" ")
}

/**
 * Wraps the multiTermFilter function to be used as a filter function for
 * react-table.
 *
 * @param row Row to filter.
 * @param columnId Column to filter.
 * @param filterTerms Filter terms to use.
 * @returns Returns true if the row should be visible, false otherwise.
 */
const filterRowWrapper: FilterFn<ICall> = (
    row,
    columnId,
    filterTerms
): boolean => {
    const value = String(row.getValue(columnId))
    return multiTermFilter(value, filterTerms)
}
