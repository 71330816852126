import { Row, flexRender } from "@tanstack/react-table"
import { ICall } from "../../types/Call"
import { NavigateLink } from "../common/NavigateLink"

interface CallDetailsRowProps {
    row: Row<ICall>
}

export default function CallDetailsRow({ row }: CallDetailsRowProps) {
    const href = `/calls/${row.original.id}`

    return (
        <tr
            className="text-gray-600 text-base leading-normal h-16 border-b
                last:border-0 transition-colors ease-out hover:bg-gray-100 hover:cursor-pointer
                bg-white rounded-xl shadow-md shadow-gray-100"
        >
            {row.getVisibleCells().map((cell) => (
                <td
                    key={cell.id}
                    className="h-16 first:rounded-l-xl last:rounded-r-xl"
                >
                    <NavigateLink
                        href={href}
                        className="flex w-full h-full items-center p-2"
                    >
                        {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                        )}
                    </NavigateLink>
                </td>
            ))}
        </tr>
    )
}
