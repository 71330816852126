import { useLocation } from "react-router-dom"
import { CallPrepPreview } from "./CallPrepPreview"
import { CallPreview } from "./CallPreview"
import {
    ActivityItem,
    PrepSheetActivityItem,
    isCallActivity,
    isEmailActivity,
} from "./Activities"
import { EmailPreview } from "./EmailPreview"
import { useState } from "react"
import ToggleButton from "../common/ToggleButton"
import clsx from "clsx"

interface ActivityListProps {
    future: PrepSheetActivityItem[]
    past: ActivityItem[]
}

export function ActivityList(props: ActivityListProps) {
    const location = useLocation()
    const hashValue = location.hash.slice(1) // Hash set by Timeline's HashLink

    const emailsExist = props.past.some(isEmailActivity)
    const [showEmails, setShowEmails] = useState<boolean>(true)

    return (
        <div className="space-y-2">
            <div className="flex justify-end">
                <ShowEmailsToggle
                    showEmails={emailsExist && showEmails}
                    setShowEmails={setShowEmails}
                    disabled={!emailsExist}
                />
            </div>
            {props.future.length > 0 && <ActivityBreak label="Upcoming Week" />}
            {props.future.map((activity) => (
                // Set "id" for Timeline's HashLink
                <div key={activity.id} id={activity.id}>
                    <CallPrepPreview
                        sheet={activity.prepSheet}
                        highlightBorder={hashValue === activity.id}
                    />
                </div>
            ))}
            {props.past.length > 0 && (
                <>
                    <ActivityBreak label="Past" />

                    {props.past.map((activity) => (
                        // Set "id" for Timeline's HashLink
                        <div key={activity.id} id={activity.id}>
                            {isCallActivity(activity) && (
                                <CallPreview
                                    call={activity.call}
                                    highlightBorder={hashValue === activity.id}
                                />
                            )}
                            {showEmails && isEmailActivity(activity) && (
                                <EmailPreview
                                    email={activity.emailThread}
                                    highlightBorder={hashValue === activity.id}
                                />
                            )}
                        </div>
                    ))}
                </>
            )}
        </div>
    )
}

function ShowEmailsToggle(props: {
    showEmails: boolean
    setShowEmails: (showEmails: boolean) => void
    disabled: boolean
}) {
    return (
        <div
            className="flex items-center space-x-2"
            data-tooltip-id="tooltip-explanation"
            data-tooltip-content={
                props.disabled
                    ? "No emails found in your CRM for this company"
                    : null
            }
        >
            <span
                className={clsx("text-sm", props.disabled && "text-gray-400")}
            >
                Show emails
            </span>
            <ToggleButton
                checked={props.showEmails}
                disabled={props.disabled}
                onChange={props.setShowEmails}
            />
        </div>
    )
}

function ActivityBreak(props: { label: string }) {
    return (
        <div className="flex justify-center">
            <span className="px-3 text-sm font-semibold text-gray-600">
                {props.label}
            </span>
        </div>
    )
}
