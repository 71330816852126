import { createQueryKeys } from "@lukemorales/query-key-factory"
import axios from "axios"
import { IUpcomingCall } from "../../types/UpcomingCall"

export const upcomingCallQueries = createQueryKeys("upcomingCalls", {
    list: () => ({
        queryKey: ["upcomingCalls"],
        queryFn: async () => {
            const response = await axios.get(
                process.env.REACT_APP_API_DOMAIN + "/call_bot/calendar/meetings"
            )
            return response.data as IUpcomingCall[]
        },
    }),
})
