import axios from "axios"
import { useEffect, useState } from "react"
import { IntegrationAppProvider } from "@integration-app/react"
import LoadingSpinner from "./common/LoadingSpinner"

export function IntegrationAppSession(props: { children: React.ReactNode }) {
    const [integrationAppToken, setIntegrationAppToken] = useState(undefined)

    // Refresh the integration app token
    useEffect(() => {
        async function getIntegrationAppToken() {
            const response = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/organization/integration_app_token`
            )
            console.log("jwt_token for integration app", response)
            setIntegrationAppToken(response.data)
        }

        getIntegrationAppToken()
    }, [])

    if (integrationAppToken) {
        return (
            <IntegrationAppProvider token={integrationAppToken!}>
                {props.children}
            </IntegrationAppProvider>
        )
    } else {
        return <LoadingSpinner />
    }
}
