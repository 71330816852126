import {
    faComments,
    faCompass,
    faPaperPlane,
} from "@fortawesome/free-regular-svg-icons"
import { faMicrochip, IconDefinition } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Link } from "react-router-dom"
import { AboutHeader } from "./AboutWrapper"

export function About() {
    return (
        <div className="flex flex-col items-center p-16 bg-delta min-h-screen space-y-10 text-white">
            <Header />
            <Description />
            <LogIn />
            <Links />
        </div>
    )
}

function Header() {
    return (
        <div className="text-center space-y-4">
            <AboutHeader />
            <h1 className="text-4xl">Make your sales teams superhuman</h1>
            <h2 className="text-xl">Your AI copilot</h2>
        </div>
    )
}

function LogIn() {
    return (
        <Link
            to="/"
            className="py-3 px-10 text-center font-bold rounded-lg space-x-2 text-delta bg-sun hover:bg-sun-pale transition-colors"
        >
            <span>Log In</span>
        </Link>
    )
}

function Description() {
    return (
        <div className="px-6 md:px-24 xl:px-52 space-y-5 text-lg transition-all">
            <DescriptionSegment bgColour="bg-nile-pale" leftIcon={faMicrochip}>
                Glyphic AI uses advanced machine learning algorithms to
                structure millions of data points generated through human
                interactions via email, audio, and video.
            </DescriptionSegment>
            <DescriptionSegment
                bgColour="bg-papyrus-pale"
                rightIcon={faComments}
            >
                We provide Sales and RevOps teams with the most relevant
                information automatically to increase sales conversion and
                operational efficiency.
            </DescriptionSegment>
            <DescriptionSegment bgColour="bg-sand-pale" leftIcon={faCompass}>
                Glyphic's Copilot offers a superior LLM model that identifies
                key information in real-time during customer conversations and
                categorizes it according to a variety of data properties and
                qualification frameworks.
            </DescriptionSegment>
            <DescriptionSegment bgColour="bg-sun-pale" rightIcon={faPaperPlane}>
                Retrieved data is automatically translated into action items,
                categorized according to a variety of data properties, and
                shipped to CRMs to keep customer data up to date and
                streamlined.
            </DescriptionSegment>
        </div>
    )
}

function DescriptionSegment(props: {
    children: React.ReactNode
    bgColour: string
    leftIcon?: IconDefinition
    rightIcon?: IconDefinition
}) {
    return (
        <div className="flex items-center space-x-5">
            {props.leftIcon && (
                <FontAwesomeIcon icon={props.leftIcon} size="4x" />
            )}
            <div
                className={`p-5 rounded-lg text-center text-delta ${props.bgColour}`}
            >
                {props.children}
            </div>
            {props.rightIcon && (
                <FontAwesomeIcon icon={props.rightIcon} size="4x" />
            )}
        </div>
    )
}

function Links() {
    return (
        <div className="underline space-x-4">
            <a href="https://www.glyphic.ai/privacy-policy">Privacy Policy</a>
            <Link to="/about/google-api-disclosure">Limited Use Policy</Link>
        </div>
    )
}
