import { useCallback, useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import axios from "axios"
import ReactPlayer from "react-player"

import { ISharedCall, ITranscriptTurn } from "../../types/Call"
import { CallDetails } from "./CallDetails"
import { CallTranscript } from "./CallTranscript"
import LoadingSpinner from "../common/LoadingSpinner"

import logo from "../../assets/logomark_sun_logotype_cotton.svg"
import { getFormattedDateTime } from "../../utils/datetime"
import { getTurnAtTimestamp, goToTimestamp } from "./utils/mediaPlayer"
import assert from "assert"
import { ErrorPage, IError } from "../common/errorPage"
import { IAnnotation } from "../../types/Annotation"
import { CallAnalysis, Tab, Tabs, TabsEnum } from "./CallAnalysis"
import AskGlyphic from "../question-answering/AskGlyphic"
import { ResourceType } from "../question-answering/types/ResourceTypes"
import { sendFollowUpEmailForCall } from "../../utils/createEmailLink"

export function SharedCall() {
    return (
        <div>
            <Banner />
            <SharedCallPage />
        </div>
    )
}

function Banner() {
    return (
        <nav className="text-base flex flex-col md:flex-row items-center justify-between space-x-4 gap-4 px-6 py-3 md:px-10 bg-gray-900 text-white min-h-fit">
            <div className="flex items-center">
                <a className="flex items-center" href="https://glyphic.ai">
                    <img src={logo} alt="Logo" className="max-h-[32px]" />{" "}
                </a>
            </div>
            <div className="flex flex-row justify-end w-full text-center items-center gap-2 md:gap-4">
                <span className="font-semibold">
                    Find out how Glyphic can take your sales to new heights.
                </span>
                <a
                    className="bg-blue-500 text-white font-bold px-6 py-2 rounded-lg"
                    href="https://glyphic.ai"
                >
                    Learn&nbsp;more
                </a>
            </div>
        </nav>
    )
}

function SharedCallPage() {
    const params = useParams()
    const sharedCallId: string = params.shared_call_id as string
    const playerRef = useRef<ReactPlayer | null>(null)
    console.assert(!!sharedCallId, "Call page should always have a call id!")

    const [call, setCall] = useState<ISharedCall | undefined>()
    const [callLoadError, setCallLoadError] = useState<IError | undefined>()
    const [tabs, setTabs] = useState<Tab[]>([])
    const [selectedAnnotation, setSelectedAnnotation] = useState<
        IAnnotation | undefined
    >()
    const [signedUrl, setSignedUrl] = useState<string>("")
    const [playerHeight, setPlayerHeight] = useState<string>("100%")
    const [playerReady, setPlayerReady] = useState<boolean>(false)

    const selectAnnotation = (annotation: IAnnotation) => {
        setSelectedAnnotation((prevSelectedAnnotation) => {
            if (prevSelectedAnnotation === annotation) {
                return undefined // unselect the annotation
            }
            return annotation
        })
    }

    useEffect(() => {
        function setTabsFromCall(call: ISharedCall) {
            const availableTabs: Tab[] = []
            if (call.share_options.share_annotations) {
                availableTabs.push(Tabs[TabsEnum.Annotations])
            }
            if (call.share_options.share_custom_insights) {
                availableTabs.push(Tabs[TabsEnum.CustomInsights])
            }
            if (call.share_options.share_talk_stats) {
                availableTabs.push(Tabs[TabsEnum.TalkStats])
            }
            setTabs(availableTabs)
        }

        async function getCalls() {
            try {
                const response = await axios.get(
                    process.env.REACT_APP_API_DOMAIN +
                        "/shared/calls/" +
                        sharedCallId
                )
                setCall(response.data)
                setTabsFromCall(response.data)
                setCallLoadError(undefined)
            } catch (error) {
                let errorCode: number | undefined
                let errorMsg = "Failed to load call."
                if (axios.isAxiosError(error)) {
                    errorCode = error.response?.status
                    if (errorCode === 404) {
                        errorMsg +=
                            " If this shared call has expired, please contact the meeting host."
                    }
                }
                setCallLoadError({ code: errorCode, message: errorMsg })
            }
        }

        async function getSignedUrl() {
            const response = await axios.get(
                process.env.REACT_APP_API_DOMAIN +
                    "/shared/calls/" +
                    sharedCallId +
                    "/media"
            )
            if (response.data.video_signed_url) {
                setSignedUrl(response.data.video_signed_url)
                setPlayerHeight("100%")
            } else if (response.data.audio_signed_url) {
                setSignedUrl(response.data.audio_signed_url)
                setPlayerHeight("50px")
            } else {
                console.error("No signed url found")
            }
        }
        getCalls()
        getSignedUrl()

        return () => {
            // Reset the title when the component unmounts
            document.title = process.env.REACT_APP_DOCUMENT_TITLE!
        }
    }, [sharedCallId])

    useEffect(() => {
        if (call && call.title) {
            document.title =
                call.title + " - " + process.env.REACT_APP_DOCUMENT_TITLE
        }
    }, [call])

    const [highlightedTurns, setHighlightedTurns] = useState<ITranscriptTurn[]>(
        []
    )

    // if link has timestamp, go to that timestamp
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const startTimestamp = urlParams.get("start_timestamp")
        if (startTimestamp) {
            goToTimestamp(playerRef)(startTimestamp)
        }
    }, [params.timestamp, playerReady])

    function onMediaProgress(mediaProgress: {
        played: number
        playedSeconds: number
        loaded: number
        loadedSeconds: number
    }) {
        assert(call)
        const turn = getTurnAtTimestamp(
            call.transcript_turns,
            mediaProgress.playedSeconds
        )
        if (turn !== null) {
            setHighlightedTurns([turn])
        }
    }

    const sendEmail = useCallback(
        async (message: string) => {
            if (!call) return
            await sendFollowUpEmailForCall(message, call)
        },
        [call]
    )

    if (!!callLoadError) {
        return <ErrorPage error={callLoadError} />
    }

    if (!call) {
        return <LoadingSpinner />
    }

    const callTitle = call.title ?? "Untitled call"

    return (
        <div className="rounded-lg max-w-7xl mx-auto grid md:grid-cols-5 divide-x md:h-[calc(100vh-80px)]">
            <div className="col-span-3 pt-12 overflow-y-auto">
                <section className="pb-6 px-10 space-y-10">
                    <div className="space-y-5">
                        <AccessGrantedSection
                            sharerName={call.share_options.shared_by_name}
                            sharerEmail={call.share_options.shared_by_email}
                            expiryDate={call.share_options.expiry_date}
                        />
                        <div className="flex justify-between space-x-2">
                            <h1 className="pb-2 text-2xl font-bold">
                                {callTitle}
                            </h1>
                        </div>
                        <CallDetails call={call} />

                        {signedUrl && (
                            <div className="rounded-lg overflow-hidden">
                                <ReactPlayer
                                    url={signedUrl}
                                    controls={true}
                                    ref={playerRef}
                                    height={playerHeight}
                                    width="100%"
                                    onProgress={onMediaProgress}
                                    onReady={() => setPlayerReady(true)}
                                />
                            </div>
                        )}
                        {call.share_options.enable_ask_glyphic && (
                            <AskGlyphic
                                resourceId={sharedCallId}
                                type={ResourceType.SharedCalls}
                                sendEmail={sendEmail}
                                isReadOnly={true}
                            />
                        )}
                        <CallAnalysis
                            tabs={tabs}
                            call={call}
                            selectedAnnotation={selectedAnnotation}
                            selectAnnotation={selectAnnotation}
                            playerRef={playerRef}
                        />
                    </div>
                </section>
            </div>

            <div className="col-span-2 pt-12 px-10 flex flex-grow rounded-xl overflow-y-auto">
                <CallTranscript
                    transcriptTurns={call.transcript_turns}
                    highlightedTurns={highlightedTurns}
                    parties={call.parties}
                    goToTimestamp={goToTimestamp(playerRef)}
                    followMedia={true}
                />
            </div>
        </div>
    )
}

function AccessGrantedSection(props: {
    sharerName: string
    sharerEmail: string
    expiryDate: string
}) {
    const formattedDate = getFormattedDateTime(props.expiryDate)
    return (
        <div className="bg-blue-50 rounded-lg px-4 py-2">
            <div className="font-bold">
                <span>You’ve been granted access to this call by </span>
                <span className="text-blue-600">{props.sharerName}</span>
            </div>
            <div>
                <span>This link will expire on {formattedDate}. </span>
                <a className="underline" href={`mailto:${props.sharerEmail}`}>
                    Contact the host
                </a>
                <span> if you need more time.</span>
            </div>
        </div>
    )
}
