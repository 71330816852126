import { DateTime, Duration } from "luxon"

export function getLocalDateTime(isoDateTime: string): DateTime {
    // If no timezone offset is present default to UTC
    return DateTime.fromISO(isoDateTime, { zone: "UTC" }).toLocal()
}

export function localToUTC(isoDateTime: string): string | null {
    const localDateTime = DateTime.fromISO(isoDateTime, { zone: "local" })
    return localDateTime.toUTC().toISO()
}

export function getDetailedDateTime(isoDateTime: string): string {
    const callDateTime = getLocalDateTime(isoDateTime)
    return callDateTime.toLocaleString(DateTime.DATETIME_FULL)
}

export function getSimpleDate(isoDateTime: string): string {
    const callDateTime = getLocalDateTime(isoDateTime)
    return callDateTime.toLocaleString(DateTime.DATE_MED)
}

export function getSimpleTime(isoDateTime: string): string {
    const callDateTime = getLocalDateTime(isoDateTime)
    return callDateTime.toLocaleString(DateTime.TIME_SIMPLE)
}

export function getSimpleDateWithoutYear(isoDateTime: string): string {
    const callDateTime = getLocalDateTime(isoDateTime)
    return callDateTime.toFormat("d MMM") // Example format: "13 Aug"
}

export function getFormattedDateTime(
    isoDateTime: string,
    showTimeForDistantDates: boolean = false
): string {
    // Returns a formatted date
    // If showTimeForDistantDates is true then time is always appended,
    // otherwise it is only appended for close dates (today, tomorrow, yesterday)
    const callDateTime = getLocalDateTime(isoDateTime)
    const currentDate = DateTime.local().startOf("day")
    const diffInDays = callDateTime
        .startOf("day")
        .diff(currentDate, "days").days
    const daysUntilNow = Math.floor(diffInDays)

    if (daysUntilNow === 0) {
        return `Today, ${callDateTime.toLocaleString(DateTime.TIME_SIMPLE)}`
    } else if (daysUntilNow === -1) {
        return `Yesterday, ${callDateTime.toLocaleString(DateTime.TIME_SIMPLE)}`
    } else if (daysUntilNow === 1) {
        return `Tomorrow, ${callDateTime.toLocaleString(DateTime.TIME_SIMPLE)}`
    }

    const date = callDateTime.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
    if (showTimeForDistantDates) {
        return `${date}, ${callDateTime.toLocaleString(DateTime.TIME_SIMPLE)}`
    } else {
        return date
    }
}

export function formatDuration(seconds: number): string {
    const duration = Duration.fromObject({ seconds })
    // If duration is 0, rescale() won't work
    if (seconds === 0) return duration.toHuman({ unitDisplay: "narrow" })
    return duration.rescale().toHuman({ unitDisplay: "narrow" })
}

export function timestampToSeconds(timestamp: string): number {
    // Formats timestamp of the format "mm:ss" into seconds
    const [minutes, seconds] = timestamp.split(":").map((num) => parseInt(num))
    if (isNaN(minutes) || isNaN(seconds)) {
        throw new Error("Invalid timestamp format")
    }
    return minutes * 60 + seconds
}

export function displaySecondsAsHours(seconds: number): string {
    const hours = Math.round(seconds / 3600)
    if (hours === 0) {
        return `<1h`
    }
    return `${hours}h`
}

export function convertDateToISODateString(dateString: string): string {
    return new Date(dateString + "T00:00:00.000Z").toISOString()
}
