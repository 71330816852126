export function RecordingIcon(props: { className?: string }) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect x="1" y="5" width="2" height="6" rx="1" fill="currentColor" />
            <rect
                x="4"
                y="1"
                width="2"
                height="14"
                rx="1"
                fill="currentColor"
            />
            <rect x="7" y="6" width="2" height="4" rx="1" fill="currentColor" />
            <rect
                x="10"
                y="4"
                width="2"
                height="8"
                rx="1"
                fill="currentColor"
            />
            <rect
                x="13"
                y="2"
                width="2"
                height="12"
                rx="1"
                fill="currentColor"
            />
        </svg>
    )
}
