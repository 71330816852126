import axios from "axios"
import { AnswerFeedback } from "../types/QuestionTypes"
import { useCallback } from "react"
import { ResourceType } from "../types/ResourceTypes"

interface FeedbackApiHook {
    sendFeedback: (threadId: string, feedback: AnswerFeedback) => Promise<void>
}

export const useFeedbackApi = (
    resourceType?: ResourceType,
    resourceId?: string
): FeedbackApiHook => {
    const sendFeedback = useCallback(
        async (threadId: string, feedback: AnswerFeedback): Promise<void> => {
            const url =
                resourceType && resourceId
                    ? `${process.env.REACT_APP_API_DOMAIN}/${resourceType}/${resourceId}/askglyphic/threads/${threadId}/feedback`
                    : `${process.env.REACT_APP_API_DOMAIN}/organization/askglyphic/threads/${threadId}/feedback`
            try {
                await axios.put(url, feedback)
            } catch (error) {
                console.error(error)
            }
        },
        [resourceType, resourceId]
    )

    return {
        sendFeedback,
    }
}
