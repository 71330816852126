import React from "react"

import purify from "dompurify"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { EmailContact, EmailThread } from "../../types/EmailThread"
import {
    faArrowRight,
    faChevronDown,
    faChevronUp,
} from "@fortawesome/free-solid-svg-icons"
import { faEnvelope } from "@fortawesome/free-regular-svg-icons"
import { useEffect, useState } from "react"
import clsx from "clsx"
import { useLocation } from "react-router-dom"
import { ActivityPreview } from "./ActivityPreview"
import { getHeapInstance } from "../../utils/heap"

export function EmailPreview(props: {
    email: EmailThread
    highlightBorder: boolean
}) {
    const location = useLocation()
    const [showFull, setShowFull] = useState<boolean>(false)

    const lastMessage = props.email.messages[props.email.messages.length - 1]
    const threadId = props.email.email_thread_id

    useEffect(() => {
        // If the EmailPreview is being opened from a hash link (ie from a link from the Timeline),
        // then open the full thread
        const currentHash = location.hash.replace("#", "")
        if (currentHash === threadId) {
            setShowFull(true)
        }
    }, [location, threadId])

    useEffect(() => {
        if (showFull) {
            // Track that the user opened the email thread
            getHeapInstance()?.track("email-thread-opened", {
                email_thread_id: threadId,
            })
        }
    }, [showFull, threadId])

    return (
        <ActivityPreview
            heading={
                <h2 className="font-bold text-gray-900 text-base">
                    {lastMessage.subject}
                </h2>
            }
            subheading={
                <div>
                    {contactDisplayName(lastMessage.from_contact)}
                    <FontAwesomeIcon
                        icon={faArrowRight}
                        className="mx-1"
                        size="sm"
                    />
                    {lastMessage.to_contacts
                        .map((c) => contactDisplayName(c))
                        .join(", ")}
                </div>
            }
            date={lastMessage.sent_at_utc}
            icon={<FontAwesomeIcon icon={faEnvelope} size="sm" />}
            button={
                <FontAwesomeIcon
                    icon={showFull ? faChevronUp : faChevronDown}
                ></FontAwesomeIcon>
            }
            backgroundColor={showFull ? "bg-white" : "bg-zinc-200"}
            onClick={() => {
                setShowFull((prev) => !prev)
            }}
            rowClickable={!showFull}
            highlightBorder={props.highlightBorder}
        >
            <div
                className={clsx(
                    "email-content whitespace-normal overflow-y-clip",
                    !showFull && "max-h-0"
                )}
            >
                {/* We need to display email html in an iframe to prevent
                        styles from leaking into the rest of the page */}
                <IframeWrapper
                    htmlContent={lastMessage.body_html || lastMessage.body}
                />
            </div>
        </ActivityPreview>
    )
}

function IframeWrapper(props: { htmlContent: string }) {
    // Component for an iframe that resizes its height to fit its content
    // Note this currently isn't responsive: if the screen is resized, the iframe won't resize, but a scrollbar may appear

    const ref = React.useRef<HTMLIFrameElement>(null)
    const [height, setHeight] = React.useState("0px")
    const onLoad = () => {
        // We have to manually set the height of the iframe to match the height of the content
        const height = ref.current?.contentWindow?.document.body.scrollHeight
        if (height) {
            const buffer = 30
            setHeight(height + buffer + "px")
        }
    }
    return (
        <iframe
            title="email-content"
            ref={ref}
            onLoad={onLoad}
            height={height}
            className="w-full overflow-hidden"
            srcDoc={purify.sanitize(props.htmlContent)}
        />
    )
}

function contactDisplayName(contact: EmailContact): string {
    return contact.name || contact.email
}
