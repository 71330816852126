import axios from "axios"
import _ from "lodash"
import { useState } from "react"
import { ToggleSetting } from "./ToggleSetting"
import { CrmType } from "../crm/types/Crm"
import { CrmSettings } from "../../types/Settings"
import { useQuery } from "@tanstack/react-query"
import { CrmCompanyUrlFieldSetting } from "./CrmCompanyUrlFieldSetting"
import { CrmDealPipelineFilter } from "./CrmDealPipelineFilter"
import LoadingSpinner from "../common/LoadingSpinner"
import { queries } from "../../api/queries"

export function CrmConfig(props: { crmType: CrmType }) {
    const { data: settings, isPending: isSettingsPending } = useQuery(
        queries.organization.settings()
    )
    const { data: fields, isPending: isFieldsPending } = useQuery(
        queries.organization.customInsights()
    )
    const [saveCompleted, setSaveCompleted] = useState<boolean>(false)

    const CanAutoSyncCustomInsights =
        fields?.some((field) => !!field.crm_field_name) || false

    if (isSettingsPending || isFieldsPending) {
        return (
            <div className="p-10">
                <LoadingSpinner />
            </div>
        )
    }

    const crm_settings = settings?.crm_settings || {
        auto_sync_custom_insights: false,
        add_notes: false,
        fetch_crm_calls: false,
        company_page_url_field: null,
        enabled_deal_pipelines: null,
    }

    function onChangeSetSettings(settings: CrmSettings) {
        setSaveCompleted(false)
        updateCrmSettings(settings, setSaveCompleted)
    }
    return (
        <div className="space-y-4">
            <h2 className="font-bold text-lg">CRM Preferences</h2>
            <hr className="my-4" />
            <ToggleSetting
                name="Automatically Sync Notes"
                description="Notes from a call will be automatically added to your CRM once the call is processed"
                checked={crm_settings.add_notes}
                disabled={false}
                onChange={(checked) => {
                    onChangeSetSettings({
                        ...crm_settings,
                        add_notes: checked,
                    })
                }}
            />

            <ToggleSetting
                name="Automatically Sync Custom Insights"
                description="Custom insights from a call will be automatically synced to your CRM fields once the call is processed"
                checked={crm_settings.auto_sync_custom_insights}
                disabled={!CanAutoSyncCustomInsights}
                disabledMessage="No custom insights are currently configured. To enable this feature, please contact Glyphic support."
                onChange={(checked) => {
                    onChangeSetSettings({
                        ...crm_settings,
                        auto_sync_custom_insights: checked,
                    })
                }}
            />

            {props.crmType === CrmType.Hubspot && (
                <ToggleSetting
                    name="Process Hubspot calls"
                    description="Calls you record in Hubspot will be automatically processed by Glyphic"
                    checked={crm_settings.fetch_crm_calls}
                    disabled={false}
                    onChange={(checked) => {
                        onChangeSetSettings({
                            ...crm_settings,
                            fetch_crm_calls: checked,
                        })
                    }}
                />
            )}
            {props.crmType === CrmType.Salesforce && (
                <ToggleSetting
                    name="Process Sales Dialer calls"
                    description="Calls you record using Salesforce Sales Dialer will be automatically processed by Glyphic"
                    checked={crm_settings.fetch_crm_calls}
                    disabled={false}
                    onChange={(checked) => {
                        onChangeSetSettings({
                            ...crm_settings,
                            fetch_crm_calls: checked,
                        })
                    }}
                />
            )}
            {props.crmType === CrmType.Hubspot && (
                <CrmDealPipelineFilter settings={crm_settings} />
            )}
            <CrmCompanyUrlFieldSetting crmType={props.crmType} />
            <div
                className={`flex justify-end text-gray-500 h-6 transition-opacity duration-300 ${
                    saveCompleted ? "opacity-100" : "opacity-0"
                }`}
            >
                Changes saved
            </div>
        </div>
    )
}

async function updateCrmSettings(
    settings: CrmSettings,
    setSaveCompleted: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> {
    try {
        await axios.put(
            `${process.env.REACT_APP_API_DOMAIN}/organization/settings`,
            { crm_settings: settings }
        )
        setSaveCompleted(true)
        const delay = 3000
        _.debounce(() => setSaveCompleted(false), delay)()
    } catch (error) {
        console.log(error)
    }
}
