import CreatableSelect from "react-select/creatable"
import { FilterTerm } from "./types/FilterTerm"
import { InputActionMeta, OnChangeValue } from "react-select"
import { FilterIcon } from "../../assets/icons/FilterIcon"

export type FilterChangeHandler = (
    newFilterTerms: FilterTerm[],
    oldFilterTerms?: FilterTerm[]
) => void

type Option = { value: string; label: string }

interface FilterInputProps {
    filterTerms: FilterTerm[]
    placeholder?: string
    onFilterChange: FilterChangeHandler
    className?: string
}

export default function FilterInput(props: FilterInputProps) {
    const incompleteFilterTerm =
        props.filterTerms.find((term) => !term.accepted)?.value ?? ""
    const acceptedFilterTerms = props.filterTerms.filter(
        (term) => term.accepted
    )
    const filterTermsAsOptions = acceptedFilterTerms.map((term) => ({
        label: term.value,
        value: term.value,
    }))

    const handleInputChange = (
        newValue: string,
        actionMeta: InputActionMeta
    ) => {
        // We only care for input changes, other actions are dealt by the
        // handleFilterChange function.
        if (actionMeta.action !== "input-change") return

        const newFilterTerms = [
            ...acceptedFilterTerms,
            { value: newValue, accepted: false },
        ]
        props.onFilterChange(newFilterTerms, props.filterTerms)
    }

    const handleFilterChange = (
        newValue: OnChangeValue<Option, /*isMulti =*/ true>
    ) => {
        const newFilterTerms = newValue.map((o) => ({
            value: o.value,
            accepted: true,
        }))
        props.onFilterChange(newFilterTerms, props.filterTerms)
    }

    return (
        <div
            className={
                "flex flex-row items-center px-4 border border-gray-300 rounded-lg " +
                (props.className ?? "")
            }
        >
            <FilterIcon className="text-gray-500" />
            <CreatableSelect
                className="w-full font-lg outline-none"
                placeholder={props.placeholder ?? "Type here..."}
                inputValue={incompleteFilterTerm}
                value={filterTermsAsOptions}
                options={filterTermsAsOptions}
                onInputChange={handleInputChange}
                onChange={handleFilterChange}
                isMulti={true}
                isClearable={true}
                createOptionPosition="first"
                formatCreateLabel={(inputValue) =>
                    `Add "${inputValue}" to filter`
                }
                autoFocus
                styles={{
                    control: (styles) => ({
                        ...styles,
                        border: "0",
                        boxShadow: "none",
                    }),
                    input: (styles) => ({
                        ...styles,
                        cursor: "text",
                    }),
                    indicatorSeparator: (styles) => ({
                        ...styles,
                        display: "none",
                    }),
                    dropdownIndicator: (styles) => ({
                        ...styles,
                        display: "none",
                    }),
                    placeholder: (defaultStyles) => {
                        return {
                            ...defaultStyles,
                            color: "#4b5563",
                        }
                    },
                }}
            />
        </div>
    )
}
