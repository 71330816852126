import Session from "supertokens-web-js/recipe/session"
// We need this polyfill because the EventSource provided by the browser
// doesn't support the custom headers!
import { EventSourcePolyfill, MessageEvent } from "event-source-polyfill"

/**
 * Pipes the response from the given url to the given receiver function.
 * Assumes the endpoint follow's Glyphic's SSE format.
 *
 * @param url url to pipe the response from, this must be a GET request and the
 *  question must be provided as part of the url (e.g. query paramenter).
 * @param receiver function to receive the response. The function should return
 *  true if it wants to continue receiving responses, or false if it wants to
 *  stop receiving responses.
 */
export async function pipeResponse(
    url: string,
    receiver: (delta: string) => boolean
): Promise<void> {
    return new Promise(async (resolve, reject) => {
        const token = await Session.getAccessToken()
        const eventSource = new EventSourcePolyfill(url, {
            withCredentials: true,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })

        const close = () => {
            eventSource.close()
            resolve()
        }

        eventSource.addEventListener("answer", (event) => {
            const messageEvent = event as MessageEvent
            const data = JSON.parse(messageEvent.data)
            if (!receiver(data.answer)) {
                close()
            }
        })

        eventSource.addEventListener("done", close)

        eventSource.onerror = (error) => {
            eventSource.close()
            reject(error)
        }
    })
}
