export function CopyIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            stroke="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g id="Control" clipPath="url(#clip0_3231_13635)">
                <path
                    id="Icon"
                    d="M6.66675 13.3337V15.667C6.66675 16.6004 6.66675 17.0671 6.8484 17.4236C7.00819 17.7372 7.26316 17.9922 7.57676 18.152C7.93328 18.3337 8.39999 18.3337 9.33341 18.3337H15.6667C16.6002 18.3337 17.0669 18.3337 17.4234 18.152C17.737 17.9922 17.992 17.7372 18.1518 17.4236C18.3334 17.0671 18.3334 16.6004 18.3334 15.667V9.33366C18.3334 8.40024 18.3334 7.93353 18.1518 7.57701C17.992 7.2634 17.737 7.00844 17.4234 6.84865C17.0669 6.66699 16.6002 6.66699 15.6667 6.66699H13.3334M4.33341 13.3337H10.6667C11.6002 13.3337 12.0669 13.3337 12.4234 13.152C12.737 12.9922 12.992 12.7372 13.1518 12.4236C13.3334 12.0671 13.3334 11.6004 13.3334 10.667V4.33366C13.3334 3.40024 13.3334 2.93353 13.1518 2.57701C12.992 2.2634 12.737 2.00844 12.4234 1.84865C12.0669 1.66699 11.6002 1.66699 10.6667 1.66699H4.33341C3.39999 1.66699 2.93328 1.66699 2.57676 1.84865C2.26316 2.00844 2.00819 2.2634 1.8484 2.57701C1.66675 2.93353 1.66675 3.40024 1.66675 4.33366V10.667C1.66675 11.6004 1.66675 12.0671 1.8484 12.4236C2.00819 12.7372 2.26316 12.9922 2.57676 13.152C2.93328 13.3337 3.39999 13.3337 4.33341 13.3337Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_3231_13635">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
