import {
    UseMutateFunction,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query"
import { SimpleCard } from "../common/SimpleCard"
import { Task } from "./types/Task"
import { LoadingPulse } from "../common/LoadingPulse"
import axios from "axios"
import { transformSnakeKeysToCamelCase } from "../../utils/transformSnakeKeysToCamelCase"
import { ICall, IParticipant } from "../../types/Call"
import { UserCircle } from "../UserCircle"
import { getFormattedDateTime } from "../../utils/datetime"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendar } from "@fortawesome/free-regular-svg-icons"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"
import { useState } from "react"

export function TaskList({ call }: { call: ICall }) {
    const { addNotification } = useNotification()
    const queryClient = useQueryClient()
    const queryKey = ["call/tasks/", call.id]

    const [isUpdatingTasks, setIsUpdatingTasks] = useState(false)

    const { data: tasks, isPending } = useQuery<Task[]>({
        queryKey,
        queryFn: async () => {
            const results = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/calls/${call.id}/tasks`
            )
            const tasks = results.data
            return tasks.map(transformSnakeKeysToCamelCase)
        },
    })

    const toggleCompletion = useMutation({
        mutationFn: async (variables: {
            taskId: string
            completionState: boolean
        }) => {
            await axios.put(
                `${process.env.REACT_APP_API_DOMAIN}/calls/${call.id}/tasks/${variables.taskId}`,
                { completed: variables.completionState }
            )
        },
        onMutate: () => {
            setIsUpdatingTasks(true)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey })
        },
        onError: (error: any) => {
            queryClient.invalidateQueries({ queryKey })
            addNotification(
                "Failed to update the task",
                `Please try again later. (${error?.toString() || ""})`,
                NotificationType.Error
            )
        },
        onSettled: () => {
            setIsUpdatingTasks(false)
        },
    })

    return (
        <SimpleCard className="p-4">
            <div className="flex flex-col flex-grow justify-start text-base space-y-2">
                <div className="flex flex-row justify-between items-center">
                    <h2 className="text-gray-900 text-xl font-bold leading-7">
                        Tasks
                    </h2>
                </div>
                {isPending ? (
                    <LoadingPulse rows={2} />
                ) : (
                    <TaskListView
                        tasks={tasks}
                        call={call}
                        isUpdating={isUpdatingTasks}
                        setCompleted={toggleCompletion.mutate}
                    />
                )}
            </div>
        </SimpleCard>
    )
}

type CompletionMutation = UseMutateFunction<
    void,
    any,
    {
        taskId: string
        completionState: boolean
    },
    void
>

function TaskListView({
    tasks,
    call,
    isUpdating,
    setCompleted,
}: {
    tasks?: Task[]
    call: ICall
    isUpdating: boolean
    setCompleted: CompletionMutation
}) {
    if (!tasks?.length) {
        return <EmptyTaskList />
    }

    return (
        <div className="flex flex-col gap-4">
            {tasks.map((task) => (
                <TaskItem
                    key={task.description}
                    task={task}
                    call={call}
                    isUpdating={isUpdating}
                    setCompleted={setCompleted}
                />
            ))}
        </div>
    )
}

function EmptyTaskList() {
    return (
        <div className="flex flex-row items-center">
            <span className="text-gray-400">No tasks</span>
        </div>
    )
}

function TaskItem({
    task,
    call,
    isUpdating,
    setCompleted,
}: {
    task: Task
    call: ICall
    isUpdating: boolean
    setCompleted: CompletionMutation
}) {
    const participant = getParticipant(task.assignee, call.parties)

    return (
        <div className="flex flex-row items-center justify-between w-full">
            <div className="flex flex-col items-start justify-between">
                <div className="flex flex-row items-center justify-start">
                    <input
                        type="checkbox"
                        className="h-4 w-4 text-blue-500 border-gray-300 rounded"
                        disabled={isUpdating}
                        checked={task.completed}
                        onChange={() =>
                            setCompleted({
                                taskId: task.id,
                                completionState: !task.completed,
                            })
                        }
                    />
                    <span className="ml-2 text-gray-900">
                        {task.description}
                    </span>
                </div>
                {task.dueDate && (
                    <div className="flex flex-row items-center text-gray-500 text-sm">
                        <span className="ml-6">
                            <FontAwesomeIcon icon={faCalendar} />{" "}
                            {getFormattedDateTime(task.dueDate)}
                        </span>
                    </div>
                )}
            </div>
            {participant && (
                <div
                    data-tooltip-id="tooltip-explanation"
                    data-tooltip-content={participant.name}
                >
                    <UserCircle user={participant} size="28px" />
                </div>
            )}
            {!participant && task.assignee && (
                <span className="text-sm">{task.assignee}</span>
            )}
        </div>
    )
}

function getParticipant(
    assignee?: string | null,
    participants?: IParticipant[] | null
): IParticipant | undefined | null {
    return participants?.find((participant) => participant.email === assignee)
}
