export function getPagesToDisplay(
    currentPage: number,
    pageCount: number
): number[] {
    console.assert(currentPage < pageCount || pageCount === 0)
    console.assert(currentPage >= 0)
    console.assert(pageCount >= 0)

    // This is used to show a window of pages around the current page.
    const currentPageWindowSize = 3 // including the current page

    // + 2 for the first and last page which are fixed
    if (pageCount <= currentPageWindowSize + 2) {
        // There's no need to show "..." in this case as we don't have
        // enough pages.
        return createRange(pageCount)
    }

    // This case is for when we have enough pages to show "..."
    // We want to show a window of pages around the current page and always
    // display the first and last page.
    let startPage = Math.max(1, currentPage - 1)
    let endPage = Math.min(pageCount - 2, currentPage + 1)

    if (currentPage <= 1) {
        endPage = Math.min(pageCount - 2, currentPageWindowSize - 1)
    }

    if (currentPage >= pageCount - 2) {
        startPage = Math.max(1, pageCount - currentPageWindowSize)
    }

    // Here's the first and last page added
    return [0, ...createRange(startPage, endPage), pageCount - 1]
}

function createRange(startOrCount: number, end?: number): number[] {
    if (end === undefined) {
        return Array.from({ length: startOrCount }, (_, index) => index)
    } else {
        return Array.from(
            { length: end - startOrCount + 1 },
            (_, index) => index + startOrCount
        )
    }
}
