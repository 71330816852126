import { useMemo, useState } from "react"
import { ICallTag } from "../../types/Call"
import { Tag } from "../settings/CallTags"

export function TagsPreview(props: { tags: ICallTag[] }) {
    const [showAllTags, setShowAllTags] = useState(false)

    const sortedTags = useMemo(() => {
        return props.tags.sort((a, b) => a.name.localeCompare(b.name))
    }, [props.tags])

    if (props.tags.length === 0) {
        return null
    }

    if (props.tags.length <= 3) {
        return (
            <div className="flex flex-row gap-2">
                {sortedTags.map((tag) => (
                    <Tag key={tag.id} text={tag.name} />
                ))}
            </div>
        )
    }

    return (
        <div
            className="relative"
            onMouseEnter={() => setShowAllTags(true)}
            onMouseLeave={() => setShowAllTags(false)}
        >
            <div className="flex flex-row gap-2">
                <span className="whitespace-nowrap bg-gray-100 rounded-sm px-2 py-1 text-sm flex items-center">
                    {props.tags.length} tags
                </span>
            </div>
            {showAllTags && (
                <div className="absolute top-full left-0 mt-1 bg-white border border-gray-200 rounded shadow-md p-2 z-10">
                    <div className="flex flex-row gap-2 overflow-x-auto">
                        {sortedTags.map((tag) => (
                            <Tag key={tag.id} text={tag.name} />
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}
