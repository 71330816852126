import {
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useState,
} from "react"
import { SearchModal } from "../components/Search"

interface SearchModalContextType {
    openSearchModal: () => void
}

const SearchModalContext = createContext<SearchModalContextType | undefined>(
    undefined
)

// SearchModalProvider allows other components to open the search modal
export const SearchModalProvider = ({ children }: { children: ReactNode }) => {
    const [showSearch, setShowSearch] = useState(false)

    const openSearchModal = () => setShowSearch(true)
    const closeSearchModal = () => setShowSearch(false)

    // Listen for keyboard shortcuts to open and close the search modal
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            // cmd-k or ctrl-k to open the search modal
            if (event.key === "k" && (event.ctrlKey || event.metaKey)) {
                setShowSearch((prev) => !prev)
            }
            if (event.key === "Escape") {
                setShowSearch(false)
            }
        }
        document.addEventListener("keydown", handleKeyDown)
        return () => {
            document.removeEventListener("keydown", handleKeyDown)
        }
    }, [])

    return (
        <SearchModalContext.Provider value={{ openSearchModal }}>
            {children}
            <SearchModal isOpen={showSearch} onClose={closeSearchModal} />
        </SearchModalContext.Provider>
    )
}

export const useSearchModal = () => {
    const context = useContext(SearchModalContext)
    if (context === undefined) {
        throw new Error(
            "useSearchModal must be used within a SearchModalProvider"
        )
    }
    return context
}
