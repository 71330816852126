import { ICallInfo } from "../../types/CallPrep"
import { getFormattedDateTime, getSimpleTime } from "../../utils/datetime"

export function UpcomingCallInfo(props: { callInfo: ICallInfo }) {
    const { callInfo } = props
    return (
        <div className="flex justify-between bg-white p-3 border border-gray-300 rounded-xl space-x-2">
            <CallDetails callInfo={callInfo} />
            {callInfo.meeting_url && (
                <JoinButton meetingUrl={callInfo.meeting_url} />
            )}
        </div>
    )
}

function CallDetails(props: { callInfo: ICallInfo }) {
    const { callInfo } = props
    const endTime = getSimpleTime(callInfo.end_time)
    const startDate = getFormattedDateTime(callInfo.start_time, true)
    return (
        <div className="space-y-2">
            <h2 className="text-lg font-bold">{callInfo.title}</h2>
            <div className="space-x-2">
                <span>{`${startDate} - ${endTime}`}</span>
            </div>
        </div>
    )
}

export function JoinButton(props: { meetingUrl: string }) {
    return (
        <div>
            <a
                className="bg-emerald-500 hover:bg-emerald-400 text-white transition-colors
                            py-2 px-4 rounded-lg h-full hover:cursor-pointer
                            flex items-center"
                href={props.meetingUrl}
                target="_blank"
                rel="noreferrer"
            >
                Join meeting
            </a>
        </div>
    )
}
