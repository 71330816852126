import { ReactNode, useEffect, useRef } from "react"
import ReactDOM from "react-dom"

export function Modal(props: {
    isOpen: boolean
    onClose: () => void
    children: ReactNode
    noCloseButton?: boolean
}) {
    const modalRef = useRef<HTMLDivElement>(null)
    const isOpen = props.isOpen
    const onClose = props.onClose
    const showCloseButton = props.noCloseButton !== true

    // Close the modal if the user presses the escape key
    useEffect(() => {
        const handleEscapeKey = (event: KeyboardEvent) => {
            if (event.key === "Escape" && modalRef.current) {
                onClose()
            }
        }

        if (isOpen) {
            document.addEventListener("keydown", handleEscapeKey)
        } else {
            document.removeEventListener("keydown", handleEscapeKey)
        }

        return () => {
            document.removeEventListener("keydown", handleEscapeKey)
        }
    }, [modalRef, isOpen, onClose])

    // Close the modal if the user clicks outside of it
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target as Node)
            ) {
                onClose()
            }
        }

        function disablePageScroll() {
            document.body.style.overflow = "hidden"
        }

        function enablePageScroll() {
            document.body.style.overflow = ""
        }

        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside)
            disablePageScroll()
        } else {
            document.removeEventListener("mousedown", handleClickOutside)
            enablePageScroll()
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
            enablePageScroll()
        }
    }, [isOpen, onClose])

    if (!isOpen) return null

    return ReactDOM.createPortal(
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-30 backdrop-blur-sm transition-opacity">
            <div
                ref={modalRef}
                className="relative max-w-[100vw] bg-white rounded-lg transition-transform transform scale-100"
            >
                {showCloseButton && (
                    <button
                        className="absolute top-3 right-3 font-bold bg-gray-100 w-7 h-7 text-gray-600 hover:text-delta hover:bg-gray-200 transition-colors"
                        onClick={onClose}
                    >
                        &#10005;
                    </button>
                )}
                <div className="max-h-[calc(100vh-50px)] overflow-auto rounded-lg">
                    {props.children}
                </div>
            </div>
        </div>,
        document.body
    )
}
