import { IUser } from "../../types/User"
import { QuickAction } from "./types/QuickActionTypes"
import { ResourceType } from "./types/ResourceTypes"

const companyQuickActions = [
    {
        title: "😊 Sales Opportunity Score",
        question:
            "Based on the sales calls, emails, and conversations with this potential customer, can you assess if we can win their business and if they are truly interested in purchasing our product?",
    },
    {
        title: "🤔 Risks in the deal",
        question:
            "Based on sales calls, emails, and conversations with this potential customer, can you assess and summarize the key risks that could derail or delay closing the deal?",
    },
    {
        title: "📝 Customer Success Handoff",
        question:
            "Could you please provide a customer success handoff document for onboarding this new customer, outlining the onboarding process, teams involved, required features, and any special requests, keeping a professional and detailed tone?",
    },
]

export function getGlyphicQuickActions(
    user: IUser,
    type: ResourceType
): QuickAction[] {
    // Ideally we would never need the default, but in case, we should at least
    // have something that is understood by the user.
    const userName = user.name ?? "[Name]"
    const organizationName = user.organizationName ?? "[Organization]"

    const followUpEmailQuickAction = {
        title: "✉️ Generate follow up email",
        question:
            "Write a follow-up email to the other organization" +
            ` on the call from me, ${userName}, working at` +
            ` ${organizationName}. Do not return any text other than` +
            " the email text.",
    }

    if (type === ResourceType.Companies) {
        return [followUpEmailQuickAction, ...companyQuickActions]
    } else {
        return [followUpEmailQuickAction]
    }
}
