/**
 * Extracts the domain from the given email address.
 *
 * @param email - The email address from which to extract the domain.
 * @returns The domain extracted from the email address.
 */
export function extractDomain(email?: string | null): string | undefined {
    if (!email) {
        return
    }

    const atIndex = email.lastIndexOf("@")
    if (atIndex === -1 || atIndex === email.length - 1) {
        return
    }

    return email.slice(atIndex + 1).toLowerCase()
}
