import React, { useEffect, useMemo } from "react"

import { IParticipant, ITranscriptTurn } from "../../types/Call"
import { UserCircle } from "../UserCircle"
import { getParticipantDisplayName } from "../../utils/getParticipantDisplayName"
import { ScreenSize, screenSmallerThan } from "../../utils/screenSize"
import { PrimaryButton } from "../common/Buttons"

export function CallTranscript(props: {
    transcriptTurns: ITranscriptTurn[] | null
    highlightedTurns: ITranscriptTurn[]
    parties: IParticipant[]
    followMedia: boolean
    setFollowMedia?: (enabled: boolean) => void
    goToTimestamp: (timestamp: string) => void
}) {
    const handleManualScroll = () => {
        props.setFollowMedia && props.setFollowMedia(false)
    }

    const handleResumeTranscriptScrollClicked = () => {
        props.setFollowMedia && props.setFollowMedia(true)
    }

    // Create map of turnIds to References, to allow us to scroll to a given turn
    const turnToRefMap: Map<
        number,
        React.RefObject<HTMLDivElement>
    > = useMemo(() => {
        if (!props.transcriptTurns) {
            return new Map()
        }

        return new Map(
            props.transcriptTurns.map((turn) => [
                turn.turn_start,
                React.createRef<HTMLDivElement>(),
            ])
        )
    }, [props.transcriptTurns])

    useEffect(() => {
        const scrollToTurn = (turn: ITranscriptTurn) => {
            if (!props.followMedia) {
                return
            }

            if (screenSmallerThan(ScreenSize.md)) {
                // Do not follow media when in mobile view
                return
            }

            const ref = turnToRefMap.get(turn.turn_start)!

            ref.current!.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
            })
        }

        // We need to run this in useEffect as React will not have created the DOM elements on the first render
        // https://beta.reactjs.org/learn/manipulating-the-dom-with-refs#when-react-attaches-the-refs
        if (props.highlightedTurns.length > 0) {
            scrollToTurn(props.highlightedTurns[0])
        }
    }, [props.highlightedTurns, props.followMedia, turnToRefMap])

    if (props.transcriptTurns === null || props.transcriptTurns.length === 0) {
        return (
            <div className="bg-gray-100 text-gray-500 rounded-lg p-3 h-12 text-center w-full">
                No transcript to display
            </div>
        )
    }

    const showResumeAutoScrollButton =
        !props.followMedia && !screenSmallerThan(ScreenSize.md)

    return (
        <div className="bg-white shadow rounded-xl flex-grow overflow-y-auto">
            <div onWheel={handleManualScroll} className="relative">
                <div className="overflow-y-auto w-full h-full p-3 space-y-6">
                    <h1 className="text-xl font-bold">Transcript</h1>
                    {props.transcriptTurns.map((turn) => {
                        const id = turn.turn_start
                        const party = props.parties.find(
                            (p) => p.id === turn.party_id
                        )!
                        return (
                            <TranscriptTurn
                                key={id}
                                transcriptTurn={turn}
                                party={party}
                                isHighlighted={props.highlightedTurns.includes(
                                    turn
                                )}
                                ref={turnToRefMap.get(id)!}
                                goToTimestamp={props.goToTimestamp}
                            />
                        )
                    })}
                </div>
                <PrimaryButton
                    onClick={handleResumeTranscriptScrollClicked}
                    className={`sticky bottom-6 -translate-x-1/2 left-1/2 my-2 whitespace-nowrap transition-opacity ${
                        showResumeAutoScrollButton ? "opacity-100" : "opacity-0"
                    }`}
                >
                    <div className="flex flex-row gap-2 items-center">
                        Resume Transcript Auto-Scroll
                    </div>
                </PrimaryButton>
            </div>
        </div>
    )
}

const TranscriptTurn = React.forwardRef(
    (
        props: {
            transcriptTurn: ITranscriptTurn
            isHighlighted: boolean
            party: IParticipant
            goToTimestamp: (timestamp: string) => void
        },
        ref: React.ForwardedRef<HTMLDivElement>
    ) => {
        const turn = props.transcriptTurn
        const partyName = getParticipantDisplayName(props.party)

        return (
            <div
                className={
                    (props.isHighlighted ? "bg-gray-100 " : " ") +
                    "text-base rounded-md scroll-mt-2 transition-colors p-1"
                }
                ref={ref}
            >
                <div className="text-base flex items-center space-x-2">
                    <UserCircle user={props.party} size="24px" />
                    <span className="font-bold">{partyName}</span>
                    <button
                        className="text-gray-400 hover:font-bold"
                        onClick={() => props.goToTimestamp(turn.timestamp)}
                    >
                        {turn.timestamp}
                    </button>
                </div>

                {turn.turn_text}
            </div>
        )
    }
)
