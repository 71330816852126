const commonPasswordSet = getCommonPasswords()

/**
 * Validates a new password against a list of common passwords.
 *
 * @param password The unhashed password value, do not log this!
 * @returns The error message or undefined if the password is valid
 */
export async function validateUniqueNewPassword(
    password: any
): Promise<string | undefined> {
    // let the default validator handle empty passwords
    if (!password || typeof password !== "string") return undefined

    const cleanPassword = password.toLocaleLowerCase().trim().replace(/_/g, "")
    if (commonPasswordSet.has(cleanPassword)) {
        return "Password must not be a commonly used password"
    }

    return undefined
}

function getCommonPasswords(): Set<string> {
    const leetAlternatives: Record<string, string> = {
        a: "4",
        e: "3",
        i: "1",
        l: "1",
        o: "0",
        s: "5",
        t: "7",
        z: "2",
    }
    const symbolAlternatives: Record<string, string> = {
        a: "@",
        s: "$",
    }

    const commonPasswords = [
        "password",
        "qwerty12",
        "qwerty123",
        "qwertyuiop",
        "abc12345",
        "12344321",
        "123454321",
        "01234567",
        "012345678",
        "0123456789",
        "1234567890",
        "12345678",
        "123456789",
        "letmein1",
        "letmein2",
        "password1",
        "password2",
        "password3",
        "password123",
        "00000000",
        "11111111",
        "1q2w3e",
        "1q2w3e4r",
        "aa12345678",
        "!@#$%^&*",
        "qwertyuiop",
        "1q2w3e4r5t",
        "zxcvbnm",
        "iloveyou",
        "welcome1",
    ]

    return new Set(
        withAlternatives(
            withAlternatives(commonPasswords, leetAlternatives),
            symbolAlternatives
        )
    )
}

function withAlternatives(
    passwords: string[],
    alternativeMap: Record<string, string>
): string[] {
    const leetAlternativesPasswords = passwords.flatMap((password) => {
        return Object.entries(alternativeMap).map(([key, value]) =>
            password.replace(new RegExp(key, "g"), value)
        )
    })

    return [...passwords, ...leetAlternativesPasswords]
}
