import axios from "axios"
import { PropsWithChildren, useEffect } from "react"
import { useSessionContext } from "supertokens-auth-react/recipe/session"
import { identifyHeapUser } from "../utils/heap"
import { transformSnakeKeysToCamelCase } from "../utils/transformSnakeKeysToCamelCase"
import { IUser } from "../types/User"

import { createContext, useContext } from "react"
import { useQuery } from "@tanstack/react-query"

const UserContext = createContext<IUser | undefined>(undefined)

export function UserProvider(props: PropsWithChildren<{}>) {
    const sessionContext = useSessionContext()

    const { data: user } = useQuery<IUser>({
        queryKey: ["user"],
        queryFn: async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_DOMAIN}/user`
                )
                return transformSnakeKeysToCamelCase(response.data)
            } catch (e) {
                console.error("Error fetching user:", e)
                throw e
            }
        },
        enabled: !sessionContext.loading && sessionContext.doesSessionExist,
    })

    useEffect(() => {
        if (user) {
            identifyHeapUser(user.id, user.organizationId)
        }
    }, [user])

    return (
        <UserContext.Provider value={user}>
            {props.children}
        </UserContext.Provider>
    )
}

export function useUser() {
    return useContext(UserContext)
}
