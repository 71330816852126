import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SecondaryButton } from "./Buttons"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { Link, To, useNavigate } from "react-router-dom"

interface BackButtonProps {
    defaultCaption: string
    defaultTo: To
}

export function BackButton(props: BackButtonProps) {
    const navigate = useNavigate()
    const hasPreviousPage = window.history.length > 1

    if (hasPreviousPage) {
        return (
            <SecondaryButton
                className="flex flex-row gap-2 items-center"
                onClick={() => {
                    navigate(-1)
                }}
            >
                <BackButtonContent caption="Back" />
            </SecondaryButton>
        )
    }

    return (
        <Link className="min-w-fit" to={props.defaultTo}>
            <SecondaryButton className="flex flex-row gap-2 items-center">
                <BackButtonContent caption={props.defaultCaption} />
            </SecondaryButton>
        </Link>
    )
}

function BackButtonContent({ caption }: { caption: string }) {
    return (
        <>
            <FontAwesomeIcon className="h-4 w-4" icon={faArrowLeft} />
            <div className="hidden md:block min-w-fit">{caption}</div>
        </>
    )
}
