import { createQueryKeys } from "@lukemorales/query-key-factory"
import axios from "axios"
import { IOrganizationUser } from "../../types/Organization"

export const userQueries = createQueryKeys("users", {
    list: () => ({
        queryKey: ["organization/users"],
        queryFn: async () => {
            const response = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/organization/users`
            )
            return response.data.users as IOrganizationUser[]
        },
    }),
})
