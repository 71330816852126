import React, { useEffect, useState } from "react"
import { Logomark } from "../assets/Logomark"
import clsx from "clsx"

export function Glyphs() {
    let numSymbols = 500
    let maxLogoPosition = Math.min(100, numSymbols) // Occur within the first n symbols so the logo does not appear off screen

    const [symbols, setSymbols] = useState<string[]>([])
    const [logoPosotion] = useState<number>(
        Math.floor(Math.random() * maxLogoPosition)
    )

    // Generate an array of 50 random symbols
    useEffect(() => {
        setSymbols(generateGlyphs(numSymbols))
    }, [numSymbols])

    const [logoFound, setLogoFound] = useState(false)

    return (
        <div className="w-full bg-delta text-delta-pale px-4">
            <div className="text-[60px] font-bold cursor-default">
                <div className="flex items-center flex-wrap space-x-4">
                    {symbols.map((char, index) => (
                        <React.Fragment key={index}>
                            {index === logoPosotion && (
                                <div onClick={() => setLogoFound(true)}>
                                    <Logomark
                                        className={clsx(
                                            "text-current hover:text-sun h-[50px] w-[50px] transition-all duration-1000 ease-out",
                                            logoFound
                                                ? "rotate-[360deg] text-sun"
                                                : "rotate-0  cursor-pointer"
                                        )}
                                    />
                                </div>
                            )}
                            <span className=" hover:text-white transition-colors duration-1000 ease-out">
                                {char}
                            </span>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    )
}

function generateGlyphs(numOfGlyphs: number): string[] {
    // Generates an array of glyphs from the Linear A alphabet https://en.wikipedia.org/wiki/Linear_A_(Unicode_block)

    // Unicode codes of the block of Linear A characters we want to use:
    let startCode = 0x10600
    let endCode = 0x106af

    return Array.from({ length: numOfGlyphs }, () =>
        pickRandomUnicodeChar(startCode, endCode)
    )
}

function pickRandomUnicodeChar(start: number, end: number) {
    // Generate a random number within the specified range
    const randomCodePoint =
        Math.floor(Math.random() * (end - start + 1)) + start
    // Convert the random number to a Unicode character
    return String.fromCodePoint(randomCodePoint)
}
