import { CallStatus } from "../../types/Call"
import StatusIcon, { LockIcon } from "../StatusIcon"

interface CallTitleProps {
    title?: string
    status: CallStatus
    status_reason?: string | null
    is_private: boolean
    language?: string | null
}

export default function CallTitle(props: CallTitleProps) {
    const displayTitle = props.title || "Untitled call"
    return (
        <div className="text-base flex flex-nowrap items-center overflow-clip gap-2 w-full">
            {props.is_private ? (
                <LockIcon />
            ) : (
                <StatusIcon
                    status={props.status}
                    reason={props.status_reason}
                />
            )}
            <span className="font-semibold text-gray-900 truncate overflow-ellipsis whitespace-nowrap">
                {displayTitle}
            </span>
        </div>
    )
}
