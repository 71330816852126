import ThirdPartyEmailPassword, {
    Google,
} from "supertokens-auth-react/recipe/thirdpartyemailpassword"
import EmailVerification from "supertokens-auth-react/recipe/emailverification"
import Session from "supertokens-auth-react/recipe/session"
import { validateUniqueNewPassword } from "./utils/getCommonPasswords"
import { SignInAndUp } from "supertokens-auth-react/recipe/thirdpartyemailpassword/prebuiltui"
import { Glyphs } from "./components/Glyphs"
import microsoftLogo from "./assets/microsoft.png"

const formStyle = `
    [data-supertokens~=superTokensBranding] {
        display:none;
    }
    [data-supertokens~=container] {
        --palette-primary: 26 46 53;
        --palette-primaryBorder: 26 46 53;
        font-family: sans-serif;
    }
`

export const SuperTokensConfig = {
    appInfo: {
        appName: "Sales Copilot by Glyphic",
        apiDomain: process.env.REACT_APP_API_DOMAIN || "http://localhost:8000",
        websiteDomain:
            process.env.REACT_APP_WEBSITE_DOMAIN || "http://localhost:3000",
        apiBasePath: "/auth",
        websiteBasePath: "/auth",
    },
    // recipeList contains all the modules that you want to
    // use from SuperTokens. See the full list here: https://supertokens.com/docs/guides
    recipeList: [
        ThirdPartyEmailPassword.init({
            signInAndUpFeature: {
                disableDefaultUI: true, // Disable displaying of default sign in components on /auth route
                providers: [
                    Google.init(),
                    {
                        id: "microsoft",
                        name: "Microsoft",
                        logo: (
                            <img
                                src={microsoftLogo}
                                alt="Microsoft"
                                className="w-4 h-4"
                            />
                        ),
                    },
                ],
                style: formStyle,
                signUpForm: {
                    formFields: [
                        {
                            id: "password",
                            label: "Password",
                            validate: validateUniqueNewPassword,
                        },
                    ],
                },
            },
            resetPasswordUsingTokenFeature: {
                enterEmailForm: {
                    style: formStyle,
                },
                submitNewPasswordForm: {
                    style: formStyle,
                },
            },
            useShadowDom: false,
        }), // Shadow DOM prevents Safari from autofilling password fields: https://supertokens.com/docs/emailpassword/common-customizations/password-managers
        Session.init({ tokenTransferMethod: "header" }), // Header based auth required to support Safari when API and web URL domains differ, as Safari doesn't allow cross site cookies
        EmailVerification.init({
            mode:
                process.env.REACT_APP_EMAIL_VERIFICATION_MODE === "REQUIRED"
                    ? "REQUIRED"
                    : "OPTIONAL",
        }),
    ],
    async getRedirectionURL(context: any) {
        // The user will be taken to this path when they need to login.
        if (context.action === "TO_AUTH") {
            return "/auth" // return the path where you are rendering the Auth UI
        }
        return "/"
    },
}

export function AuthPage() {
    return (
        <div className="flex h-screen">
            {/* Left half for the login component */}
            <div className="w-full md:w-1/2 px-4 min-w-fit my-auto">
                <SignInAndUp />
            </div>

            {/* Right half for the branded image */}
            <div className="hidden md:block w-1/2 overflow-hidden">
                <Glyphs />
            </div>
        </div>
    )
}
