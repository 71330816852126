import { StatusCircleEmpty } from "../../assets/icons/rubric/StatusCircles"
import { ICompanyAnnotationRubricResults } from "../../types/Company"
import { annotationTypes } from "../../utils/getAnnotationSections"
import { StatusIcon } from "../company-view/Summary"

export function QualificationScores(props: {
    scores: ICompanyAnnotationRubricResults[] | null
    length: number
}) {
    const { scores, length } = props
    return (
        <div className="flex gap-1 items-center justify-evenly w-full overflow-auto">
            {scores &&
                // we show scores in the same order as the annotations
                annotationTypes.map((annotation) => {
                    const score = scores.find(
                        (score) => score.tag === annotation.tag
                    )
                    if (!score) {
                        return null
                    }

                    return (
                        <StatusIcon
                            key={score.name}
                            score={score.score}
                            tag={score.tag}
                            data-tooltip-id="tooltip-explanation"
                            data-tooltip-html={`<b>${score.name}:</b><br/>${score.feedback_headline}`}
                        />
                    )
                })}
            {!scores &&
                Array.from({ length }, (_, i) => (
                    <StatusCircleEmpty
                        key={i}
                        className="w-4 h-4 flex-shrink-0 text-gray-200"
                    />
                ))}
        </div>
    )
}
