import { ButtonHTMLAttributes } from "react"

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>

/**
 * Utility component for rendering a button with priority button styling.
 * @param props Same props as a normal button.
 * @returns Renders a button with priority button styling.
 */
export function PriorityButton(props: ButtonProps) {
    const classNames = `border border-solid transition-colors font-semibold text-sm rounded-lg px-2.5 py-2 bg-sun border-sun hover:bg-sun-dark hover:border-sun-dark text-delta ${props.className}`
    return <button {...props} className={classNames} />
}

/**
 * Utility component for rendering a button with primary button styling.
 * @param props Same props as a normal button.
 * @returns Renders a button with primary button styling.
 */
export function PrimaryButton(props: ButtonProps) {
    const classNames = `border border-solid transition-colors font-semibold text-sm rounded-lg px-2.5 py-2
    ${
        props.disabled
            ? "bg-gray-100 border-gray-300 text-gray-400"
            : "bg-gray-900 border-gray-900 hover:bg-gray-700 text-white"
    }
    ${props.className}`
    return <button {...props} className={classNames} />
}

/**
 * Utility component for rendering a button with secondary button styling.
 * @param props Same props as a normal button.
 * @returns Renders a button with secondary button styling.
 */
export function SecondaryButton(props: ButtonProps) {
    const classNames = `bg-white border border-solid border-gray-300 transition-colors font-semibold text-sm rounded-lg w-fit px-2.5 py-2
        ${props.disabled ? "text-gray-300" : "text-gray-700 hover:bg-gray-200"}
        ${props.className}`
    return <button {...props} className={classNames} />
}

/**
 * Utility component for rendering a button with dangerous button styling.
 * @param props Same props as a normal button.
 * @returns Renders a button with dangerous button styling.
 */
export function DangerousButton(props: ButtonProps) {
    const classNames =
        "transition-colors font-semibold text-sm rounded-lg px-2.5 py-2 " +
        (props.disabled
            ? "text-gray-300"
            : "bg-red-600 hover:bg-red-700 text-white ") +
        props.className
    return <button {...props} className={classNames} />
}
