export function StatusCircleEmpty(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx="8" cy="8" r="7" stroke="currentColor" strokeWidth="2" />
        </svg>
    )
}

export function StatusCircleHalf(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx="8" cy="8" r="7" stroke="currentColor" strokeWidth="2" />
            <path
                d="M13 8C13 10.7614 10.7614 13 8 13C8 13 8.00013 10.7614 8.00013 8C8.00013 5.23858 8 3 8 3C10.7614 3 13 5.23858 13 8Z"
                fill="currentColor"
            />
        </svg>
    )
}

export function StatusCircleThreeQuarters(
    props: React.SVGProps<SVGSVGElement>
) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx="8" cy="8" r="7" stroke="currentColor" strokeWidth="2" />
            <path
                d="M13 8C13 10.7614 10.7614 13 8 13C5.23858 13 3 10.7614 3 8H8V3C10.7614 3 13 5.23858 13 8Z"
                fill="currentColor"
            />
        </svg>
    )
}

export function StatusCircleFull(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx="8" cy="8" r="7" stroke="currentColor" strokeWidth="2" />
            <circle cx="8" cy="8" r="5" fill="currentColor" />
        </svg>
    )
}
