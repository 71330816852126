import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { IOrganization } from "../types/Organization"
import { IntegrationAppClient, useIntegrationApp } from "@integration-app/react"
import { useEffect, useState } from "react"
import { CrmType } from "../components/crm/types/Crm"
import { findValidCrmType } from "../components/crm/utils/integration"

export enum CalendarType {
    Google = "google",
    Microsoft = "microsoft",
}

export function useCrmIntegration() {
    const crmClient = useIntegrationApp()
    const [crmType, setCrmType] = useState<CrmType | undefined>()

    const {
        data: connections,
        error,
        isPending,
    } = useQuery({
        queryKey: ["crmConnections"],
        queryFn: () => fetchConnections(crmClient),
        enabled: !!crmClient,
        staleTime: 60000, // 1 minute
    })

    useEffect(() => {
        if (connections) {
            setCrmType(findValidCrmType(connections))
        } else if (error) {
            console.error("Failed to set CRM connection", error)
        }
    }, [connections, error])

    return { crmType, isPending }
}

export function useIntegrations() {
    const { data: org, isPending: isOrgPending } = useQuery<IOrganization>({
        queryKey: ["organization"],
        queryFn: async () => {
            const response = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/organization`
            )
            return response.data
        },
    })

    const { data: hasGoogleCal, isPending: isGoogleCalPending } = useQuery({
        queryKey: ["calendar", CalendarType.Google],
        queryFn: async () => getCalendarEnablement(CalendarType.Google),
    })
    const { data: hasMicrosoftCal, isPending: isMicrosoftCalPending } =
        useQuery({
            queryKey: ["calendar", CalendarType.Microsoft],
            queryFn: async () => getCalendarEnablement(CalendarType.Microsoft),
        })

    return {
        orgName: org?.name,
        hasGong: org?.integrations.gong || false,
        hasZoom: org?.integrations.zoom || false,
        hasSlack: org?.integrations.slack || false,
        hasGoogleCal: hasGoogleCal || false,
        hasMicrosoftCal: hasMicrosoftCal || false,
        hasCalendar: hasGoogleCal || hasMicrosoftCal,
        hasAircall: org?.integrations.aircall || false,
        isPending: isOrgPending || isGoogleCalPending || isMicrosoftCalPending,
    }
}

async function getCalendarEnablement(
    calendarType: CalendarType
): Promise<boolean> {
    try {
        await axios.get(
            `${process.env.REACT_APP_API_DOMAIN}/call_bot/calendar/${calendarType}`
        )
        return true
    } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 404) {
            return false
        } else {
            console.log(error)
            return false
        }
    }
}

async function fetchConnections(crmClient: IntegrationAppClient) {
    const response = await crmClient.connections.find()
    return response.items
}
