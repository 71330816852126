import { FilterTerm } from "../types/FilterTerm"

/**
 * Filters in columns that match all filter terms.
 *
 * This function assumes that we use a single column for filtering as each
 * column has to match every term. This is intended for tables that have a
 * single hidden search column.
 *
 * @remarks Performance should be improved in the future, but ok for now.
 *
 * @param value Value to match.
 * @param filterTerms List of filter terms to match against.
 * @returns Whether the value should be filtered in/out.
 */
export function multiTermFilter(
    value: string,
    filterTerms: Pick<FilterTerm, "value">[]
): boolean {
    if (filterTerms.length === 0) return true

    const lowerCaseValue = value.toLowerCase()
    const lowerCaseTerms = filterTerms.map((term) => term.value.toLowerCase())
    const matchesAllTerms = lowerCaseTerms.every((filterTerm) =>
        lowerCaseValue.includes(filterTerm)
    )

    return matchesAllTerms
}
