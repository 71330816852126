import axios from "axios"
import React, { useEffect, useState } from "react"
import { ToggleSetting } from "./ToggleSetting"
import { OrgSettingsResponse } from "../../types/Settings"
import { getOrgSettings } from "../../api/Settings"
import { useQuery } from "@tanstack/react-query"
import LoadingSpinner from "../common/LoadingSpinner"

interface RecordingPreferences {
    record_internal: boolean
    record_external: boolean
    record_confirmed: boolean
    record_host: boolean
}

export function MeetingBotConfig(props: { hasExternalCallRecorder: boolean }) {
    const { data: orgSettings, isPending: orgSettingsLoading } =
        useQuery<OrgSettingsResponse>({
            queryKey: ["organization/settings"],
            queryFn: getOrgSettings,
        })

    const [preferences, setPreferences] = useState<RecordingPreferences>({
        record_internal: false,
        record_external: false,
        record_confirmed: false,
        record_host: false,
    })
    const [saveCompleted, setSaveCompleted] = useState<boolean>(false)

    // Fetch prefereneces on startup
    useEffect(() => {
        async function getPreferences() {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_DOMAIN}/call_bot/calendar/preferences`
                )
                const preferences: RecordingPreferences = response.data
                setPreferences({
                    record_internal: preferences.record_internal,
                    record_external: preferences.record_external,
                    record_confirmed: preferences.record_confirmed,
                    record_host: preferences.record_host,
                })
            } catch (error) {
                console.log(error)
            }
        }
        getPreferences()
    }, [])

    function onChangeSetPreferences(preferences: RecordingPreferences) {
        // On user change, set the preference state and send PUT request
        setPreferences(preferences)
        setSaveCompleted(false)
        setRecordingPreferences(preferences, setSaveCompleted)
    }

    const enableAdditionalSettings =
        preferences.record_external || preferences.record_internal

    if (orgSettingsLoading) {
        return <LoadingSpinner />
    }

    return (
        <div className="space-y-4">
            <div className="space-y-2">
                <h2 className="font-bold text-lg">Automatic recording</h2>
                <div className="text-gray-600 text-base">
                    <p>
                        Configure Glyphic Copilot to automatically join your
                        scheduled calls.
                    </p>
                    {props.hasExternalCallRecorder && (
                        <p>
                            If your calls are recorded via Gong or Zoom Cloud,
                            you don't need to record them separately using
                            Glyphic.
                        </p>
                    )}
                </div>
            </div>
            <hr className="my-6" />
            <div className="space-y-4">
                <ToggleSetting
                    name="Record external meetings"
                    description="Record my meetings where at least 1 external attendee."
                    checked={preferences.record_external}
                    disabled={false}
                    onChange={(checked) =>
                        onChangeSetPreferences({
                            ...preferences,
                            record_external: checked,
                        })
                    }
                />
                <ToggleSetting
                    name="Record internal meetings"
                    description="Record my meetings where there are no external attendees."
                    checked={preferences.record_internal}
                    disabled={!orgSettings?.allow_internal_recording}
                    disabledMessage="This setting has been disabled at the organization level"
                    onChange={(checked) =>
                        onChangeSetPreferences({
                            ...preferences,
                            record_internal: checked,
                        })
                    }
                />
            </div>
            <hr className="my-4" />
            <div className="space-y-4">
                <ToggleSetting
                    name="Only record meetings I have accepted"
                    description="Meetings I have declined or not responded to will not be recorded."
                    checked={preferences.record_confirmed}
                    disabled={!enableAdditionalSettings}
                    onChange={(checked) =>
                        onChangeSetPreferences({
                            ...preferences,
                            record_confirmed: checked,
                        })
                    }
                />
                <ToggleSetting
                    name="Only record meetings I am hosting"
                    description="Meetings I am invited to but not hosting will not be recorded."
                    checked={preferences.record_host}
                    disabled={!enableAdditionalSettings}
                    onChange={(checked) =>
                        onChangeSetPreferences({
                            ...preferences,
                            record_host: checked,
                        })
                    }
                />
            </div>
            <div
                className={`flex justify-end text-gray-500 h-6 transition-opacity duration-300 ${
                    saveCompleted ? "opacity-100" : "opacity-0"
                }`}
            >
                Changes saved
            </div>
        </div>
    )
}

export async function setDefaultRecordingPreferences(
    hasExternalCallRecorder: boolean
): Promise<void> {
    // Enable call recording by default, but only if the user does not already
    // have another call recorder integration set up
    const defaultPreferences: RecordingPreferences = {
        record_internal: false,
        record_external: !hasExternalCallRecorder,
        record_confirmed: false,
        record_host: false,
    }
    await setRecordingPreferences(defaultPreferences, () => {})
}

async function setRecordingPreferences(
    preferences: RecordingPreferences,
    setSaveCompleted: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> {
    try {
        await axios.put(
            `${process.env.REACT_APP_API_DOMAIN}/call_bot/calendar/preferences`,
            preferences
        )
        setSaveCompleted(true)
        setTimeout(() => {
            setSaveCompleted(false)
        }, 3000)
    } catch (error) {
        console.log(error)
    }
}
