import colors from "tailwindcss/colors"

// All available tailwind colours
// Removed some of the grays as there are too many
const tailwindColours = [
    // colors.slate,
    // colors.gray,
    // colors.zinc,
    // colors.neutral,
    colors.stone,
    colors.red,
    colors.orange,
    colors.amber,
    colors.yellow,
    colors.lime,
    colors.green,
    colors.emerald,
    colors.teal,
    colors.cyan,
    colors.sky,
    colors.blue,
    colors.indigo,
    colors.violet,
    colors.purple,
    colors.fuchsia,
    colors.pink,
    colors.rose,
]

export function stringToColour(input: string): { [key: string]: string } {
    // Generate hash from string
    let hash = 0
    for (let i = 0; i < input.length; i++) {
        hash = input.charCodeAt(i) + ((hash << 5) - hash)
    }

    // Return colour from hash
    const index = Math.abs(hash) % tailwindColours.length
    return tailwindColours[index]
}
