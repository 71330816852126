import _ from "lodash"
import { IAnnotation, IAnnotationSection } from "../../../types/Annotation"
import { getCurrentUrl } from "../../../utils/getCurrentUrl"

export function getFormattedAnnotationNotes(
    callTitle: string,
    section: IAnnotationSection
): string {
    return getFormattedSectionNotes(callTitle, formatSection(section))
}

export function getFormattedSectionNotes(callTitle: string, notes: string) {
    const url = getCurrentUrl()
    return _.filter([formatTitle(callTitle), formatUrl(url), notes]).join(
        "\n\n"
    )
}

function formatTitle(title: string): string {
    return `"${title}" call notes`
}

function formatUrl(url: string): string {
    return "More details at " + url
}

function formatSection(section: IAnnotationSection | undefined): string {
    if (!section) return ""

    const formattedSection = formatAnnotations(section.annotations)

    return `${section.heading}:\n${formattedSection}`
}

/**
 * Format annotations into a string. This is used for the long form of a
 * section.
 * It will split the summary into lines and add a dash at the beginning of each.
 *
 * @param annotations List of annotations to format.
 * @returns A formatted string with the annotations.
 */
function formatAnnotations(annotations: IAnnotation[]): string {
    return _(annotations)
        .flatMap((annotation) => annotation.summary?.split("\n"))
        .filter()
        .map((line) => `- ${line!.trim()}`)
        .join("\n")
}
