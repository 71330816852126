import { useState, useRef } from "react"
import axios from "axios"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons"

interface FileUploaderProps {
    onFileUpload: (files: File[]) => void
}

export default function FileUploader(props: FileUploaderProps) {
    const [loading, setLoading] = useState(false)
    const fileInputRef = useRef<HTMLInputElement>(null)
    const { addNotification } = useNotification()

    const handleUpload = async () => {
        if (
            fileInputRef.current?.files &&
            fileInputRef.current?.files.length > 0
        ) {
            const files = Array.from<File>(fileInputRef.current.files)
            const formData = new FormData()
            files.forEach((file) => {
                formData.append("files", file)
            })

            try {
                setLoading(true)
                const response = await axios.put(
                    `${process.env.REACT_APP_API_DOMAIN}/case-studies/`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                )

                if (response.status === 201) {
                    props.onFileUpload(files)
                    addNotification(
                        "",
                        "Upload successful!",
                        NotificationType.Success
                    )
                } else {
                    addNotification(
                        "Upload failed!",
                        "Status: " + response.status,
                        NotificationType.Error
                    )
                }
            } catch (error) {
                addNotification("", "Upload failed!", NotificationType.Error)
            } finally {
                setLoading(false)
            }
        }
    }

    return (
        <label className="flex transition-colors font-semibold text-sm rounded-lg px-2.5 py-1.5 bg-sun hover:bg-sun-dark hover:cursor-pointer">
            {!loading ? (
                <>
                    <div className="flex items-center gap-2">
                        <FontAwesomeIcon icon={faFileArrowUp} />
                        <p>Upload</p>
                    </div>
                    <input
                        ref={fileInputRef}
                        className="hidden"
                        type="file"
                        multiple
                        accept="application/pdf"
                        onChange={handleUpload}
                    />
                </>
            ) : (
                "Uploading"
            )}
        </label>
    )
}
