import { AboutWrapper } from "./AboutWrapper"

export function GoogleApiDisclosure() {
    return (
        <AboutWrapper title="Limited use policy for connecting to Google API">
            <div>
                <span>
                    Glyphic AI's use and transfer to any other app of
                    information received from Google APIs will adhere to{" "}
                </span>
                <a
                    href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
                    className="underline"
                >
                    Google API Services User Data Policy
                </a>
                <span>, including the Limited Use requirements.</span>
            </div>
        </AboutWrapper>
    )
}
