import { useState, FormEvent } from "react"
import axios from "axios"
import { CircleLoadingSpinner } from "../../common/CircleLoadingSpinner"
import { RecordingUploader } from "./RecordingUploader"
import { PrimaryButton } from "../../common/Buttons"
import { NotificationType } from "../../common/Notifcations"
import { useNotification } from "../../../providers/NotificationProvider"
import { getLocalDateTime, localToUTC } from "../../../utils/datetime"
import { DateTime } from "luxon"

interface Recording {
    type: "audio" | "video"
    name: string
}

export function CallUploadForm(props: { onSuccess: (callId: string) => void }) {
    const { addNotification } = useNotification()
    const [title, setTitle] = useState("")
    const currentTimeUTC = DateTime.utc().toFormat("yyyy-MM-dd'T'HH:mm'Z'")
    const [startTimeUTC, setStartTimeUTC] = useState(currentTimeUTC)
    const [recording, setRecording] = useState<Recording | null>(null)
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (!title || !recording) return
        setIsLoading(true)
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_DOMAIN}/calls`,
                {
                    title: title,
                    s3_recording_type: recording.type,
                    s3_recording_name: recording.name,
                    start_time: startTimeUTC,
                }
            )
            props.onSuccess(response.data)
        } catch (error) {
            addNotification("Failed to create call", "", NotificationType.Error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <form
            onSubmit={handleSubmit}
            className="mx-auto w-[600px] max-w-full bg-white p-10 rounded-xl shadow-lg space-y-6"
        >
            <h2 className="text-xl font-bold text-gray-800 mb-8">
                Upload call recording
            </h2>
            <TitleInput title={title} setTitle={setTitle} />
            <StartTimeInput
                startTimeUTC={startTimeUTC}
                setStartTimeUTC={setStartTimeUTC}
            />
            <RecordingSection setRecording={setRecording} />
            <div className="pt-8">
                <PrimaryButton type="submit" className="w-full">
                    {isLoading ? (
                        <CircleLoadingSpinner className="mx-auto" />
                    ) : (
                        "Process call"
                    )}
                </PrimaryButton>
            </div>
        </form>
    )
}

function TitleInput(props: {
    title: string
    setTitle: (title: string) => void
}) {
    return (
        <div className="space-y-3">
            <h3 className="text-lg font-semibold text-gray-800">Call title</h3>
            <input
                type="text"
                value={props.title}
                placeholder="Enter call title"
                onChange={(e) => props.setTitle(e.target.value)}
                className="w-full text-lg border border-gray-300 rounded-lg py-3 px-4 pr-10 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                required
            />
        </div>
    )
}

function StartTimeInput(props: {
    startTimeUTC: string
    setStartTimeUTC: (startTime: string) => void
}) {
    const localNoTimezoneISO =
        getLocalDateTime(props.startTimeUTC).toISO({
            includeOffset: false,
        }) || ""

    return (
        <div className="space-y-3">
            <h3 className="text-lg font-semibold text-gray-800">
                Call start time
            </h3>
            <input
                type="datetime-local"
                value={localNoTimezoneISO}
                onChange={(e) => {
                    const utcTime = localToUTC(e.target.value)
                    if (utcTime) {
                        props.setStartTimeUTC(utcTime)
                    }
                }}
                className="w-full text-lg border border-gray-300 rounded-lg py-3 px-4 pr-10 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                required
            />
        </div>
    )
}

function RecordingSection(props: {
    setRecording: (recording: Recording) => void
}) {
    return (
        <div className="space-y-3">
            <h3 className="text-lg font-semibold text-gray-800">Recording</h3>
            <RecordingUploader
                onUpload={(type, name) => props.setRecording({ type, name })}
            />
        </div>
    )
}
