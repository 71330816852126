export default function LoadingMessage({ message }: { message?: string }) {
    return (
        <>
            <style>
                {`@keyframes ellipsis {
                        0% {
                            content: ".";
                        }
                        33% {
                            content: "..";
                        }
                        66% {
                            content: "...";
                        }
                        100% {
                            content: ".";
                        }
                    }

                    .ellipsis::after {
                        min-width: 1em;
                        content: "...";
                        display: inline-block;
                        animation: ellipsis 2s infinite;
                    }`}
            </style>
            <p className="text-gray-500 ellipsis">{message ?? ""}</p>
        </>
    )
}
