interface Heap {
    track: (event: string, properties?: Object) => void
    identify: (identity: string) => void
    resetIdentity: () => void
    addUserProperties: (properties: Object) => void
    addEventProperties: (properties: Object) => void
    removeEventProperty: (property: string) => void
    clearEventProperties: () => void
    appid: string
    userId: string
    identity: string | null
    config: any
}

export function getHeapInstance(): Heap | undefined {
    return (window as any).heap as Heap
}

export function identifyHeapUser(userId: string, organizationId: string) {
    const heap = getHeapInstance()
    heap?.identify(userId)
    heap?.addUserProperties({ organizationId: organizationId })
}
