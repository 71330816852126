import { createQueryKeys } from "@lukemorales/query-key-factory"
import axios from "axios"
import { ICall } from "../../types/Call"

export const callQueries = createQueryKeys("calls", {
    byId: (callId: string) => ({
        queryKey: ["call", callId],
        queryFn: async () => {
            const response = await axios.get(
                process.env.REACT_APP_API_DOMAIN + "/calls/" + callId
            )
            return response.data as ICall
        },
    }),
    media: (callId: string) => ({
        queryKey: ["call/media", callId],
        queryFn: async () => {
            const response = await axios.get(
                process.env.REACT_APP_API_DOMAIN + "/calls/" + callId + "/media"
            )
            return response.data
        },
    }),
})
