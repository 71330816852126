import colors from "tailwindcss/colors"

import santaHat from "../assets/santa_hat.png"
import reindeerHorns from "../assets/reindeer_horns.png"
import elfHat from "../assets/elf_hat.png"

export function isChristmas(): boolean {
    const today = new Date()
    const month = today.getMonth()
    const day = today.getDate()
    return month === 11 && day >= 18
}

export function chrismtastColor(keyStr: string): string {
    const keyHash = hashString(keyStr)
    const options = [
        colors.red[500],
        colors.red[600],
        colors.red[700],
        colors.green[500],
        colors.green[600],
        colors.green[700],
    ]
    return options[keyHash % options.length]
}

export function ChristmasHat(props: { keyStr: string }) {
    const keyHash = hashString(props.keyStr)
    const numOptions = 3
    switch (keyHash % numOptions) {
        case 0:
            return <SantaHat />
        case 1:
            return <ReindeerHorns />
        case 2:
            return <ElfHat />
        default:
            return <SantaHat />
    }
}

function ElfHat() {
    return (
        <img
            src={elfHat}
            className="absolute bottom-[50%] left-[20%] h-full w-full"
            alt="Elf hat"
        />
    )
}
function ReindeerHorns() {
    return (
        <img
            src={reindeerHorns}
            className="absolute bottom-[25%] h-full w-full scale-150"
            alt="Reindeer horns"
        />
    )
}
function SantaHat() {
    return (
        <img
            src={santaHat}
            className="absolute bottom-[40%] right-[20%] h-full w-full"
            alt="Santa hat"
        />
    )
}

function hashString(str: string): number {
    var hash = 0
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }
    hash = Math.abs(hash)
    console.log(str.slice(100), hash, hash % 3)
    return hash
}
