import { Message } from "./types/ThreadTypes"
import { UserMessage, AiMessage } from "./Message"
import { AnswerFeedback } from "./types/QuestionTypes"

export function AskGlyphicThread(props: {
    messages: Message[]
    receivingResponse: boolean
    submitFeedback: (feedback: AnswerFeedback) => Promise<void>
    isReadOnly?: boolean
    sendEmail: (message: string) => Promise<void>
}) {
    return (
        <div className="space-y-3">
            {props.messages.map((msg, i) => {
                if (msg.role === "human") {
                    return <UserMessage key={i} message={msg.content} />
                }
                return (
                    <AiMessage
                        key={i}
                        message={msg.content}
                        isComplete={
                            !props.receivingResponse ||
                            i < props.messages.length - 1
                        }
                        isLast={i === props.messages.length - 1}
                        submitFeedback={props.submitFeedback}
                        isReadOnly={props.isReadOnly}
                        sendEmail={props.sendEmail}
                    />
                )
            })}
        </div>
    )
}
