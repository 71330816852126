import { useFlow } from "@frigade/react"
import * as Frigade from "@frigade/react"
import { useEffect } from "react"
import { ScreenSize, screenSmallerThan } from "../utils/screenSize"
import { useUser } from "../providers/UserProvider"
import { useQuery } from "@tanstack/react-query"
import { ICompany } from "../types/Company"
import axios from "axios"

const FRIGADE_API_KEY = process.env.REACT_APP_FRIGADE_API_KEY!

// Theme with Glyphic colours
const FRIGADE_THEME_OVERRIDES = {
    colors: {
        primary: {
            border: "#ffc267",
            surface: "#ffc267",
            foreground: "#000000",
            hover: {
                surface: "#ffdaa2",
            },
        },
    },
}

export function FrigadeProvider(props: { children: React.ReactNode }) {
    const user = useUser()
    if (!user) return <>{props.children}</>

    return (
        <Frigade.Provider
            apiKey={FRIGADE_API_KEY}
            userId={user.id}
            userProperties={{
                name: user.name,
                email: user.email,
                organizationName: user.organizationName,
                organizationId: user.organizationId,
            }}
            theme={FRIGADE_THEME_OVERRIDES}
        >
            {props.children}
        </Frigade.Provider>
    )
}

export function FrigadeAskGlyphicTour() {
    const flowId = "flow_dBasaGuJ"
    const { flow } = useFlow(flowId)

    const stepToElementMap = {
        "ask-glyphic-2": "frigade-ask-glyphic-entry",
        "ask-glyphic-3": "frigade-ask-glyphic-entry",
        "ask-glyphic-4": "frigade-ask-glyphic-entry",
        "ask-glyphic-5": "frigade-create-quick-action",
        "ask-glyphic-6": "frigade-save-quick-action",
    }

    useFlowStepClickListeners(flowId, stepToElementMap)

    // Proceed these steps on enter press in the AskGlyphic field
    useFlowStepProceedOnEnter(flowId, [
        "ask-glyphic-2",
        "ask-glyphic-3",
        "ask-glyphic-4",
    ])

    if (!flow?.isVisible) return null

    // Don't display on mobile
    if (screenSmallerThan(ScreenSize.md)) return null

    return <Frigade.Tour flowId={flowId} zIndex={100} />
}

export function FrigadeCallToCompanyTour(props: { companyDomain: string }) {
    const flowId = "flow_DToUegvu"
    const { flow } = useFlow(flowId)

    const { data: company } = useQuery<ICompany>({
        queryKey: ["company", props.companyDomain],
        queryFn: async () => {
            const response = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/companies/${props.companyDomain}`
            )
            return response.data
        },
        enabled: !!flow && flow?.isVisible,
    })

    const stepToElementMap = {
        "company-tooltip-1": "frigade-call-companies-list",
    }
    useFlowStepClickListeners(flowId, stepToElementMap)

    if (!flow?.isVisible) return null

    // Don't display on mobile
    if (screenSmallerThan(ScreenSize.md)) return null

    // Only display if the company has multiple calls
    const numberOfCalls = company?.calls.length || 0
    if (!company || numberOfCalls < 2) return null

    return (
        <Frigade.Tour
            flowId={flowId}
            variables={{
                companyName: company.name || company.domain,
                callCount: numberOfCalls,
            }}
        />
    )
}

export function FrigadeCallNewTagTour() {
    const flowId = "flow_riYRZqPX"
    const { flow } = useFlow(flowId)

    if (!flow?.isVisible) return null
    if (screenSmallerThan(ScreenSize.md)) return null

    return <Frigade.Tour flowId={flowId} />
}

// Some tours require steps to be completed on click of specific elements
// To support that without adding extra onclick handlers throughout the code base,
// we can use this hook to add event listeners for the given (stepId, elementId) pairs
const useFlowStepClickListeners = (
    flowId: string,
    stepToElementMap: Record<string, string>
) => {
    const { flow } = useFlow(flowId)

    useEffect(() => {
        // Create the click handlers for each step, which will complete that step on click
        const stepToHandleClickMap: Record<string, () => void> = {}
        Object.entries(stepToElementMap).forEach(([stepId]) => {
            stepToHandleClickMap[stepId] = () => {
                if (flow?.getCurrentStep()?.id === stepId) {
                    flow?.steps?.get(stepId)?.complete()
                }
            }
        })

        // Connect the click handlers to the correct element
        const addEventListeners = () => {
            Object.entries(stepToElementMap).forEach(([stepId, elementId]) => {
                if (stepId !== flow?.getCurrentStep()?.id) {
                    return
                }
                const element = document.getElementById(elementId)
                const handleClick = stepToHandleClickMap[stepId]
                // Don't add the event listener if it's already attached
                if (element && !element.dataset.frigadeListenerAttached) {
                    element.addEventListener("click", handleClick)
                    // Mark the element as having a listener attached
                    element.dataset.frigadeListenerAttached = "true"
                }
            })
        }

        // Observe the DOM for changes to add event listeners, as elements may not be rendered yet
        const observer = new MutationObserver(addEventListeners)
        observer.observe(document.body, { childList: true, subtree: true })

        // Initial check in case elements are already rendered
        addEventListeners()

        // Cleanup function to remove all event listeners and disconnect the observer
        return () => {
            observer.disconnect()
            Object.entries(stepToElementMap).forEach(([stepId, elementId]) => {
                const element = document.getElementById(elementId)
                const handleClick = stepToHandleClickMap[stepId]
                if (element && element.dataset.frigadeListenerAttached) {
                    element.removeEventListener("click", handleClick)
                    delete element.dataset.frigadeListenerAttached // Clean up the attribute
                }
            })
        }
    }, [flow, stepToElementMap])
}

// Allow certain steps to be marked complete on enter press
// Eg where they can be completed by submitting a form
const useFlowStepProceedOnEnter = (
    flowId: string,
    stepsToProceedOnEnter: string[]
) => {
    const { flow } = useFlow(flowId)

    useEffect(() => {
        // Handle Enter key press to complete the current step
        const handleEnterKeyPress = (event: KeyboardEvent) => {
            if (event.key === "Enter") {
                const currentStepId = flow?.getCurrentStep()?.id
                if (
                    currentStepId &&
                    stepsToProceedOnEnter.includes(currentStepId)
                ) {
                    flow?.steps?.get(currentStepId)?.complete()
                }
            }
        }

        // Attach keydown event listener for Enter key
        document.addEventListener("keydown", handleEnterKeyPress)

        // Cleanup function to remove the event listener
        return () => {
            document.removeEventListener("keydown", handleEnterKeyPress)
        }
    }, [flow, stepsToProceedOnEnter])
}
