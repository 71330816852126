import FileUploader from "./FileUpload"
import { useCallback, useEffect, useState } from "react"
import ResourceList from "./ResourceList"
import { useUser } from "../../providers/UserProvider"
import { IResource } from "./types/ResourceTypes"
import { FeatureFlags, canUseFeature } from "../../utils/FeatureFlags"
import { useNotification } from "../../providers/NotificationProvider"
import axios from "axios"
import { NotificationType } from "../common/Notifcations"
import ResourceSearch from "./ResourcesSearch"
import { CaseStudyContent } from "../call-prep/CaseStudies"

export default function Resources() {
    const user = useUser()
    const canShowResources = canUseFeature(
        FeatureFlags.ShowResources,
        user?.organizationId
    )

    const { addNotification } = useNotification()
    const [resources, setResources] = useState<IResource[]>([])
    const [loadingResources, setLoadingResources] = useState<boolean>(true)

    const fetchResources = useCallback(async () => {
        try {
            setLoadingResources(true)
            const response = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/case-studies/`
            )
            const caseStudiesResponse: CaseStudyContent[] = response.data.cases
            console.log("Fetched case studies: ", caseStudiesResponse)
            const caseStudiesResources: IResource[] = caseStudiesResponse.map(
                (caseStudy) => ({
                    id: caseStudy.id,
                    name: caseStudy.filename,
                })
            )
            setResources(caseStudiesResources)
            setLoadingResources(false)
        } catch (error) {
            addNotification(
                "",
                "Error occurred while fetching case studies",
                NotificationType.Error
            )
        }
    }, [addNotification])

    useEffect(() => {
        fetchResources()
    }, [fetchResources])

    if (!canShowResources) {
        return null
    }

    return (
        <section className="mx-auto max-w-5xl px-6 py-4 first:pt-12 space-y-8">
            <div className="flex justify-between items-center">
                <h1 className="text-2xl font-bold">Resources</h1>
                <div className="flex justify-between align-middle">
                    <div className="flex flex-col gap-1 justify-end items-end">
                        <FileUploader onFileUpload={fetchResources} />
                        <p className="text-xs text-gray-500">
                            Works best if each file is a single case study
                        </p>
                    </div>
                </div>
            </div>

            <div className="space-y-4">
                <ResourceSearch />
                <ResourceList
                    resources={resources}
                    refreshResourceList={fetchResources}
                    isLoading={loadingResources}
                />
            </div>
        </section>
    )
}
