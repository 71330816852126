import { Cell, Row, flexRender } from "@tanstack/react-table"
import { ICRMDeal } from "../../types/Deal"
import { NavigateLink } from "../common/NavigateLink"
import clsx from "clsx"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"
import { Fragment, ReactNode, useState } from "react"
import LoadingSpinner from "../common/LoadingSpinner"
import { Modal } from "../common/Modal"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { getHeapInstance } from "../../utils/heap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { DealDetailsColumnMeta } from "./DealDetailsTable"

interface DealDetailsRowProps {
    row: Row<ICRMDeal>
}

export default function DealDetailsRow({ row }: DealDetailsRowProps) {
    const companyExists = row.original.company !== null

    if (row.getIsGrouped()) {
        return <StageGroupHeader row={row} />
    }

    return (
        <Fragment key={row.id}>
            <tr
                className={clsx(
                    "h-16 border-b last:border-0 bg-white rounded-xl shadow-md shadow-gray-100",
                    "hover:bg-slate-100 hover:cursor-pointer transition-colors ease-out"
                )}
            >
                {row.getVisibleCells().map((cell) => (
                    <td
                        key={cell.id}
                        className="h-16 first:rounded-l-xl last:rounded-r-xl"
                    >
                        <DealDetailsCell
                            cell={cell}
                            companyExists={companyExists}
                        />
                    </td>
                ))}
            </tr>
        </Fragment>
    )
}

function DealDetailsCell({
    cell,
    companyExists,
}: {
    cell: Cell<ICRMDeal, unknown>
    companyExists: boolean
}) {
    const cellContent = flexRender(
        cell.column.columnDef.cell,
        cell.getContext()
    )

    const isEditable =
        (cell.column.columnDef.meta as DealDetailsColumnMeta)?.isEditable ??
        false

    if (isEditable) {
        // If the cell is editable, we don't want to wrap it in a link
        return (
            <div className="flex w-full h-full items-center p-2 overflow-hidden">
                {cellContent}
            </div>
        )
    }

    if (companyExists) {
        return (
            <NavigateLink
                href={`/companies/${cell.row.original.company?.domain}`}
                className="flex w-full h-full items-center p-2 overflow-hidden"
                onClick={() => trackDealRowClick(true)}
            >
                {cellContent}
            </NavigateLink>
        )
    }

    return (
        <DealWithoutCompanyLink
            crmDealId={cell.row.original.id}
            cellContent={cellContent}
        />
    )
}

function StageGroupHeader(props: { row: Row<ICRMDeal> }) {
    const subRows = props.row.subRows
    const stageName = subRows[0].original.stage?.name
    const totalAmount = subRows
        .reduce((acc, row) => acc + (row.original.amount || 0), 0)
        .toLocaleString()
    const numDealsInStage = subRows.length

    const numDealsTooltipHtml = `<b>${numDealsInStage}</b> deals in <b>${stageName}</b> stage</br>with the currently applied filters.`
    const totalAmountTooltipHtml = `Total value of all <b>${numDealsInStage}</b> deals in <b>${stageName}</b> stage</br>with the currently applied filters is <b>${totalAmount}</b>.`

    // If the amount column is show, display the totalAmount in line with that.
    // We currently assume that the amount column is the final column.
    // Otherwise display it at the end of the header row.
    const amountColumnShown =
        props.row
            .getVisibleCells()
            .find((cell) => cell.column.id === "amount") !== undefined
    const colSpan =
        props.row.getVisibleCells().length - (amountColumnShown ? 1 : 0)

    const underlineClass = props.row.getIsExpanded()
        ? ""
        : "border-b-[1px] border-gray-200"

    return (
        <tr
            className="bg-none hover:bg-gray-200 transition-colors h-16 cursor-pointer"
            onClick={() => {
                // Allow group collapse/expand by clicking on the row
                props.row.toggleExpanded()
            }}
        >
            <td
                className={clsx(
                    "p-2 space-x-4 w-full overflow-auto first:rounded-tl-xl last:rounded-tr-xl relative",
                    underlineClass
                )}
                colSpan={colSpan}
            >
                <FontAwesomeIcon
                    icon={faChevronDown}
                    className={clsx(
                        "text-gray-500 transition-transform",
                        !props.row.getIsExpanded() && "-rotate-90"
                    )}
                />

                <span
                    className="text-white bg-gray-400 px-2 rounded-lg inline-flex min-w-[36px] justify-center font-semibold"
                    data-tooltip-id="tooltip-explanation"
                    data-tooltip-html={numDealsTooltipHtml}
                >
                    {numDealsInStage}
                </span>
                <span className="font-bold text-base">{stageName}</span>
                {!amountColumnShown && (
                    <span
                        className="absolute right-4 font-semibold"
                        data-tooltip-id="tooltip-explanation"
                        data-tooltip-html={totalAmountTooltipHtml}
                    >
                        {totalAmount}
                    </span>
                )}
            </td>
            {amountColumnShown && (
                <td
                    className={clsx(
                        "p-2 last:rounded-tr-xl text-right pr-12 font-semibold",
                        underlineClass
                    )}
                    data-tooltip-id="tooltip-explanation"
                    data-tooltip-html={totalAmountTooltipHtml}
                >
                    {totalAmount}
                </td>
            )}
        </tr>
    )
}

function DealWithoutCompanyLink(props: {
    crmDealId: string
    cellContent: ReactNode
}) {
    const navigate = useNavigate()
    const { addNotification } = useNotification()
    const [isCreatingCompany, setIsCreatingCompany] = useState(false)
    const queryClient = useQueryClient()

    // No Company record yet exists for this deal in our system, so first attempt to create one
    // then redirect to the newly created company page
    const createCompanyMutation = useMutation({
        mutationFn: async (dealId: string) => {
            const response = await axios.post(
                `${process.env.REACT_APP_API_DOMAIN}/deals/crm/${dealId}/company`
            )
            return response.data.redirect_url
        },
        onMutate: () => {
            setIsCreatingCompany(true)
        },
        onSuccess: (redirectUrl: string) => {
            // Redirect to the newly created company page
            navigate(redirectUrl)
            // Invalidate the deals query so that the new company info is shown in the deals list
            queryClient.invalidateQueries({ queryKey: ["deals"] })
        },
        onError: (error: any) => {
            const errorMsg = error.response?.data?.detail || "Unknown error"
            addNotification(
                "Failed to link to company",
                errorMsg,
                NotificationType.Error
            )
        },
        onSettled: () => {
            setIsCreatingCompany(false)
        },
    })

    return (
        <button
            className="flex w-full h-full items-center p-2 overflow-hidden text-left"
            onClick={() => {
                createCompanyMutation.mutate(props.crmDealId)
                trackDealRowClick(false)
            }}
        >
            {props.cellContent}

            {/* Creating the company can take a few seconds, so show a loading popup */}
            <Modal isOpen={isCreatingCompany} onClose={() => {}}>
                <div className="p-10">
                    <span className="">Importing deal into Glyphic...</span>
                    <div className="pt-20">
                        <LoadingSpinner />
                    </div>
                </div>
            </Modal>
        </button>
    )
}

function trackDealRowClick(companyExists: boolean) {
    getHeapInstance()?.track("deals-list-row-clicked", { companyExists })
}
