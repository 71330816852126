import axios from "axios"

export function parseAxiosError(error: any): string {
    if (axios.isAxiosError(error) && error.response?.data?.detail) {
        if (
            typeof error.response.data.detail === "object" &&
            error.response.data.detail.message
        ) {
            return error.response.data.detail.message
        } else if (typeof error.response.data.detail === "string") {
            return error.response.data.detail
        }
    }
    return ""
}
