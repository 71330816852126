import { IUser } from "../../../types/User"
import { hasPermission, Permission } from "../../../utils/Permissions"
import { AccessLevel, SavedQuickAction } from "../types/QuickActionTypes"

export function hasEditPermission(
    user: IUser | undefined,
    quickAction: SavedQuickAction
) {
    if (quickAction.access_level === AccessLevel.Default) {
        return false
    }
    if (!user) {
        return false
    }
    if (quickAction.user_id === user.id) {
        return true
    }
    if (
        [AccessLevel.Organization, AccessLevel.Team].includes(
            quickAction.access_level
        ) &&
        user.organizationId === quickAction.organization_id
    ) {
        return hasPermission(user, Permission.EDIT_ORG_QUICK_ACTIONS)
    }
    return false // This case should not be hit, as other user's personal quick actions should not be visible
}
