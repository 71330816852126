import { IUser } from "../types/User"

export enum Permission {
    VIEW_EDIT_ORG_SETTINGS = "view_edit_org_settings",
    EDIT_ORG_QUICK_ACTIONS = "edit_org_quick_actions",
    CONNECT_CALENDAR = "connect_calendar",
    EDIT_CRM_DEALS = "edit_crm_deals",
}

export function hasPermission(user: IUser, permission: Permission) {
    return user.permissions.includes(permission)
}
