// These enums match the TailwindCSS breakpoint sizes in pixels
// https://tailwindcss.com/docs/responsive-design
export enum ScreenSize {
    sm = 640,
    md = 768,
    lg = 1024,
    xl = 1280,
}

export const screenSmallerThan = (size: ScreenSize): boolean => {
    return window.innerWidth < size
}

export const screenLargerOrEqualTo = (size: ScreenSize): boolean => {
    return !screenSmallerThan(size)
}
