import { useState, useEffect, useCallback } from "react"

import { SelectCrmContact, SelectedOption } from "./SelectCrmRecords"
import { search } from "./utils/integration"
import { Modal } from "../common/Modal"
import LoadingSpinner from "../common/LoadingSpinner"
import {
    CrmTable,
    CrmType,
    ICrmContact,
    ICrmObjectReference,
} from "./types/Crm"
import SendButton from "./SendButton"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"
import { useIntegrationApp } from "@integration-app/react"
import axios from "axios"
import { useQuery } from "@tanstack/react-query"
import { getHeapInstance } from "../../utils/heap"

interface CrmSyncWindowProps {
    crmType: CrmType
    callId: string
    notes: string
    isOpen: boolean
    onClose: () => void
}

/**
 * This component renders a modal window with a form to select a CRM contact,
 * preview the data and send it to the CRM.
 * The user has to select an Contact for the Send button to be enabled.
 */
export default function CrmSyncWindow(props: CrmSyncWindowProps) {
    const crmClient = useIntegrationApp()
    const crmType = props.crmType
    const { addNotification } = useNotification()

    const { data: crmObjects, isPending: isLoadingContacts } = useQuery<
        ICrmObjectReference[]
    >({
        queryKey: [`calls/${props.callId}/crm/objects`],
        queryFn: async () => {
            const response = await axios.get(
                process.env.REACT_APP_API_DOMAIN +
                    `/calls/${props.callId}/crm/objects`
            )
            return response.data as ICrmObjectReference[]
        },
    })

    const matchedContacts =
        crmObjects?.filter(
            (crmObject) => crmObject.crm_table === CrmTable.Contacts
        ) ?? []

    const [notes, setNotes] = useState<string>(props.notes)

    useEffect(() => {
        setNotes(props.notes)
    }, [props.notes])

    const loadContacts = useCallback(
        async (contactSearchQuery: string) =>
            search(crmClient, crmType, CrmTable.Contacts, contactSearchQuery),
        [crmClient, crmType]
    )

    // Action called when the user clicks on the Send button, syncs with
    // the CRM.
    // Currently it uploads the call text as notes in the selected Contact.
    async function onSend(selectedContactEmails: string[]) {
        console.assert(selectedContactEmails.length > 0, "No contact selected")

        setIsSyncing(true)
        try {
            await axios.post(
                `${process.env.REACT_APP_API_DOMAIN}/calls/${props.callId}/crm/notes`,
                {
                    contact_emails: selectedContactEmails,
                    notes,
                }
            )
            addNotification(
                "Sent to CRM!",
                "You can find it in the notes.",
                NotificationType.Success
            )
            props.onClose()
        } catch (error) {
            console.error("Failed to sync CRM", error)
            addNotification(
                "Failed to sync CRM",
                `${error}`,
                NotificationType.Error
            )
        } finally {
            setIsSyncing(false)
        }
    }

    const [isSyncing, setIsSyncing] = useState<boolean>(false)

    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
            {isLoadingContacts || isSyncing || !notes ? (
                <div className="p-8 space-y-4">
                    <LoadingSpinner />
                </div>
            ) : (
                <SyncForm
                    loadContacts={loadContacts}
                    matchedContacts={matchedContacts}
                    notes={notes}
                    onSend={onSend}
                    onNotesChange={setNotes}
                />
            )}
        </Modal>
    )
}

type SendFunction = (selectedContactEmails: string[]) => void

interface SyncFormProps {
    loadContacts: (contactSearchQuery: string) => Promise<ICrmContact[]>
    matchedContacts: ICrmObjectReference[]
    notes: string
    onSend: SendFunction
    onNotesChange: (notes: string) => void
}

function SyncForm(props: SyncFormProps) {
    const [selectedContacts, setSelectedContacts] = useState<SelectedOption[]>(
        props.matchedContacts
            .filter((contact) => contact.email)
            .map((contact) => ({
                label: contact.name || contact.email!,
                value: contact.email!,
            }))
    )

    const hasContacts = selectedContacts && selectedContacts?.length > 0
    // We can send when there's a contact selected.
    const canSend = hasContacts

    const handleSend = () => {
        getHeapInstance()?.track("call-sync-notes-clicked")

        if (selectedContacts?.length === 0) return

        props.onSend(selectedContacts!.map((option) => option.value))
    }

    const handleNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
        props.onNotesChange(event.target.value)

    return (
        <div className="bg-gray-50 m-auto max-w-5xl rounded-lg border flex flex-col">
            <div className="p-8 space-y-4">
                <h2 className="font-bold text-xl">
                    Sync call data to your CRM
                </h2>
                <div className="flex grow flex-auto flex-col md:flex-row justify-between gap-6">
                    {/* Notes preview section */}
                    <div className="w-5/6">
                        <p className="font-semibold text-base mb-3">Notes</p>
                        {/* I don't know a better way to wrap this correctly */}
                        <div className="max-h-96 rounded-md border border-solid border-gray-300">
                            <textarea
                                className="bg-white p-4 text-gray-900 w-full h-full max-h-screen overflow-auto outline-none"
                                value={props.notes}
                                onChange={handleNotesChange}
                                cols={90}
                                rows={16}
                                style={{ resize: "none" }}
                            />
                        </div>
                    </div>
                    {/* Column 2 */}
                    <div className="w-1/6 min-w-[250px] space-y-3">
                        <div>
                            <p className="font-bold mb-3">Contacts</p>
                            <SelectCrmContact
                                loadContacts={props.loadContacts}
                                setSelectedValue={setSelectedContacts}
                                selectedContacts={selectedContacts}
                                className="bg-white rounded-md width-full"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* Footer section with the send button */}
            <div className="bg-gray-100 border-t rounded-b-lg p-3 flex justify-end align-right w-full">
                <SendButton disabled={!canSend} onClick={handleSend} />
            </div>
        </div>
    )
}
