import clsx from "clsx"
import { NavigateLink } from "./NavigateLink"

interface BadgeProps {
    className?: string
    label: string
    image_url?: string | null
    href?: string
}

export default function Badge(props: BadgeProps) {
    return (
        <NavigateLink
            className={clsx(
                "flex items-center gap-1 w-fit h-fit px-2 py-1 rounded",
                props.className
            )}
            href={props.href || ""}
        >
            {props.image_url && (
                <img
                    src={props.image_url}
                    alt={`${props.label} logo`}
                    className="w-[18px] h-[18px] object-cover rounded-sm"
                ></img>
            )}
            <span className="whitespace-nowrap overflow-hidden overflow-ellipsis">
                {props.label}
            </span>
        </NavigateLink>
    )
}
