import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useNotification } from "../../providers/NotificationProvider"
import axios from "axios"
import { NotificationType } from "../common/Notifcations"
import { queries } from "../../api/queries"
import { ICallTag } from "../../types/Call"
import { useState } from "react"
import { TagInput } from "./TagInput"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons"
import { stringToColour } from "../../utils/stringToColour"
import { Tag } from "../settings/CallTags"
import { FrigadeCallNewTagTour } from "../Frigade"

const useDeleteTag = (callId: string) => {
    const queryClient = useQueryClient()
    const { addNotification } = useNotification()

    return useMutation({
        mutationFn: async (tagId: string) => {
            await axios.delete(
                `${process.env.REACT_APP_API_DOMAIN}/calls/${callId}/tags/${tagId}`
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: queries.calls.byId(callId).queryKey,
            })
        },
        onError: (error) => {
            addNotification(
                "Failed to delete tag",
                `${error}`,
                NotificationType.Error
            )
        },
    })
}

const useAddTag = (callId: string) => {
    const queryClient = useQueryClient()
    const { addNotification } = useNotification()

    return useMutation({
        mutationFn: async (tagName: string) => {
            await axios.post(
                `${process.env.REACT_APP_API_DOMAIN}/calls/${callId}/tags`,
                {
                    name: tagName,
                }
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: queries.calls.byId(callId).queryKey,
            })
        },
        onError: (error) => {
            addNotification(
                "Failed to add tag",
                `${error}`,
                NotificationType.Error
            )
        },
    })
}

export function CallTags(props: { tags?: ICallTag[]; callId: string }) {
    const [isAddingTag, setIsAddingTag] = useState(false)
    const addTag = useAddTag(props.callId)

    const handleAddTag = (newTag: string) => {
        const trimmedTag = newTag.trim()
        if (trimmedTag) {
            addTag.mutate(trimmedTag, {
                onSuccess: () => {
                    setIsAddingTag(false)
                },
            })
        }
    }

    return (
        <div className="flex gap-2 w-full">
            <h3 className="text-base font-bold flex-none">Tags:</h3>
            <div className="flex gap-2 items-center flex-wrap relative">
                {props.tags
                    ?.sort((a, b) => a.name.localeCompare(b.name))
                    .map((tag) => (
                        <RemovableTag
                            key={tag.id}
                            callId={props.callId}
                            tag={tag}
                        />
                    ))}
                {isAddingTag ? (
                    <TagInput
                        existingTags={props.tags?.map((tag) => tag.name) || []}
                        onAddTag={handleAddTag}
                        onCancel={() => setIsAddingTag(false)}
                    />
                ) : (
                    <AddTagButton onClick={() => setIsAddingTag(true)} />
                )}
            </div>
        </div>
    )
}

function RemovableTag(props: { tag: ICallTag; callId: string }) {
    const [showDelete, setShowDelete] = useState(false)
    const deleteTag = useDeleteTag(props.callId)
    const tagColour = stringToColour(props.tag.name)

    return (
        <div
            className="relative"
            onMouseEnter={() => setShowDelete(true)}
            onMouseLeave={() => setShowDelete(false)}
        >
            <Tag text={props.tag.name} />
            {showDelete && (
                <button
                    className="absolute right-0 top-0 bottom-0 rounded-r-md p-0.5 px-1.5 text-gray-500 hover:text-gray-700 transition-colors"
                    style={{ backgroundColor: tagColour[200] }}
                    onClick={() => deleteTag.mutate(props.tag.id)}
                >
                    <FontAwesomeIcon icon={faXmark} className="w-3 h-3" />
                </button>
            )}
        </div>
    )
}

function AddTagButton({ onClick }: { onClick: () => void }) {
    return (
        <>
            <FrigadeCallNewTagTour />
            <button
                id="frigade-call-new-tag"
                className="px-2 py-1 text-sm gap-1 rounded-md border border-gray-200 flex items-center hover:bg-gray-100 transition-colors"
                onClick={onClick}
            >
                <FontAwesomeIcon
                    icon={faPlus}
                    className="w-3 h-3 text-gray-500"
                />
                New Tag
            </button>
        </>
    )
}
