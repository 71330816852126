import { ResourceType } from "./ResourceTypes"

export interface QuickAction {
    question: string
    title: string | null
}

export enum AccessLevel {
    Personal = "personal",
    Team = "team",
    Organization = "organization",
    // Default quick actions are currently only defined in the front end and can't be saved.
    Default = "default",
}

export interface SavedQuickAction extends QuickAction {
    _id: string
    organization_id: string
    user_id: string
    user_name: string | null
    resource_type: ResourceType
    access_level: AccessLevel
    created_at_utc: string
}
