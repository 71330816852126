// Utils for ReleaseNotes.io

export function displayReleaseNotesModal() {
    /* @ts-ignore */
    rnw("show")
}

export function displayReleaseNotesModalIfNew() {
    // If this user has unseen release notes, display them
    // window.rnw.unseen_count is set in the ReleaseNotes widget
    /* @ts-ignore */
    if (window.rnw.unseen_count > 0) {
        displayReleaseNotesModal()
    }
}
