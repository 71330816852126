/**
 * Transforms the top level keys of an object from snake_case to camelCase.
 *
 * @param obj object to transform, please note the object cannot contain self
 *   references!
 * @returns a new object with the top level keys transformed from snake_case
 *   to camelCase.
 */
export function transformSnakeKeysToCamelCase<T extends Record<string, any>>(
    obj: T
): T {
    const transformedObj = {} as T

    for (const [key, value] of Object.entries(obj)) {
        // Replace all underscores followed by a lowercase letter with the
        // uppercase version of that letter. For example, "foo_bar" becomes
        // "fooBar".
        let camelCaseAttributeName = key
        if (key.startsWith("_")) {
            camelCaseAttributeName = key.slice(1)
        }
        const camelCaseKey = camelCaseAttributeName.replace(/_([a-z])/g, (g) =>
            g[1].toUpperCase()
        ) as keyof T

        // Replace the snake_case key with the camelCase key in the new object.
        transformedObj[camelCaseKey] = value
    }

    return transformedObj
}
