import { useState, useCallback, useMemo } from "react"
import { pipeResponse } from "../utils/pipeResponse"

interface ThreadHook {
    rerunThread: (threadId: string) => Promise<void>
    sendMessage: (threadId: string, message: string) => Promise<void>
    isLoading: boolean
}

interface UseThreadOptions {
    resourceType?: string
    resourceId?: string
    onError: (error: Error) => void
    onStreamUpdate: (delta: string) => void
}

export const useThread = ({
    resourceType,
    resourceId,
    onError,
    onStreamUpdate,
}: UseThreadOptions): ThreadHook => {
    const [isLoading, setIsLoading] = useState(false)

    const threadsUrl = useMemo(
        () =>
            resourceType && resourceId
                ? `${process.env.REACT_APP_API_DOMAIN}/${resourceType}/${resourceId}/askglyphic/threads`
                : `${process.env.REACT_APP_API_DOMAIN}/organization/askglyphic/threads`,
        [resourceType, resourceId]
    )

    const stream = useCallback(
        async (url: string) => {
            setIsLoading(true)
            try {
                await pipeResponse(url, (delta) => {
                    onStreamUpdate(delta)
                    return true
                })
            } catch (error) {
                onError(error as Error)
            } finally {
                setIsLoading(false)
            }
        },
        [setIsLoading, onError, onStreamUpdate]
    )

    const sendMessage = useCallback(
        async (threadId: string, message: string) => {
            const encodedMessage = encodeURIComponent(message)
            const url = `${threadsUrl}/${threadId}/messages/stream?message=${encodedMessage}`
            await stream(url)
        },
        [stream, threadsUrl]
    )

    const rerunThread = useCallback(
        async (threadId: string) => {
            const url = `${threadsUrl}/${threadId}/rerun`
            await stream(url)
        },
        [stream, threadsUrl]
    )

    return {
        sendMessage,
        rerunThread,
        isLoading,
    }
}
