import { IntegrationAppClient, Connection } from "@integration-app/sdk"
import {
    CrmTable,
    CrmType,
    ICrmContact,
    ICrmDeal,
    ICrmNote,
    IIntegrationAppCrmUser,
    ICrmCompany,
} from "../types/Crm"

export function findValidCrmType(
    connections: Connection[]
): CrmType | undefined {
    for (const connection of connections) {
        const crmType = getCrmType(connection)
        if (crmType) {
            return crmType
        }
    }

    return undefined
}

export function getCrmType(connection: Connection): CrmType | undefined {
    if (!connection.integration) return

    const crmType = connection.integration?.key as CrmType

    if (!Object.values(CrmType).includes(crmType)) {
        // We ignore connections to integrations that are not supported.
        // This is useful when introducing new integrations and testing them
        // with our integration app account.
        return
    }

    return crmType
}

type CrmTableReturnType<T extends CrmTable> = T extends CrmTable.Deals
    ? ICrmDeal
    : T extends CrmTable.Users
    ? IIntegrationAppCrmUser
    : T extends CrmTable.Contacts
    ? ICrmContact
    : T extends CrmTable.Notes
    ? ICrmNote
    : T extends CrmTable.Companies
    ? ICrmCompany
    : never

export async function search<T extends CrmTable>(
    client: IntegrationAppClient,
    crm: CrmType,
    table: T,
    query: any
): Promise<CrmTableReturnType<T>[]> {
    const response = await client
        .dataSourceInstance({
            integrationKey: crm,
            dataSourceKey: table,
            autoCreate: true,
        })
        .searchRecords({ query })

    return response.records as CrmTableReturnType<T>[]
}
