import { AboutWrapper } from "./AboutWrapper"

export function ZoomDocumentation() {
    // This page is a requirement for Zoom marketplace approval
    // https://developers.zoom.us/docs/distribute/app-submission/submission-checklist/#7-provide-a-documentation-url
    return (
        <AboutWrapper title="Zoom Documentation">
            <div>
                This is a guide on how to user Glyphic's Zoom Cloud integration.
            </div>
            <h2 className="text-lg font-bold">Adding Zoom Cloud integration</h2>
            <ol className="list-decimal pl-8">
                <li>Log in to your Glyphic account</li>
                <li>Naviage to the Settings page</li>
                <li>
                    Under Organization integrations: Zoom Cloud Recordings,
                    click "Connect"
                </li>
                <li>Log into your Zoom account</li>
                <li>Review and accept the required permissions</li>
            </ol>
            <h2 className="text-lg font-bold">Usage</h2>
            <ol className="list-decimal pl-8">
                <li>Enable Zoom Cloud integration as described above</li>
                <li>
                    When holding a Zoom meeting, click "Record to the Cloud"
                    within Zoom
                </li>
                <li>
                    Once your meeting has completed, Glyphic will process your
                    Zoom recording
                </li>
                <li>
                    Results will then be available under the Glyphic calls page
                </li>
            </ol>
            <h2 className="text-lg font-bold">
                Removing Zoom Cloud integration
            </h2>
            <ol className="list-decimal pl-8">
                <li>Log in to your Glyphic account</li>
                <li>Naviage to the Settings page</li>
                <li>
                    Under Organization integrations: Zoom Cloud Recordings,
                    click "Disconnect"
                </li>
                <li>
                    Glyphic will no longer process any meetings recorded via
                    Zoom Cloud
                </li>
            </ol>
        </AboutWrapper>
    )
}
