export function FilterIcon(props: { className?: string }) {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M15 13.4599V18.382C15 18.7607 14.786 19.107 14.4472 19.2764L11.4472 20.7764C10.7823 21.1088 10 20.6253 10 19.882V13.4599C10 13.168 9.87244 12.8907 9.65079 12.7007L3.34921 7.29932C3.12756 7.10934 3 6.83199 3 6.54007V5C3 4.44772 3.44771 4 4 4H21C21.5523 4 22 4.44772 22 5V6.54007C22 6.83199 21.8724 7.10934 21.6508 7.29932L15.3492 12.7007C15.1276 12.8907 15 13.168 15 13.4599Z"
                stroke="currentColor"
                strokeWidth="2"
            />
            <path d="M5 8.5H20" stroke="currentColor" strokeWidth="2" />
        </svg>
    )
}
