import _ from "lodash"
import { useSearchParams } from "react-router-dom"

type SearchParamHook = [string, (newValue: string | undefined | null) => void]

export const useSearchParam = (paramName: string): SearchParamHook => {
    const [searchParams, setSearchParams] = useSearchParams()

    const setSearchParam = (newValue: string | undefined | null) => {
        setSearchParams(
            (prevSearchParams) => {
                const entries = Object.fromEntries(prevSearchParams)
                if (!newValue?.length) {
                    // We always have to set the entire search params object.
                    // If the current value is empty, we remove the key.
                    return _.omit(entries, paramName)
                }
                return { ...entries, [paramName]: newValue }
            },
            { replace: true }
        )
    }

    return [searchParams.get(paramName) ?? "", setSearchParam]
}
