export function LogoLink(props: { url: string; logo: React.ReactNode }) {
    return (
        <a
            className={`hover:cursor-pointer text-gray-400 hover:text-gray-800 transition-colors`}
            href={props.url}
            target="_blank"
            rel="noreferrer"
        >
            {props.logo}
        </a>
    )
}
