import axios from "axios"

export async function generateOAuthAccessToken(
    apiEndpoint: string,
    oauthCode: string,
    callbackUrl: string
): Promise<boolean> {
    const response = await axios.post(
        `${process.env.REACT_APP_API_DOMAIN}/organization/${apiEndpoint}`,
        { oauth_code: oauthCode, redirect_uri: callbackUrl }
    )
    const success = response.data.success
    if (success) {
        console.log(`Successfully generated ${apiEndpoint}`)
    } else {
        console.log(`Failed to generate ${apiEndpoint}`, response)
    }
    return success
}

export async function deleteOAuthAccessToken(
    apiEndpoint: string
): Promise<boolean> {
    const response = await axios.delete(
        `${process.env.REACT_APP_API_DOMAIN}/organization/${apiEndpoint}`
    )
    const success = response.data.success
    if (success) {
        console.log(`Successfully deleted ${apiEndpoint}`)
    } else {
        console.log(`Failed to delete ${apiEndpoint}`, response)
    }
    return success
}

export function navigateToExternal(url: string) {
    window.location.href = url
}
