import { QueryClient } from "@tanstack/react-query"
import axios from "axios"

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: (failureCount: number, error: Error) => {
                // Disable retries for 4xx errors
                if (
                    axios.isAxiosError(error) &&
                    error.response &&
                    error.response?.status >= 400 &&
                    error.response?.status < 500
                ) {
                    return false
                }

                // Retry up to 3 times otherwise (react-query default)
                return failureCount < 3
            },
        },
    },
})
