import { faCircleRight } from "@fortawesome/free-regular-svg-icons"
import { faChevronDown, faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import _ from "lodash"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useNotification } from "../../providers/NotificationProvider"
import { useUser } from "../../providers/UserProvider"
import { PrimaryButton, SecondaryButton } from "../common/Buttons"
import { CopyButton } from "../common/CopyButton"
import LoadingMessage from "../common/LoadingMessage"
import { NotificationType } from "../common/Notifcations"
import {
    IInferredCrmFieldValue,
    IResolvedCrmFieldValue,
} from "../crm/types/Crm"
import { getFormattedSectionNotes } from "../crm/utils/getFormattedNotes"
import { clsx } from "clsx"

interface CustomInsightsProps {
    callId: string
    callTitle: string
    inferredValues: IInferredCrmFieldValue[]
}

type CrmFieldValue = IInferredCrmFieldValue | IResolvedCrmFieldValue

const NO_VALUE = "Not covered"

export function CustomInsights({
    callId,
    callTitle,
    inferredValues,
}: CustomInsightsProps) {
    const user = useUser()
    const hasCrmAccess: boolean = !!user // CRM access only available to logged in users
    const [mappedFields, setMappedFields] = useState<IResolvedCrmFieldValue[]>(
        []
    )
    const [showSyncUI, setShowSyncUI] = useState(false)
    const [disableSyncReason, setDisableSyncReason] = useState<string | null>(
        null
    )

    useEffect(() => {
        async function getMappedFields() {
            try {
                setMappedFields([])
                setDisableSyncReason("Loading...")
                // First update sync all companies with the latest CRM info to ensure we have the latest CRM IDs for them
                await axios.patch(
                    `${process.env.REACT_APP_API_DOMAIN}/calls/${callId}/companies/refresh_crm_info`
                )
                const { data } = await axios.get(
                    `${process.env.REACT_APP_API_DOMAIN}/calls/${callId}/crm/fields/`
                )
                const fields = data as IResolvedCrmFieldValue[]
                setMappedFields(fields)
                const fieldsWithCrmObject = fields.filter(
                    (field) => field.source_id
                )

                if (data.length === 0) {
                    setDisableSyncReason(
                        "Custom insights have not been connected to a CRM field. Contact Glyphic to enable this functionality at support@glyphic.ai"
                    )
                } else if (fieldsWithCrmObject.length === 0) {
                    setDisableSyncReason(
                        "This call could not be matched to any CRM object."
                    )
                } else {
                    setDisableSyncReason(null) // Enable sync button
                }
            } catch (error) {
                setDisableSyncReason(getDisableSyncReason(error))
                console.error("Failed to get mapped fields", error)
            }
        }
        if (hasCrmAccess) {
            getMappedFields()
        }
    }, [callId, hasCrmAccess])

    const fields: CrmFieldValue[] = useMemo(() => {
        if (inferredValues.length === 0) return inferredValues

        const fieldsToDisplay = showSyncUI ? mappedFields : inferredValues
        return fieldsToDisplay.sort(
            (a, b) =>
                getDisplayName(a).localeCompare(getDisplayName(b), undefined, {
                    numeric: true,
                }) || 0
        )
    }, [showSyncUI, mappedFields, inferredValues])

    if (fields.length === 0) {
        return <div className="text-gray-600 text-sm">No custom insights.</div>
    }

    const ViewSyncButton = () =>
        showSyncUI ? (
            <PrimaryButton onClick={() => setShowSyncUI(false)}>
                Close
            </PrimaryButton>
        ) : (
            <SecondaryButton
                onClick={() => setShowSyncUI(true)}
                disabled={disableSyncReason !== null}
                data-tooltip-id="tooltip-explanation"
                data-tooltip-content={disableSyncReason}
            >
                Sync to CRM ...
            </SecondaryButton>
        )

    return (
        <div className="bg-white border rounded-lg p-3 text-base space-y-4">
            <div className="flex justify-between w-full">
                <h2 className="text-xl font-bold mr-3">Custom Insights</h2>
                <div className="flex justify-end space-x-3">
                    {!showSyncUI && (
                        <CopyButton
                            content={getFormattedNotes(callTitle, fields)}
                        />
                    )}
                    {hasCrmAccess && <ViewSyncButton />}
                </div>
            </div>
            <FieldList
                fields={fields}
                showSyncUI={showSyncUI}
                callId={callId}
            />
        </div>
    )
}

function FieldList({
    fields,
    showSyncUI,
    callId,
}: {
    fields: CrmFieldValue[]
    showSyncUI: boolean
    callId: string
}) {
    const sortedGroupedFields = useMemo(() => {
        const grouped = _.groupBy(fields, (field) => field.group_name ?? "")
        return Object.entries(grouped).sort(([a], [b]) => {
            if (a === "") return -1
            if (b === "") return 1
            return a.localeCompare(b)
        })
    }, [fields])

    if (sortedGroupedFields.length > 1) {
        return (
            <GroupListView
                groupedFields={sortedGroupedFields}
                showSyncUI={showSyncUI}
                callId={callId}
            />
        )
    } else {
        return (
            <FlatListView
                fields={fields}
                showSyncUI={showSyncUI}
                callId={callId}
            />
        )
    }
}

function GroupListView({
    groupedFields,
    showSyncUI,
    callId,
}: {
    groupedFields: [string, CrmFieldValue[]][]
    showSyncUI: boolean
    callId: string
}) {
    return (
        <div className="space-y-4">
            {groupedFields.map(([groupName, fields]) => (
                <GroupView
                    key={groupName}
                    groupName={groupName}
                    fields={fields}
                    showSyncUI={showSyncUI}
                    callId={callId}
                />
            ))}
        </div>
    )
}

function GroupView({
    groupName,
    fields,
    showSyncUI,
    callId,
}: {
    groupName: string
    fields: CrmFieldValue[]
    showSyncUI: boolean
    callId: string
}) {
    const [collapsed, setCollapsed] = useState(false)

    const groupNameValue = groupName || "General"
    const totalFieldCount = fields.length
    const nonEmptyFieldCount = fields.filter(hasValue).length
    const tooltipContent =
        `${nonEmptyFieldCount} out of the ${totalFieldCount} ${groupNameValue} insights ` +
        (showSyncUI ? "can be synced." : "were mentioned.")

    return (
        <div className="space-y-2 divide-y">
            <div
                className="flex items-center cursor-pointer justify-between"
                onClick={() => setCollapsed(!collapsed)}
            >
                <span className="flex items-center">
                    <FontAwesomeIcon
                        icon={faChevronDown}
                        className={clsx(
                            "mr-2 transition-transform",
                            collapsed && "-rotate-90"
                        )}
                    />
                    <h3 className="text-lg font-semibold">{groupNameValue}</h3>
                </span>
                <span
                    className="text-gray-500 bg-gray-100 px-2 rounded-lg inline-flex justify-center"
                    data-tooltip-id="tooltip-explanation"
                    data-tooltip-html={tooltipContent}
                >
                    {nonEmptyFieldCount} / {totalFieldCount}
                </span>
            </div>
            <div
                className={clsx(
                    "pt-2 pl-2 transition-max-height duration-500 overflow-auto",
                    collapsed ? "max-h-0" : "max-h-[200vh]"
                )}
            >
                <FlatListView
                    fields={fields}
                    showSyncUI={showSyncUI}
                    callId={callId}
                />
            </div>
        </div>
    )
}

function FlatListView({
    fields,
    showSyncUI,
    callId,
}: {
    fields: CrmFieldValue[]
    showSyncUI: boolean
    callId: string
}) {
    return (
        <div className="space-y-2">
            {fields.map((field) => (
                <div
                    key={field.field_name}
                    className="flex flex-row items-center space-x-3"
                >
                    {showSyncUI ? (
                        <FieldSyncPreview
                            field={field as IResolvedCrmFieldValue}
                            callId={callId}
                        />
                    ) : (
                        <Field field={field} />
                    )}
                </div>
            ))}
        </div>
    )
}

function getDisableSyncReason(error: any) {
    if (axios.isAxiosError(error)) {
        if (error.response?.status === 412) {
            return "No CRM linked"
        } else if (error.response?.status === 424) {
            // We do not expect to be displaying the component in this case
            return "No custom insights found"
        }
    }
    return "Failed to get CRM mapped fields"
}

export function Field({ field }: { field: IInferredCrmFieldValue }) {
    const bgColor = hasValue(field) ? "bg-indigo-500" : "bg-gray-300"
    return (
        <div className="flex flex-row grow gap-3">
            <div className={`${bgColor} flex-none w-1 rounded-lg`}></div>
            <div className="flex-grow">
                <p className="font-semibold">
                    {field.field_display_name || field.field_name}
                </p>
                <FieldValue field={field} />
            </div>
        </div>
    )
}

function hasValue(field: IInferredCrmFieldValue): boolean {
    // Whether inference has found some value for this field
    return !!field.value || !!field.value_additional_context
}

function FieldSyncPreview({
    callId,
    field,
}: {
    callId: string
    field: IResolvedCrmFieldValue
}) {
    const bgColor = field.value ? "bg-indigo-500" : "bg-gray-300"
    return (
        <div className="flex flex-row grow gap-3">
            <div className={`${bgColor} flex-none w-1 rounded-lg`}></div>
            <div className="flex flex-row grow gap-3 items-start">
                <div className="flex-grow space-y-1">
                    <div>
                        <p className="font-semibold">{getDisplayName(field)}</p>
                        <FieldValue field={field} />
                    </div>
                    <div className="bg-gray-100 text-gray-600 rounded py-2 px-3 w-full">
                        {field.existing_crm_value ? (
                            <p>
                                Currently in CRM:{" "}
                                <b className="font-semibold">
                                    {field.existing_crm_value}
                                </b>
                            </p>
                        ) : (
                            <p>CRM field is empty</p>
                        )}
                    </div>
                </div>
                <FieldSyncControls callId={callId} field={field} />
            </div>
        </div>
    )
}

function FieldValue({ field }: { field: IInferredCrmFieldValue }) {
    const displayValue = getDisplayValue(field)
    // if there is additional context but no value,
    // it means that the CI was covered in the call but we failed to map it to a valid CRM value
    const emptyValue = field.value_additional_context
        ? "No corresponding CRM value"
        : NO_VALUE

    return (
        <div className="flex flex-row gap-2 items-center">
            <p className={!displayValue ? "opacity-50" : ""}>
                {displayValue || emptyValue}
            </p>
            {field.value_additional_context && (
                <FontAwesomeIcon
                    className="text-gray-400 mt-0.5"
                    icon={faInfoCircle}
                    data-tooltip-content={field.value_additional_context}
                    data-tooltip-id="tooltip-explanation"
                />
            )}
        </div>
    )
}

function FieldSyncControls({
    callId,
    field,
}: {
    callId: string
    field: IResolvedCrmFieldValue
}) {
    const { addNotification } = useNotification()
    const [loading, setLoading] = useState(false)

    const onSync = useCallback(async () => {
        setLoading(true)
        try {
            await axios.post(
                `${process.env.REACT_APP_API_DOMAIN}/calls/${callId}/crm/fields`,
                {
                    fields: [field],
                }
            )
            addNotification(
                "Sent to CRM!",
                "You can find it in the company properties.",
                NotificationType.Success
            )
        } catch (error) {
            console.error("Failed to sync CRM", error)
            addNotification(
                "Failed to sync CRM",
                `${error}`,
                NotificationType.Error
            )
        } finally {
            setLoading(false)
        }
    }, [field, callId, addNotification])

    const canSync = !loading && field.value && field.source_id

    return (
        <div className="flex flex-row space-x-2 items-center">
            <SecondaryButton onClick={onSync} disabled={!canSync}>
                <span className="space-x-1 whitespace-nowrap items-center flex w-16 justify-center">
                    {loading ? (
                        <LoadingMessage message="Syncing" />
                    ) : (
                        <>
                            <FontAwesomeIcon icon={faCircleRight} size="lg" />
                            <span>{"Sync"}</span>
                        </>
                    )}
                </span>
            </SecondaryButton>
        </div>
    )
}

function getFormattedNotes(
    callTitle: string,
    inferredValues: IInferredCrmFieldValue[]
) {
    const fieldsString = [
        "Custom Insights:",
        ...inferredValues.map((field) => {
            const displayValue = getDisplayValue(field) || NO_VALUE
            return `- ${
                field.field_display_name || field.field_name
            }: ${displayValue}`
        }),
    ].join("\n")
    return getFormattedSectionNotes(callTitle, fieldsString)
}

function getDisplayName(field: IInferredCrmFieldValue): string {
    return field.field_display_name || field.field_name || ""
}

function getDisplayValue(inferredValue: IInferredCrmFieldValue): string | null {
    return inferredValue.display_value || inferredValue.value
}
