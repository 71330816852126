import { useEffect, useState } from "react"
import {
    ICallPrepMinsBeforeType,
    ICallProcessedNotificationType,
    INotificationPreferences,
    IUser,
} from "../../types/User"
import axios from "axios"
import { transformSnakeKeysToCamelCase } from "../../utils/transformSnakeKeysToCamelCase"
import { Card } from "./Card"
import { useUser } from "../../providers/UserProvider"
import { NotificationType } from "../common/Notifcations"
import { useNotification } from "../../providers/NotificationProvider"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import Select, { SingleValue } from "react-select"

export function NotificationPreferences(props: { slackEnabled: boolean }) {
    const { addNotification } = useNotification()
    const user = useUser()
    const [notificationPreferences, setNotificationPreferences] = useState<
        INotificationPreferences | undefined
    >()

    // Fetch preferences from a fresh user object, settings inside useUser context could be stale
    useEffect(() => {
        async function getUser() {
            const response = await axios.get<IUser>(
                `${process.env.REACT_APP_API_DOMAIN}/user`
            )
            const user = transformSnakeKeysToCamelCase(response.data)
            setNotificationPreferences(user.notificationPreferences)
        }
        getUser()
    }, [])

    if (user === undefined || notificationPreferences === undefined) {
        return <></>
    }

    async function updatePreferences(prefs: INotificationPreferences) {
        try {
            setNotificationPreferences(prefs)
            await saveNotificiatonPreferences(prefs)
            addNotification(
                "Notification preferences updated",
                "",
                NotificationType.Success
            )
        } catch (error) {
            addNotification(
                "Failed to update notification preferences",
                `${error}`,
                NotificationType.Error
            )
        }
    }

    return (
        <Card
            title="Notification Preferences"
            label={
                <>
                    Receive emails at{" "}
                    <span className="font-bold">{user.email}</span>. Customize
                    your preferences below.
                </>
            }
        >
            <CallProcessedPreferences
                value={notificationPreferences.call_processed_emails}
                savePrefs={(
                    CallProcessedPreferences: ICallProcessedNotificationType
                ) => {
                    updatePreferences({
                        ...notificationPreferences,
                        call_processed_emails: CallProcessedPreferences,
                    })
                }}
            />
            <CallPrepPreferences
                slackEnabled={props.slackEnabled}
                email_mins_before={
                    notificationPreferences.call_prep_emails_mins_before
                }
                slack_mins_before={
                    notificationPreferences.call_prep_slack_mins_before
                }
                savePrefs={(
                    email_mins_before: ICallPrepMinsBeforeType,
                    slack_mins_before: ICallPrepMinsBeforeType
                ) => {
                    updatePreferences({
                        ...notificationPreferences,
                        call_prep_emails_mins_before: email_mins_before,
                        call_prep_slack_mins_before: slack_mins_before,
                    })
                }}
            />
        </Card>
    )
}

function CallProcessedPreferences(props: {
    value: ICallProcessedNotificationType
    savePrefs: (prefs: ICallProcessedNotificationType) => void
}) {
    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newOption = event.target.value as ICallProcessedNotificationType
        props.savePrefs(newOption)
    }

    return (
        <div className="text-base flex flex-col space-y-2">
            <div>
                Receive summary emails when calls have been processed by
                Glyphic:
            </div>
            <NotificationRadioOption
                value={ICallProcessedNotificationType.Participant}
                checked={
                    props.value === ICallProcessedNotificationType.Participant
                }
                onChange={handleRadioChange}
                label="Notify me for any call where I am a participant."
                group="call-processed-email"
            />
            <NotificationRadioOption
                value={ICallProcessedNotificationType.Host}
                checked={props.value === ICallProcessedNotificationType.Host}
                onChange={handleRadioChange}
                label="Notify me only for calls where I am the host."
                group="call-processed-email"
            />
            <NotificationRadioOption
                value={ICallProcessedNotificationType.None}
                checked={props.value === ICallProcessedNotificationType.None}
                onChange={handleRadioChange}
                label="Do not receive email notifications."
                group="call-processed-email"
            />
        </div>
    )
}

function CallPrepPreferences(props: {
    slackEnabled: boolean
    email_mins_before: ICallPrepMinsBeforeType
    slack_mins_before: ICallPrepMinsBeforeType
    savePrefs: (
        email_mins_before: ICallPrepMinsBeforeType,
        slack_mins_before: ICallPrepMinsBeforeType
    ) => void
}) {
    const user = useUser()

    const minsBeforeOptions = [
        { value: 15, label: "15 minutes before a call" },
        { value: 30, label: "30 minutes before a call" },
        { value: 60, label: "1 hour before a call" },
        { value: 180, label: "3 hours before a call" },
        { value: 360, label: "6 hours before a call" },
        { value: 480, label: "8 hours before a call" },
    ]

    const handleMediumsChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        let email_pref = props.email_mins_before
        let slack_pref = props.slack_mins_before
        const default_val = 15

        if (event.target.checked) {
            if (event.target.value === "email") {
                email_pref = slack_pref || default_val
            } else {
                slack_pref = email_pref || default_val
            }
        } else {
            if (event.target.value === "email") {
                email_pref = null
            } else {
                slack_pref = null
            }
        }
        props.savePrefs(email_pref, slack_pref)
    }

    const handleMinsBeforeChange = (
        newOption: SingleValue<{
            value: number
            label: string
        }>
    ) => {
        if (newOption) {
            const emailMinsBefore =
                props.email_mins_before === null ? null : newOption.value
            const slackMinsBefore =
                props.slack_mins_before === null ? null : newOption.value

            props.savePrefs(emailMinsBefore, slackMinsBefore)
        }
    }
    const notificationsTurnedOff =
        !props.email_mins_before && !props.slack_mins_before
    return (
        <div className="text-base space-y-2">
            <div>Receive prep sheets before your calls via: </div>
            <div className="flex flex-row gap-4">
                <NotificationCheckboxOption
                    value={"email"}
                    checked={Boolean(props.email_mins_before)}
                    onChange={handleMediumsChange}
                    label="Email"
                />
                <div className="flex flex-row gap-2 items-center">
                    <NotificationCheckboxOption
                        value={"slack"}
                        checked={Boolean(props.slack_mins_before)}
                        onChange={handleMediumsChange}
                        label="Slack DM"
                        disabled={!props.slackEnabled}
                    />
                    <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="text-gray-600"
                        data-tooltip-id="tooltip-explanation"
                        data-tooltip-content={
                            props.slackEnabled
                                ? `Glyphic will search for a Slack user associated with your email (${user?.email}) and send you a DM`
                                : "Please connect Glyphic to your Slack workspace to enable this option"
                        }
                    />
                </div>
            </div>
            <Select
                className="basic-single w-64"
                classNamePrefix="select"
                value={
                    minsBeforeOptions.find(
                        (option) =>
                            option.value === props.email_mins_before ||
                            option.value === props.slack_mins_before
                    ) || minsBeforeOptions[0]
                }
                isDisabled={notificationsTurnedOff}
                onChange={handleMinsBeforeChange}
                options={minsBeforeOptions}
            />
        </div>
    )
}

function NotificationCheckboxOption(props: {
    value: string
    checked: boolean
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    label: string
    disabled?: boolean
}) {
    return (
        <label
            className={`inline-flex items-center ${
                props.disabled && "opacity-50"
            }`}
        >
            <input
                type="checkbox"
                name="notification-medium"
                value={props.value}
                checked={props.checked}
                onChange={props.onChange}
                disabled={props.disabled}
            />
            <span className="ml-2">{props.label}</span>
        </label>
    )
}

function NotificationRadioOption(props: {
    value: string
    checked: boolean
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    label: string
    group: string
}) {
    return (
        <label className="inline-flex items-center">
            <input
                type="radio"
                name={props.group}
                value={props.value}
                checked={props.checked}
                onChange={props.onChange}
            />
            <span className="ml-2">{props.label}</span>
        </label>
    )
}

async function saveNotificiatonPreferences(
    preferences: INotificationPreferences
): Promise<void> {
    await axios.put(
        `${process.env.REACT_APP_API_DOMAIN}/user/notification_preferences`,
        preferences
    )
}
