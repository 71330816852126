import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons"
import { PrimaryButton } from "../common/Buttons"

interface SendButtonProps {
    onClick: () => void
    disabled?: boolean
}

export default function SendButton({ onClick, disabled }: SendButtonProps) {
    let classNames = disabled
        ? "opacity-30 cursor-not-allowed"
        : "cursor-pointer"

    return (
        <PrimaryButton
            onClick={onClick}
            className={classNames}
            disabled={disabled}
        >
            <strong>Sync now</strong>&nbsp;&nbsp;
            <FontAwesomeIcon icon={faPaperPlane} />
        </PrimaryButton>
    )
}
