import { AboutWrapper } from "./AboutWrapper"

export function TermsOfService() {
    // This page is a requirement for Zoom marketplace approval
    // https://developers.zoom.us/docs/distribute/app-submission/submission-checklist/#5-provide-a-terms-of-use-url
    return (
        <AboutWrapper title="Terms of Service">
            <TermsOfServiceContent />
        </AboutWrapper>
    )
}

function TermsOfServiceContent() {
    // Source: https://docs.google.com/document/d/1zjdSvTo2ksELagatH01yY44_tK4WJ8isQu9oe9QdyHQ
    return (
        <div className="space-y-4">
            <div>
                These Terms of Service govern your use of the website located at
                https://glyphic.ai and any related services provided by Glyphic
                AI Limited.
            </div>
            <div>
                By accessing https://Glyphic.ai, you agree to abide by these
                Terms of Service and to comply with all applicable laws and
                regulations. If you do not agree with these Terms of Service,
                you are prohibited from using or accessing this website or using
                any other services provided by Glyphic AI Limited.
            </div>
            <div>
                We, Glyphic AI Limited, reserve the right to review and amend
                any of these Terms of Service at our sole discretion. Upon doing
                so, we will update this page. Any changes to these Terms of
                Service will take effect immediately from the date of
                publication.
            </div>
            <div>These Terms of Service were last updated on 1 May 2023.</div>
            <h2 className="text-lg font-bold">Limitations of Use</h2>
            <div>
                By using this website, you warrant on behalf of yourself, your
                users, and other parties you represent that you will not:
            </div>
            <ol className="list-decimal pl-8">
                <li>
                    modify, copy, prepare derivative works of, decompile, or
                    reverse engineer any materials and software contained on
                    this website;
                </li>
                <li>
                    remove any copyright or other proprietary notations from any
                    materials and software on this website;
                </li>
                <li>
                    transfer the materials to another person or “mirror” the
                    materials on any other server;
                </li>
                <li>
                    knowingly or negligently use this website or any of its
                    associated services in a way that abuses or disrupts our
                    networks or any other service Glyphic AI Limited provides;
                </li>
                <li>
                    use this website or its associated services to transmit or
                    publish any harassing, indecent, obscene, fraudulent, or
                    unlawful material;
                </li>
                <li>
                    use this website or its associated services in violation of
                    any applicable laws or regulations;
                </li>
                <li>
                    use this website in conjunction with sending unauthorised
                    advertising or spam;
                </li>
                <li>
                    harvest, collect or gather user data without the user’s
                    consent; or
                </li>
                <li>
                    use this website or its associated services in such a way
                    that may infringe the privacy, intellectual property rights,
                    or other rights of third parties.
                </li>
            </ol>
            <h2 className="text-lg font-bold">Data Security and Privacy</h2>
            <div>
                Glyphic AI has implemented and will maintain technical,
                organizational, and administrative systems, policies, and
                procedures designed to ensure the security, integrity, and
                confidentiality of Customer Data and to mitigate the risk of
                unauthorized access to or use of Customer Data. Glyphic AI will
                notify you, according to the timeframes prescribed under
                applicable law, if it becomes aware of unauthorized access to
                Customer Data. Glyphic AI will not access, disclose, view or
                process Customer Data except (a) as provided for in this
                Agreement or in Glyphic AI’s{" "}
                <a
                    href="https://www.glyphic.ai/privacy-policy"
                    className="underline"
                >
                    Privacy Policy
                </a>{" "}
                (“Privacy Policy”), (b) as authorized or instructed by you in
                writing, (c) as required to perform its obligations under this
                Agreement, or (d) as required by applicable law. Glyphic AI HAS
                NO OTHER OBLIGATIONS OR LIABILITY WITH RESPECT TO ANY LOSS,
                ALTERATION, DESTRUCTION, DAMAGE, CORRUPTION, OR RECOVERY OF
                CUSTOMER DATA.
            </div>
            <div>
                While using the Services, you or your Permitted Users may
                transfer to Glyphic AI certain Customer Data containing personal
                information that, individually or in combination, does or can
                identify a specific individual or by or from which a specific
                individual may be identified, contacted, or located (“Personal
                Information”).
            </div>
            <h2 className="text-lg font-bold">Intellectual Property</h2>
            <div>
                The intellectual property in the materials contained in this
                website are owned by or licensed to Glyphic AI Limited and are
                protected by applicable copyright and trademark law. We grant
                our users permission to download one copy of the materials for
                personal, non-commercial transitory use.
            </div>
            <div>
                This constitutes the grant of a license, not a transfer of
                title. This license shall automatically terminate if you violate
                any of these restrictions or the Terms of Service, and may be
                terminated by Glyphic AI Limited at any time.
            </div>
            <h2 className="text-lg font-bold">User-Generated Content</h2>
            <div>
                You retain your intellectual property ownership rights over
                content you submit to us for publication on our website. We will
                never claim ownership of your content but we do require a
                license from you in order to use it.
            </div>
            <div>
                When you use our website or its associated services to post,
                upload, share or otherwise transmit content covered by
                intellectual property rights, you grant to us a non-exclusive,
                royalty-free, transferable, sub-licensable, worldwide license to
                use, distribute, modify, run, copy, publicly display, translate
                or otherwise create derivative works of your content in a manner
                that is consistent with your privacy preferences and our Privacy
                Policy.
            </div>
            <div>
                The license you grant us can be terminated at any time by
                deleting your content or account. However, to the extent that we
                (or our partners) have used your content in connection with
                commercial or sponsored content, the license will continue until
                the relevant commercial or post has been discontinued by us.
            </div>
            <div>
                You give us permission to use your username and other
                identifying information associated with your account in a manner
                that is consistent with your privacy preferences and our Privacy
                Policy.
            </div>
            <h2 className="text-lg font-bold">Liability</h2>
            <div>
                Our website and the materials on our website are provided on an
                'as is' basis. To the extent permitted by law, Glyphic AI
                Limited makes no warranties, expressed or implied, and hereby
                disclaims and negates all other warranties including, without
                limitation, implied warranties or conditions of merchantability,
                fitness for a particular purpose, or non-infringement of
                intellectual property, or other violation of rights.
            </div>
            <div>
                In no event shall Glyphic AI Limited or its suppliers be liable
                for any consequential loss suffered or incurred by you or any
                third party arising from the use or inability to use this
                website or the materials on this website, even if Glyphic AI
                Limited or an authorised representative has been notified,
                orally or in writing, of the possibility of such damage.
            </div>
            <div>
                In the context of this agreement, “consequential loss” includes
                any consequential loss, indirect loss, real or anticipated loss
                of profit, loss of benefit, loss of revenue, loss of business,
                loss of goodwill, loss of opportunity, loss of savings, loss of
                reputation, loss of use and/or loss or corruption of data,
                whether under statute, contract, equity, tort (including
                negligence), indemnity or otherwise.
            </div>
            <div>
                Because some jurisdictions do not allow limitations on implied
                warranties, or limitations of liability for consequential or
                incidental damages, these limitations may not apply to you.
            </div>
            <h2 className="text-lg font-bold">Accuracy of Materials</h2>
            <div>
                The materials appearing on our website are not comprehensive and
                are for general information purposes only. Glyphic AI Limited
                does not warrant or make any representations concerning the
                accuracy, likely results, or reliability of the use of the
                materials on this website, or otherwise relating to such
                materials or on any resources linked to this website.
            </div>
            <h2 className="text-lg font-bold">Links</h2>
            <div>
                Glyphic AI Limited has not reviewed all of the sites linked to
                its website and is not responsible for the contents of any such
                linked site. The inclusion of any link does not imply
                endorsement, approval or control by Glyphic AI Limited of the
                site. Use of any such linked site is at your own risk and we
                strongly advise you make your own investigations with respect to
                the suitability of those sites.
            </div>
            <h2 className="text-lg font-bold">Right to Terminate</h2>
            <div>
                We may suspend or terminate your right to use our website and
                terminate these Terms of Service immediately upon written notice
                to you for any breach of these Terms of Service.
            </div>
            <h2 className="text-lg font-bold">Severance</h2>
            <div>
                Any term of these Terms of Service which is wholly or partially
                void or unenforceable is severed to the extent that it is void
                or unenforceable. The validity of the remainder of these Terms
                of Service is not affected.
            </div>
            <h2 className="text-lg font-bold">Governing Law</h2>
            <div>
                These Terms of Service are governed by and construed in
                accordance with the laws of the United Kingdom. You irrevocably
                submit to the exclusive jurisdiction of the courts in that State
                or location.
            </div>
        </div>
    )
}
