/**
 * Feature flags are used to enable/disable features in the app.
 */
export enum FeatureFlags {
    ShowResources = "REACT_APP_SHOW_RESOURCES",
    ShowTasks = "REACT_APP_SHOW_TASKS",
}

/**
 * Super orgs are organizations that can use all features.
 * At the moment this is only used for Glyphic AI and is a simple hardcoded
 * implementation.
 */
const SuperOrgs = [
    "63fcbc0a1383a7a07c293500", // Glyphic AI (Dev)
    "64dbb2227b329da6fa8d8fb0", // Glyphic AI + Salesforce (Dev)
    "6408a476d0fa82310ea317d9", // Glyphic AI (Prod)
    "6451056610fb2cb5774ea18b", // MyCorp Inc. (demo) (Prod)
]

/**
 * A map of organization IDs to a list of features that can be used by that
 * organization.
 * Sorted alphabetically by organization name.
 */
const FeaturesPerOrg: { [key: string]: FeatureFlags[] } = {
    // SBR (Prod)
    "640b05013e9fad21c8fb63fa": [FeatureFlags.ShowResources],
    // Vouris Demo (Prod)
    "64d3c29c78d190cad8368066": [FeatureFlags.ShowResources],
    // Glyphic AI - Demo (Staging)
    "6564afe447c9eb0d740636ae": [FeatureFlags.ShowTasks],
}

/**
 * Check if a feature can be used by the current organization.
 *
 * @param feature Feature to check if it can be used by the current
 *   organization.
 * @param organizationId Organization ID to check if it is a super org,
 *   usually means the organization of the current user, but no assumptions
 *   made.
 * @returns True if the feature can be used, false otherwise.
 *
 * @example
 * // Example usage:
 * import { FeatureFlags, canUseFeature } from "../../utils/FeatureFlags"
 * import { useUser } from "../../providers/UserProvider"
 *
 * // ...
 *
 * const user = useUser()
 * const canShowCrm = canUseFeature(
 *      FeatureFlags.ShowAwesomeFeature,
 *      user?.organizationId
 * )
 */
export function canUseFeature(
    feature: FeatureFlags,
    organizationId?: string
): boolean {
    if (isSuperOrg(organizationId)) {
        // Super orgs can use all features
        return true
    }

    if (organizationId && FeaturesPerOrg?.[organizationId]?.includes(feature)) {
        return true
    }

    // Otherwise check if the feature is enabled for the current environment
    return process.env[feature.toString()] === "true"
}

export function isSuperOrg(organizationId?: string) {
    return organizationId && SuperOrgs.includes(organizationId)
}
