import { KeyboardEventHandler, useEffect, useState } from "react"
import axios from "axios"
import { OrgSettingsRequest, OrgSettingsResponse } from "../../types/Settings"
import { getOrgSettings } from "../../api/Settings"
import {
    AddNotificationType,
    useNotification,
} from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"
import _ from "lodash"
import validator from "validator"
import CreatableSelect from "react-select/creatable"
import { SecondaryButton } from "../common/Buttons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import React from "react"
import { useQuery } from "@tanstack/react-query"
import { MultiValue } from "react-select"

export function InternalDomains() {
    const { data, isSuccess } = useQuery<OrgSettingsResponse>({
        queryKey: ["organization/settings"],
        queryFn: getOrgSettings,
    })
    const { addNotification } = useNotification()
    const [domains, setDomains] = useState<string[]>([])

    useEffect(() => {
        if (isSuccess) {
            setDomains(data.internal_domains || [])
        }
    }, [data, addNotification, isSuccess])

    return (
        <div className="space-y-2">
            <h3 className="text-md font-semibold">Internal Domains</h3>
            <p className="text-base text-gray-600">
                Calls with participants only from these domains will be treated
                as internal.{" "}
                <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="text-gray-600"
                    data-tooltip-id="tooltip-explanation"
                    data-tooltip-html={
                        "If <b>all participants</b> of a meeting are from these domains, the meeting will be treated as internal to your organization.<br/><br/>" +
                        "Internal meetings will only be recorded if users' recording preferences have enabled internal recording. They will not genererate call prep, or sync to CRM.<br/><br/>" +
                        "Internal domains will not be listed as companies in the company page.<br/><br/>" +
                        "This setting applies to all users in your Glyphic workspace."
                    }
                />
            </p>
            <div className="flex space-x-2">
                <DomainSelector domains={domains} setDomains={setDomains} />
                <SecondaryButton
                    className="w-24"
                    type="button"
                    onClick={(e) => {
                        saveDomains(
                            { internal_domains: domains },
                            addNotification
                        )
                    }}
                >
                    Save
                </SecondaryButton>
            </div>
        </div>
    )
}

export function RecordingDisallowedDomains() {
    const { data, isSuccess } = useQuery<OrgSettingsResponse>({
        queryKey: ["organization/settings"],
        queryFn: getOrgSettings,
    })
    const { addNotification } = useNotification()
    const [domains, setDomains] = useState<string[]>([])

    useEffect(() => {
        if (isSuccess) {
            setDomains(data.recording_disallowed_domains || [])
        }
    }, [data, addNotification, isSuccess])

    return (
        <div className="space-y-2">
            <h3 className="text-md font-semibold">Excluded Domains</h3>
            <p className="text-base text-gray-600">
                Calls with any participants from these domains will not be
                automatically recorded.{" "}
                <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="text-gray-600"
                    data-tooltip-id="tooltip-explanation"
                    data-tooltip-html={
                        "If <b>any participant</b> of a meeting is from one these domains, Glyphic will <b>not</b> automatically join the meeting, generate call prep, or sync to CRM.<br/><br/>" +
                        "This setting applies to all users in your Glyphic workspace.<br/><br/>" +
                        "Note this behaviour can be manually overridden by users in the schedule page, to enable recording of individual meetings."
                    }
                />
            </p>
            <div className="flex space-x-2">
                <DomainSelector domains={domains} setDomains={setDomains} />
                <SecondaryButton
                    className="w-24"
                    type="button"
                    onClick={(e) => {
                        saveDomains(
                            { recording_disallowed_domains: domains },
                            addNotification
                        )
                    }}
                >
                    Save
                </SecondaryButton>
            </div>
        </div>
    )
}

function DomainSelector(props: {
    domains: string[]
    setDomains: React.Dispatch<React.SetStateAction<string[]>>
}) {
    const createSelectValue = (label: string) => ({
        label: label,
        value: label,
    })

    const { addNotification } = useNotification()
    const [inputValue, setInputValue] = useState("")
    const setDomains = props.setDomains

    const handleKeyDown: KeyboardEventHandler = (event) => {
        switch (event.key) {
            case "Enter":
            case "Tab":
            case ",":
            case " ":
                enterValue()
                event.preventDefault()
        }
    }

    function enterValue() {
        if (!inputValue) return
        if (validator.isFQDN(inputValue)) {
            setDomains((prev) => {
                if (prev === null) {
                    return [inputValue]
                } else {
                    return _.uniq([...prev, inputValue])
                }
            })
            setInputValue("")
        } else {
            addNotification(
                `Invalid domain "${inputValue}"`,
                "Please enter a valid email domain",
                NotificationType.Error
            )
        }
    }

    const handleChange = (
        newValue: MultiValue<{ label: string; value: string }>
    ) => {
        if (newValue) {
            setDomains(newValue.map((v) => v.value))
        } else {
            setDomains([])
        }
    }

    return (
        <div className="flex-grow text-base space-y-2">
            <CreatableSelect
                components={{
                    DropdownIndicator: null,
                }}
                inputValue={inputValue}
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={handleChange}
                onInputChange={(newValue) => setInputValue(newValue)}
                onKeyDown={handleKeyDown}
                onBlur={enterValue}
                placeholder="Enter space-separated email domains e.g. example.com"
                value={props.domains.map(createSelectValue)}
                // Override default styling of CreatableSelect
                styles={{
                    multiValue: (styles, { data }) => {
                        return {
                            ...styles,
                            borderRadius: "4px",
                            backgroundColor: "#F3F4F6",
                        }
                    },
                    control: (styles, state) => ({
                        ...styles,
                        borderRadius: "8px",
                    }),
                }}
            />
        </div>
    )
}

async function saveDomains(
    settings: OrgSettingsRequest,
    addNotification: AddNotificationType
) {
    try {
        await axios.put(
            `${process.env.REACT_APP_API_DOMAIN}/organization/settings`,
            settings
        )
        addNotification(
            "Successfully updated recording disallowed domains",
            "",
            NotificationType.Success
        )
    } catch (error) {
        addNotification(
            "Failed to update recording disallowed domains",
            `${error}`,
            NotificationType.Error
        )
    }
}
