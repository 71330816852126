import { BotNameSetting } from "./BotNameSettings"
import { Card } from "./Card"
import { InternalDomains, RecordingDisallowedDomains } from "./DomainSettings"
import { ToggleSetting } from "./ToggleSetting"
import { useQuery, useMutation } from "@tanstack/react-query"
import { OrgSettingsResponse } from "../../types/Settings"
import { getOrgSettings } from "../../api/Settings"
import axios from "axios"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"

export function OrgRecordingPreferences(props: { orgName?: string }) {
    const organizationName = props.orgName || "Organization"

    return (
        <Card
            title={`${organizationName}'s recording preferences`}
            label="Configure how Glyphic records your organization's calls."
        >
            <InternalRecordingPreference />
            <BotNameSetting orgName={props.orgName} />
            <InternalDomains />
            <RecordingDisallowedDomains />
        </Card>
    )
}

function InternalRecordingPreference() {
    const { addNotification } = useNotification()
    const { data } = useQuery<OrgSettingsResponse>({
        queryKey: ["organization/settings"],
        queryFn: getOrgSettings,
    })
    const { mutate, isPending } = useMutation({
        mutationFn: async (checked: boolean) =>
            await axios.put(
                `${process.env.REACT_APP_API_DOMAIN}/organization/settings`,
                { allow_internal_recording: checked }
            ),
        onSuccess: () => {
            addNotification(
                "Successfully updated recording preferences.",
                "",
                NotificationType.Success
            )
        },
        onError: () => {
            addNotification(
                "Failed to update recording preferences.",
                "",
                NotificationType.Error
            )
        },
    })

    const handleToggle = (checked: boolean) => {
        mutate(checked)
    }

    return (
        <ToggleSetting
            name="Allow users to record internal meetings"
            description="When disabled, Glyphic will not auto-join or record internal meetings for anyone in your organization."
            checked={data?.allow_internal_recording ?? false}
            disabled={false}
            loading={isPending}
            onChange={handleToggle}
        />
    )
}
