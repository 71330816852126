import { DangerousButton, SecondaryButton } from "../common/Buttons"
import { Modal } from "../common/Modal"
import { AccessLevel, SavedQuickAction } from "./types/QuickActionTypes"

export function QuickActionDeleteModal(props: {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    deleteQuickAction: (id: string) => void
    quickAction: SavedQuickAction
}) {
    return (
        <Modal isOpen={props.isOpen} onClose={() => props.setIsOpen(false)}>
            <div className="p-4 space-y-4">
                <div className="font-semibold text-md mr-10">
                    Are you sure you want to delete this Quick Action?
                </div>
                <div className="text-gray-500">
                    {getWarningMessage(props.quickAction.access_level)}
                </div>
                <div className="flex justify-end gap-4">
                    <SecondaryButton onClick={() => props.setIsOpen(false)}>
                        Cancel
                    </SecondaryButton>
                    <DangerousButton
                        onClick={async () => {
                            await props.deleteQuickAction(props.quickAction._id)
                            props.setIsOpen(false)
                        }}
                    >
                        Delete
                    </DangerousButton>
                </div>
            </div>
        </Modal>
    )
}

function getWarningMessage(accessLevel: AccessLevel) {
    switch (accessLevel) {
        case AccessLevel.Personal:
            return "This Quick Action is visible only to you."
        case AccessLevel.Team:
            return "This Quick Action will be deleted from all users in your team."
        case AccessLevel.Organization:
            return "This Quick Action will be deleted from all users in your organization."
        default:
            return ""
    }
}
