import React, { useState, useRef, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTag, faXmark } from "@fortawesome/free-solid-svg-icons"
import { useQuery } from "@tanstack/react-query"
import { queries } from "../../api/queries"
import { stringToColour } from "../../utils/stringToColour"

interface TagInputProps {
    existingTags: string[]
    onAddTag: (tag: string) => void
    onCancel: () => void
}

export function TagInput({ onAddTag, onCancel, existingTags }: TagInputProps) {
    const [inputValue, setInputValue] = useState("")
    const [showSuggestions, setShowSuggestions] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)

    const { data: allTags } = useQuery(queries.callTags.list())

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [])

    const newTags = allTags?.filter((tag) => !existingTags.includes(tag.name))
    const filteredTags =
        newTags?.filter((tag) =>
            tag.name.toLowerCase().includes(inputValue.toLowerCase())
        ) || []

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value)
    }

    const handleInputFocus = () => {
        setShowSuggestions(true)
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter" && inputValue.trim()) {
            onAddTag(inputValue.trim())
        } else if (e.key === "Escape") {
            onCancel()
        }
    }

    const handleSuggestionClick = (tagName: string) => {
        onAddTag(tagName)
    }

    return (
        <div className="relative">
            <div className="flex items-center bg-gray-100 border border-gray-200 shadow-sm px-2 rounded-md">
                <FontAwesomeIcon
                    icon={faTag}
                    className="text-gray-400 ml-2 mr-1 text-sm"
                />
                <input
                    ref={inputRef}
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    onKeyDown={handleKeyDown}
                    className="flex-grow py-0.5 px-1 text-sm bg-transparent focus:outline-none h-full"
                    placeholder="Add new tag..."
                />
                <button
                    className="p-0.5 text-gray-400 hover:text-gray-600 focus:outline-none h-full"
                    onClick={onCancel}
                >
                    <FontAwesomeIcon icon={faXmark} className="w-3 h-3" />
                </button>
            </div>
            {showSuggestions && newTags && newTags.length > 0 && (
                <ul className="absolute z-10 w-full mt-1 bg-white border border-gray-200 shadow-lg max-h-40 overflow-y-auto rounded-b-md">
                    {(inputValue ? filteredTags : newTags).map((tag) => (
                        <li
                            key={tag.id}
                            className="flex items-center px-3 py-1 hover:bg-gray-100 cursor-pointer text-sm transition-colors duration-150 ease-in-out"
                            onClick={() => handleSuggestionClick(tag.name)}
                        >
                            <span
                                className="w-2 h-2 rounded-full mr-1.5"
                                style={{
                                    backgroundColor: stringToColour(
                                        tag.name
                                    )[500],
                                }}
                            ></span>
                            <span>{tag.name}</span>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}
