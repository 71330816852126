import { faPen } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useMemo, useState } from "react"
import validator from "validator"
import { IParticipant } from "../../types/Call"
import { PrimaryButton } from "../common/Buttons"
import { Modal } from "../common/Modal"
import { UserCircle } from "../UserCircle"
import { getHeapInstance } from "../../utils/heap"

interface EditAttendeeViewProps {
    callId: string
    parties: IParticipant[]
    onChange: (newParties: IParticipant[]) => void
}

export function EditAttendeeView(props: EditAttendeeViewProps) {
    const [showAttendeeEditor, setShowAttendeeEditor] = useState(false)

    const handleEditAttendeeButtonClick = () => {
        setShowAttendeeEditor((prev) => !prev)
        getHeapInstance()?.track("call-attendee-edit-button-clicked", {
            callId: props.callId,
            parties: props.parties, // So we can study what we were missing
        })
    }

    if (showAttendeeEditor) {
        return (
            <EditAttendeeModal
                onClose={() => setShowAttendeeEditor(false)}
                {...props}
            />
        )
    }

    return (
        <button
            className="flex-shrink-0 w-fit hidden md:block"
            onClick={handleEditAttendeeButtonClick}
            data-tooltip-id="tooltip-explanation"
            data-tooltip-content="Edit call participants"
        >
            <FontAwesomeIcon icon={faPen} />
        </button>
    )
}

interface EditAttendeeModalProps {
    onClose: () => void
}

function EditAttendeeModal(
    props: EditAttendeeViewProps & EditAttendeeModalProps
) {
    const [updatedAttendees, setUpdatedAttendees] = useState(props.parties)

    useEffect(() => {
        setUpdatedAttendees(props.parties)
    }, [props.parties])

    const handleAttendeeChange = (updatedAttendee: IParticipant) => {
        const attendees = updatedAttendees.map((party) =>
            party.id === updatedAttendee.id ? updatedAttendee : party
        )
        setUpdatedAttendees(attendees)
    }

    const handleSave = () => {
        props.onChange(updatedAttendees)
        props.onClose()
    }

    // This is used to show an error message next to the save button to explain
    // why it's disabled.
    const areAllAttendeesValid = useMemo(() => {
        return updatedAttendees.every((attendee) =>
            isEmailValid(attendee.email)
        )
    }, [updatedAttendees])

    return (
        <Modal isOpen={true} onClose={props.onClose}>
            <div className="bg-gray-50 max-w-5xl max-h-screen rounded-lg border flex flex-col p-4 gap-2">
                <div className="flex flex-col p-4">
                    <h2 className="text-lg font-semibold">
                        Edit call participants
                    </h2>
                    <h4 className="text-gray-500">
                        Change the name or email of any call participant.
                    </h4>
                    <h4 className="text-gray-500">
                        Changes to the email will associate this call to the
                        right company.
                    </h4>
                </div>
                <div className="p-4 w-full">
                    <table className="max-h-96 w-full overflow-y-auto block">
                        <tr>
                            <th className="w-fit"></th>
                            <th className="w-1/2">Name</th>
                            <th className="w-1/2">Email</th>
                        </tr>
                        {updatedAttendees.map((party) => (
                            <AttendeeEditor
                                key={party.id}
                                attendee={party}
                                onChange={handleAttendeeChange}
                            />
                        ))}
                    </table>
                </div>
                <div className="flex flex-row items-center justify-end gap-2">
                    {!areAllAttendeesValid && (
                        <h4 className="text-red-500">
                            Some emails are invalid
                        </h4>
                    )}
                    <PrimaryButton
                        className="w-fit"
                        onClick={handleSave}
                        disabled={!areAllAttendeesValid}
                    >
                        Save
                    </PrimaryButton>
                </div>
            </div>
        </Modal>
    )
}

function AttendeeEditor({
    attendee,
    onChange,
}: {
    attendee: IParticipant
    onChange: (updatedAttendee: IParticipant) => void
}) {
    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        field: keyof IParticipant
    ) => {
        const updatedAttendee = { ...attendee, [field]: e.target.value }
        onChange(updatedAttendee)
    }

    const extraEmailClass = isEmailValid(attendee.email) ? "" : "border-red-500"

    return (
        <tr>
            <td className="py-1">
                <UserCircle user={attendee} size="40px" />
            </td>
            <td className="px-2 text-base font-semibold">
                <input
                    type="text"
                    value={attendee.name ?? ""}
                    className="w-full border rounded p-2 focus:outline-none"
                    onChange={(e) => handleInputChange(e, "name")}
                />
            </td>
            <td className="px-2 text-base font-semibold">
                <input
                    type="email"
                    value={attendee.email ?? ""}
                    className={
                        "w-full border rounded p-2 focus:outline-none " +
                        extraEmailClass
                    }
                    onChange={(e) => handleInputChange(e, "email")}
                />
            </td>
        </tr>
    )
}

function isEmailValid(email?: string | null) {
    return !email || validator.isEmail(email)
}
