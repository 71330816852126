import axios from "axios"
import { useEffect } from "react"
import LoadingSpinner from "../common/LoadingSpinner"
import { ICompanyTableEntry } from "../../types/Company"
import { CompaniesTable } from "./CompaniesTable"
import { useQuery } from "@tanstack/react-query"
import { ErrorPage } from "../common/errorPage"
import { FilterIcon } from "../../assets/icons/FilterIcon"
import { useSearchParam } from "../common/hooks/useSearchParam"

async function getCompanies() {
    const response = await axios.get(
        `${process.env.REACT_APP_API_DOMAIN}/companies`
    )
    return response.data
}

export function Companies() {
    const [filterTerm, setFilterTerm] = useSearchParam("filter")
    const {
        data: companies,
        isPending,
        isError,
    } = useQuery<ICompanyTableEntry[]>({
        queryKey: ["companies"],
        queryFn: getCompanies,
    })

    useEffect(() => {
        document.title = "Companies - " + process.env.REACT_APP_DOCUMENT_TITLE

        return () => {
            // Reset the title when the component unmounts
            document.title = process.env.REACT_APP_DOCUMENT_TITLE!
        }
    })

    if (isPending) return <LoadingSpinner />
    if (isError) {
        return <ErrorPage error={{ message: "Failed to load companies" }} />
    }
    if (companies.length === 0) return <Empty />

    return (
        <section className="my-6 rounded-lg px-8 py-6 space-y-4">
            <div className="flex flex-col md:flex-row justify-between gap-2">
                <h1 className="text-2xl font-bold">All Companies</h1>
                <Filter filterTerm={filterTerm} setFilterTerm={setFilterTerm} />
            </div>
            <CompaniesTable companies={companies} filterTerm={filterTerm} />
        </section>
    )
}

function Filter(props: {
    filterTerm: string
    setFilterTerm: (filterTerm: string) => void
}) {
    return (
        <div className="flex flex-row gap-2 w-full md:w-1/2 text-base bg-white items-center py-1 pl-4 border border-block rounded-lg">
            <FilterIcon className="text-gray-500" />
            <input
                autoFocus
                className="w-full outline-none py-2 placeholder:text-gray-600"
                type="text"
                placeholder="Filter by company domain"
                value={props.filterTerm}
                onChange={(e) => props.setFilterTerm(e.target.value)}
            />
        </div>
    )
}

function Empty() {
    return (
        <div className="flex items-center justify-center h-screen text-lg font-bold text-gray-600">
            Companies you talk to will show up here
        </div>
    )
}
