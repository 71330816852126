import { ParticipantContact } from "../types/Call"

export function getShortParticipantDisplayName(
    participant: ParticipantContact
): string {
    return (
        participant.name?.split(" ")[0] ||
        participant.email?.split("@")[0] ||
        participant.phone_number ||
        "Unknown"
    )
}

export function getParticipantDisplayName(
    participant: ParticipantContact
): string {
    return (
        participant.name ||
        participant.email?.split("@")[0] ||
        participant.phone_number ||
        "Unknown"
    )
}
