import { SecondaryButton } from "../common/Buttons"
import { Link } from "react-router-dom"
import { ICRMDeal } from "../../types/Deal"
import { CompanyLogo } from "../company-view/CompanyLogo"
import { QualificationScores } from "../deals/QualificationScores"
import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { LoadingPulse } from "../common/LoadingPulse"
import { getCacheValue, setCacheValue } from "../../utils/localStorageCache"
import { useMemo } from "react"
import {
    DEAL_CLOSE_DATE_RANGE,
    getTimeInterval,
} from "../deals/utils/dealCloseDate"
import { CrmType } from "../crm/types/Crm"
import { useUser } from "../../providers/UserProvider"
import { fetchDeals } from "../deals/DealsList"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCog, faHandshakeSimple } from "@fortawesome/free-solid-svg-icons"
import _ from "lodash"
import { DarkEmptyArea } from "./EmptyArea"
import { useCrmIntegration } from "../../hooks/useIntegrations"
import clsx from "clsx"
import { ICompany, ICompanyAnnotationRubricResults } from "../../types/Company"
import { queries } from "../../api/queries"

const RECENTLY_VIEWED_COMPANIES_CACHE_KEY = "recently-viewed-companies"
const MAX_DEALS_TO_SHOW = 3
const SUPPORTED_CRMS = [CrmType.Hubspot, CrmType.Salesforce]

export function RecentlyViewedDeals() {
    const recentlyViewedCompanies = getCacheValue<string[]>(
        RECENTLY_VIEWED_COMPANIES_CACHE_KEY,
        []
    )
    if (recentlyViewedCompanies.length === 0) {
        return null
    }
    return (
        <div>
            <h3 className="text-lg font-bold mb-3">Recently viewed deals</h3>
            <div className="grid grid-cols-3 lg:grid-cols-1 xl:grid-cols-3 gap-2">
                {recentlyViewedCompanies.map((companyDomain) => (
                    <RecentlyViewedDeal
                        key={companyDomain}
                        companyDomain={companyDomain}
                    />
                ))}
            </div>
        </div>
    )
}

export function DealsClosingThisQuarter() {
    const { crmType, isPending: isIntegrationsPending } = useCrmIntegration()
    const hasCrm = crmType !== undefined
    const hasUnsupportedCrm = hasCrm && !SUPPORTED_CRMS.includes(crmType)
    const closeDateInterval = useMemo(
        () => getTimeInterval(DEAL_CLOSE_DATE_RANGE.ThisQuarter),
        []
    )
    const user = useUser()
    const { data: crmUsers } = useQuery(queries.crm.users())

    const crmUser = crmUsers?.find((u) => u.email === user?.email)
    const ownerId = crmUser ? [crmUser.crm_id] : []
    const isDealFetchingEnabled = !!crmUsers && hasCrm

    const {
        data: deals,
        isPending,
        isError,
    } = useQuery<ICRMDeal[]>({
        queryKey: [
            "deals",
            closeDateInterval.toISO(),
            ownerId,
            "is_closed=false",
        ],
        queryFn: async () => {
            if (ownerId.length === 0) {
                return []
            }
            return await fetchDeals(closeDateInterval, ownerId, false)
        },
        staleTime: 5 * 60 * 1000, // 5 minutes
        enabled: isDealFetchingEnabled,
    })

    if (isError) {
        return null
    }

    // Filter out deals that don't have a company, and only show the MAX_DEALS_TO_SHOW with largest value
    let filteredDeals = deals?.filter((deal) => deal.company)
    filteredDeals = _.orderBy(
        filteredDeals,
        [(deal) => deal.amount || 0],
        ["desc"]
    )
    filteredDeals = filteredDeals?.slice(0, MAX_DEALS_TO_SHOW)

    if ((isDealFetchingEnabled && isPending) || isIntegrationsPending) {
        return (
            <div>
                <Header />
                <div className="grid grid-cols-3 lg:grid-cols-1 xl:grid-cols-3 gap-2">
                    {Array.from({ length: MAX_DEALS_TO_SHOW }).map((_, i) => (
                        <LoadingDeal key={i} />
                    ))}
                </div>
            </div>
        )
    }

    if (!hasCrm) {
        return (
            <div>
                <Header hasCrm={hasCrm} />
                <DarkEmptyArea>
                    <div className="flex flex-col gap-3 items-center">
                        <Link to="/settings">
                            <SecondaryButton className="flex items-center gap-2">
                                <FontAwesomeIcon icon={faCog} />
                                <span>Connect your CRM</span>
                            </SecondaryButton>
                        </Link>
                        <span className="max-w-[300px] text-center">
                            Connect Glyphic to your CRM to see your deals. You
                            must be a Glyphic admin to do this.
                        </span>
                    </div>
                </DarkEmptyArea>
            </div>
        )
    }

    if (hasUnsupportedCrm) {
        return (
            <div>
                <Header hasCrm={hasCrm} />
                <DarkEmptyArea>
                    <div className="flex flex-col gap-3 items-center">
                        <span className="text-center">
                            Requires a HubsSpot or Salesforce integration
                        </span>
                    </div>
                </DarkEmptyArea>
            </div>
        )
    }

    if (filteredDeals?.length === 0) {
        return (
            <div>
                <Header />
                <DarkEmptyArea>
                    No deals you own are closing this quarter 😕
                </DarkEmptyArea>
            </div>
        )
    }

    return (
        <div>
            <Header />
            <div className="grid grid-cols-3 gap-2">
                {filteredDeals?.slice(0, MAX_DEALS_TO_SHOW).map((deal) => (
                    <Deal company={deal.company!} deal={deal} key={deal.id} />
                ))}
            </div>
        </div>
    )
}

function Header(props: { hasCrm?: boolean }) {
    const noCrm = props.hasCrm === false
    return (
        <div className="flex justify-between items-center mb-3">
            <h3 className="text-lg font-bold">
                Deals you're closing this quarter
            </h3>
            <SecondaryButton disabled={noCrm}>
                <Link
                    to="/deals"
                    className={clsx(
                        "flex items-center space-x-2",
                        noCrm && "pointer-events-none"
                    )}
                >
                    <FontAwesomeIcon icon={faHandshakeSimple} />
                    <span>View all deals</span>
                </Link>
            </SecondaryButton>
        </div>
    )
}

function RecentlyViewedDeal(props: { companyDomain: string }) {
    const {
        data: company,
        isPending,
        isError,
    } = useQuery<ICompany>({
        queryKey: [`companies/${props.companyDomain}`],
        queryFn: async () => {
            const res = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/companies/${props.companyDomain}`
            )
            return res.data
        },
        staleTime: 5 * 60 * 1000,
    })
    const { data: deal } = useQuery<ICRMDeal | null | undefined>({
        queryKey: [props.companyDomain, "deals"],
        queryFn: async () => {
            const res = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/companies/${props.companyDomain}/deals`
            )
            const deals: ICRMDeal[] = res.data
            return deals?.[0]
        },
        staleTime: 5 * 60 * 1000,
    })

    const qualificationScores = useMemo(() => {
        return company?.summary
            ?.map((section) => section.qualification_rubric_score)
            .filter(
                (score): score is ICompanyAnnotationRubricResults => !!score
            )
    }, [company?.summary])

    if (isPending) {
        return <LoadingDeal />
    }

    if (isError) {
        return null
    }

    return (
        <Deal
            company={company}
            deal={deal}
            qualificationScores={qualificationScores}
        />
    )
}

function LoadingDeal() {
    return (
        <div className="bg-white py-8 px-4 rounded-lg shadow-md shadow-gray-200 flex flex-col items-center text-center">
            <LoadingPulse rows={4} />
        </div>
    )
}

interface ICompanyMetadata {
    name: string | null
    image_url: string | null
    domain: string
}

function Deal(props: {
    company: ICompanyMetadata
    deal?: ICRMDeal | null
    qualificationScores?: ICompanyAnnotationRubricResults[] | null
}) {
    return (
        <Link
            className="bg-white py-8 px-2 md:px-4 gap-2 rounded-lg shadow-md shadow-gray-200 flex flex-col items-center text-center hover:bg-gray-100 transition-colors ease-out w-full"
            to={`/companies/${props.company.domain}`}
        >
            <CompanyLogo image_url={props.company.image_url} />
            <div className="w-full text-ellipsis">
                <div
                    className="font-bold text-ellipsis overflow-clip whitespace-nowrap"
                    data-tooltip-id="tooltip-explanation"
                    data-tooltip-content={
                        props.deal?.name || props.company.name
                    }
                >
                    {props.deal?.name || props.company.name}
                </div>
                <div
                    className="text-sm text-ellipsis overflow-clip whitespace-nowrap"
                    data-tooltip-id="tooltip-explanation"
                    data-tooltip-content={props.deal?.stage?.name}
                >
                    {props.deal?.stage?.name}
                </div>
            </div>
            <div className="mt-3">
                {props.qualificationScores && (
                    <QualificationScores
                        scores={props.qualificationScores}
                        length={0}
                    />
                )}
            </div>
        </Link>
    )
}

export function markCompanyAsViewed(companyDomain: string) {
    const recentlyViewedCompanies = getCacheValue<string[]>(
        RECENTLY_VIEWED_COMPANIES_CACHE_KEY,
        []
    )
    const index = recentlyViewedCompanies.findIndex((s) => s === companyDomain)
    if (index !== -1) {
        recentlyViewedCompanies.splice(index, 1)
    }
    setCacheValue(RECENTLY_VIEWED_COMPANIES_CACHE_KEY, [
        companyDomain,
        ...recentlyViewedCompanies.slice(0, MAX_DEALS_TO_SHOW - 1),
    ])
}
