import { AboutWrapper } from "./AboutWrapper"

export function Support() {
    // This page is a requirement for Zoom marketplace approval
    // https://developers.zoom.us/docs/distribute/app-submission/submission-checklist/#6-provide-a-support-url
    return (
        <AboutWrapper title="Support">
            <div>
                For support or any questions please email support@glyphic.ai
            </div>
            <div>
                Support teams hours of operations are 24 hours a day, 7 days a
                week.
            </div>
            <div>First response SLA can be up to 24 hours.</div>
        </AboutWrapper>
    )
}
