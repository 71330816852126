import { SuperTokensWrapper } from "supertokens-auth-react"
import { ThirdpartyEmailPasswordComponentsOverrideProvider } from "supertokens-auth-react/recipe/thirdpartyemailpassword"
import logo from "./assets/logomark_sun_logotype_delta.svg"

export function SuperTokensProviders(props: { children: React.ReactNode }) {
    return (
        <SuperTokensWrapper>
            <ThirdpartyEmailPasswordComponentsOverrideProvider
                components={{
                    EmailPasswordSignInHeader_Override: ({
                        DefaultComponent,
                        ...props
                    }) => {
                        return (
                            <>
                                <img
                                    src={logo}
                                    alt="Glyphic logo"
                                    className="w-52 mx-auto my-8"
                                />
                                <DefaultComponent {...props} />
                            </>
                        )
                    },
                }}
            >
                {props.children}
            </ThirdpartyEmailPasswordComponentsOverrideProvider>
        </SuperTokensWrapper>
    )
}
