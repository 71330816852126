// https://flowbite.com/docs/forms/toggle/

import { useEffect, useState } from "react"
import { CircleLoadingSpinner } from "./CircleLoadingSpinner"
import clsx from "clsx"

export default function ToggleButton(props: {
    checked: boolean
    disabled?: boolean
    loading?: boolean
    onChange: (checked: boolean) => void
}) {
    const [isChecked, setIsChecked] = useState(props.checked)
    const disabled = props.disabled || props.loading || false
    const backgroundColor = disabled ? "bg-gray-300" : "bg-gray-400"

    useEffect(() => {
        setIsChecked(props.checked)
    }, [props.checked])

    function handleToggle() {
        if (disabled) {
            return
        }
        const newChecked = !isChecked
        setIsChecked(newChecked)
        props.onChange(newChecked)
    }

    return (
        <div className="flex flex-row items-center">
            <label
                className={`relative inline-flex items-center ${
                    disabled ? "cursor-default" : "cursor-pointer"
                }`}
            >
                <input
                    type="checkbox"
                    value=""
                    className="sr-only peer"
                    disabled={disabled}
                    checked={isChecked}
                    onChange={() => {}}
                />
                <div
                    className={clsx(
                        `w-11 h-6 ${backgroundColor} rounded-full`,
                        !props.loading &&
                            `peer peer-checked:after:translate-x-full  peer-checked:bg-papyrus
                        after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white
                        after:rounded-full after:h-5 after:w-5 after:transition-all`
                    )}
                    onClick={handleToggle}
                >
                    {props.loading && (
                        <CircleLoadingSpinner
                            className={clsx(
                                "text-white mt-0.5",
                                isChecked ? "ml-5" : "ml-1"
                            )}
                        />
                    )}
                </div>
            </label>
        </div>
    )
}
