import { AskGlyphicAvi } from "../../assets/AskGlyphicAvi"
import { useUser } from "../../providers/UserProvider"
import { UserInitialsCircle } from "../UserCircle"
import { LoadingPulse } from "../common/LoadingPulse"
import { ResponseFeedback } from "./ResponseFeedback"
import { AnswerFeedback } from "./types/QuestionTypes"
import { CopyIcon } from "../../assets/icons/CopyIcon"
import { getHeapInstance } from "../../utils/heap"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"
import { useCallback } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faShareSquare as faMail } from "@fortawesome/free-regular-svg-icons"

export function UserMessage({ message }: { message: string }) {
    const user = useUser()
    const userName = user?.name || ""
    return (
        <div className="flex flex-row gap-2 items-start animate-fadeInUp group">
            <UserInitialsCircle name={userName} size="24px" />
            <div className="whitespace-pre-wrap">{message.trim()}</div>
        </div>
    )
}

export function AiMessage(props: {
    message: string
    isComplete: boolean
    isLast: boolean
    submitFeedback: (feedback: AnswerFeedback) => Promise<void>
    isReadOnly?: boolean
    sendEmail: (message: string) => Promise<void>
}) {
    return (
        <div className="flex flex-row gap-2 items-start animate-fadeInUp">
            <AskGlyphicAvi className="w-6 h-6 flex-shrink-0" />
            {props.message ? (
                <div>
                    <div className="whitespace-pre-wrap">
                        {props.message.trim()}
                    </div>
                    {props.isComplete && (
                        <div className="h-4 p-1 flex flex-row gap-4 text-lg items-center group my-4">
                            {props.isLast && props.isReadOnly !== true && (
                                <ResponseFeedback
                                    submitFeedback={props.submitFeedback}
                                />
                            )}
                            <div className="block">
                                <CopyMessageButton message={props.message} />
                            </div>
                            <div className="block">
                                <EmailMessageButton
                                    message={props.message}
                                    sendEmail={props.sendEmail}
                                />
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <LoadingPulse rows={2} />
            )}
        </div>
    )
}

function CopyMessageButton(props: { message: string }) {
    const { addNotification } = useNotification()

    const copy = async () => {
        try {
            await navigator.clipboard.writeText(props.message)
            getHeapInstance()?.track("askglyphic-message-copied", {
                message: props.message,
            })
            addNotification("Response copied", "", NotificationType.Success)
        } catch (error) {
            console.error("Failed to copy message:", error)
        }
    }

    return (
        <button
            className="flex flex-nowrap items-center gap-1 text-gray-500 hover:text-gray-800"
            onClick={copy}
            data-tooltip-id="tooltip-explanation"
            data-tooltip-content="Copy response to clipboard"
        >
            <CopyIcon width="1em" height="1em" />
            <span className="text-base">Copy</span>
        </button>
    )
}

interface EmailMessageButtonProps {
    message: string
    sendEmail: (message: string) => Promise<void>
}

function EmailMessageButton(props: EmailMessageButtonProps) {
    const { addNotification } = useNotification()

    const handleSendEmail = useCallback(async () => {
        try {
            await props.sendEmail(props.message)
            getHeapInstance()?.track("askglyphic-message-emailed", {
                message: props.message,
            })
        } catch (error) {
            addNotification(
                "Failed to email message!",
                `${error}`,
                NotificationType.Error
            )
        }
    }, [addNotification, props])

    return (
        <button
            className="flex flex-nowrap items-center gap-1 text-gray-500 hover:text-gray-800"
            onClick={handleSendEmail}
            data-tooltip-id="tooltip-explanation"
            data-tooltip-content="Create a new email with this response"
        >
            <FontAwesomeIcon icon={faMail} width="1em" height="1em" />
            <span className="text-base">Email</span>
        </button>
    )
}
