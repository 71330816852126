export function Suggestions(props: { suggestions: string[] }) {
    return (
        <div className="bg-white border border-gray-300 rounded-lg p-3 space-y-4">
            <div className="font-bold">Suggested discussion points</div>
            {props.suggestions.map((suggestion) => (
                <Suggestion
                    key={suggestion}
                    suggestion={suggestion}
                    highlightColour={"bg-sun"}
                />
            ))}
        </div>
    )
}

function Suggestion(props: { suggestion: string; highlightColour: string }) {
    return (
        <div className="flex space-x-2">
            <BulletPoint colour={props.highlightColour} />
            <div>{props.suggestion}</div>
        </div>
    )
}

function BulletPoint(props: { colour: string }) {
    return (
        <div className={`flex-none w-1 mt-1 mb-1 rounded-lg ${props.colour}`} />
    )
}
