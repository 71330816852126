export function AddBotIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect
                x="15.8333"
                y="5.83331"
                width="1.66667"
                height="6.66667"
                rx="0.833333"
                fill="currentColor"
            />
            <rect
                x="20"
                y="8.33331"
                width="1.66667"
                height="6.66667"
                rx="0.833333"
                transform="rotate(90 20 8.33331)"
                fill="currentColor"
            />
            <rect
                x="2.41669"
                y="2.41669"
                width="10.1667"
                height="5.16667"
                rx="2.58333"
                stroke="currentColor"
                strokeWidth="1.5"
            />
            <rect
                x="2.41669"
                y="10.75"
                width="10.1667"
                height="7.66667"
                rx="3.25"
                stroke="currentColor"
                strokeWidth="1.5"
            />
            <rect
                x="6.66669"
                y="7.5"
                width="1.66667"
                height="3.33333"
                fill="currentColor"
            />
            <circle
                cx="5.00002"
                cy="5.00002"
                r="0.833333"
                fill="currentColor"
            />
            <ellipse
                cx="10"
                cy="5.00002"
                rx="0.833333"
                ry="0.833333"
                fill="currentColor"
            />
        </svg>
    )
}
