import {
    ColumnDef,
    Row,
    SortingState,
    Updater,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table"
import {
    ICompanyTableCallPreview,
    ICompanyTableEntry,
} from "../../types/Company"
import { useMemo, useState } from "react"
import DetailsTableHeaderGroup from "../calls-list/DetailsTableHeaderGroup"
import { TablePageNavigator } from "../calls-list/PageNavigator"
import { getSimpleDate } from "../../utils/datetime"
import { ScreenSize, screenLargerOrEqualTo } from "../../utils/screenSize"
import { CompanyLogo } from "./CompanyLogo"
import { NavigateLink } from "../common/NavigateLink"
import { getCacheValue, setCacheValue } from "../../utils/localStorageCache"

const COMPANY_TABLE_SORT_CACHE_KEY = "company-table-sorting"

export function CompaniesTable(props: {
    companies: ICompanyTableEntry[]
    filterTerm: string
}) {
    const columns = useMemo<ColumnDef<ICompanyTableEntry, any>[]>(() => {
        return [
            {
                id: "logo",
                accessorFn: (row) => row.image_url,
                header: "",
                cell: (info) => <CompanyLogo image_url={info.getValue()} />,
                enableSorting: false,
                size: 10,
            },
            {
                id: "name",
                accessorFn: (row) => row.name || row.domain,
                header: "Name",
                cell: (info) => (
                    <span className="block overflow-hidden overflow-ellipsis font-bold">
                        {info.getValue()}
                    </span>
                ),
                sortingFn: "text",
                enableSorting: true,
            },
            {
                id: "domain",
                accessorFn: (row) => row.domain,
                header: "Domain",
                cell: (info) => (
                    <span className="block overflow-hidden overflow-ellipsis">
                        {info.getValue()}
                    </span>
                ),
                sortingFn: "text",
                enableSorting: true,
            },
            {
                id: "last_call",
                accessorFn: (row) => row.last_call,
                header: "Last call date",
                cell: (info) => (
                    <CallPreview call={info.getValue()} showTitle={false} />
                ),
                sortingFn: sortLastCall,
                size: 40,
            },
            {
                id: "next_call",
                accessorFn: (row) => row.next_call,
                header: "Upcoming call",
                cell: (info) => (
                    <CallPreview call={info.getValue()} showTitle={true} />
                ),
                sortingFn: sortNextCall,
            },
        ]
    }, [])

    const [sorting, setSorting] = useState<SortingState>(
        getCacheValue(COMPANY_TABLE_SORT_CACHE_KEY, [
            {
                id: "last_call",
                desc: true,
            },
        ])
    )

    const showAllColumns: boolean = screenLargerOrEqualTo(ScreenSize.md)

    const table = useReactTable({
        data: props.companies,
        columns,

        enableSorting: true,

        initialState: {
            pagination: {
                pageSize: 20,
            },
        },

        state: {
            columnVisibility: {
                domain: showAllColumns,
                last_call: showAllColumns,
            },
            globalFilter: props.filterTerm,
            sorting: sorting,
        },
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),

        globalFilterFn: "includesString",

        onSortingChange: (sortUpdater: Updater<SortingState>) => {
            // When we use onSortingChange, we need to maintain the sorting state ourselves in a useState
            // https://tanstack.com/table/v8/docs/api/features/sorting#onsortingchange
            const newSorting =
                sortUpdater instanceof Function
                    ? sortUpdater(sorting)
                    : sortUpdater
            setSorting(newSorting)
            setCacheValue(COMPANY_TABLE_SORT_CACHE_KEY, newSorting)
        },
    })

    return (
        <div>
            <div className="text-base">
                <table className="p-2 table-fixed w-full border-separate border-spacing-y-2">
                    <thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <DetailsTableHeaderGroup
                                key={headerGroup.id}
                                headerGroup={headerGroup}
                            />
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map((row) => (
                            <CompanyRow key={row.id} row={row} />
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="mt-4">
                <TablePageNavigator table={table} />
            </div>
        </div>
    )
}

function CallPreview(props: {
    call: ICompanyTableCallPreview | null
    showTitle: boolean
}) {
    if (!props.call) {
        return <></>
    }
    return (
        <span className="space-x-2 block overflow-hidden whitespace-nowrap overflow-ellipsis">
            <span>{getSimpleDate(props.call.time)}</span>
            {props.showTitle && props.call.title && (
                <span className="text-gray-500">{props.call.title}</span>
            )}
        </span>
    )
}

function sortLastCall(
    rowA: Row<ICompanyTableEntry>,
    rowB: Row<ICompanyTableEntry>,
    columnId: string
) {
    return sortCallPreview(rowA, rowB, columnId, -1)
}

function sortNextCall(
    rowA: Row<ICompanyTableEntry>,
    rowB: Row<ICompanyTableEntry>,
    columnId: string
) {
    return sortCallPreview(rowA, rowB, columnId, 1)
}

function sortCallPreview(
    rowA: Row<ICompanyTableEntry>,
    rowB: Row<ICompanyTableEntry>,
    columnId: string,
    sortNull: 1 | -1
): number {
    const valA: ICompanyTableCallPreview | null = rowA.getValue(columnId)
    const valB: ICompanyTableCallPreview | null = rowB.getValue(columnId)

    if (valA === null && valB === null) {
        return 0
    } else if (valA === null) {
        return sortNull
    } else if (valB === null) {
        return sortNull * -1
    } else {
        return valA.time.localeCompare(valB.time)
    }
}

export function CompanyRow(props: { row: Row<ICompanyTableEntry> }) {
    const href = `/companies/${props.row.original.domain}`

    return (
        <tr
            className="h-16 border-b last:border-0 transition-colors ease-out hover:bg-slate-100 hover:cursor-pointer
                    bg-white rounded-xl shadow-md shadow-gray-100"
        >
            {props.row.getVisibleCells().map((cell) => (
                <td
                    key={cell.id}
                    className="overflow-y-clip first:rounded-l-xl last:rounded-r-xl"
                >
                    <NavigateLink
                        href={href}
                        className="flex w-full h-full items-center p-2"
                    >
                        {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                        )}
                    </NavigateLink>
                </td>
            ))}
        </tr>
    )
}
