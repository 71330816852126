import { faHubspot, faSalesforce } from "@fortawesome/free-brands-svg-icons"
import {
    faAddressBook,
    IconDefinition,
} from "@fortawesome/free-regular-svg-icons"
import { CrmTable } from "../types/Crm"
import {
    faArrowUpRightFromSquare,
    faHandshakeSimple,
    faLandmark,
    faUser,
} from "@fortawesome/free-solid-svg-icons"

export function crmIconFromUrl(url: string): IconDefinition {
    // From a url to any crm, returns the relevant icon
    if (url.includes("hubspot.com")) {
        return faHubspot
    }
    if (url.includes("force.com")) {
        return faSalesforce
    }
    // If crm is unknown, return default logo
    return faAddressBook
}

export function iconFromCrmTable(table: CrmTable) {
    if (table === CrmTable.Contacts) {
        return faUser
    } else if (table === CrmTable.Deals) {
        return faHandshakeSimple
    } else if (table === CrmTable.Companies) {
        return faLandmark
    } else {
        return faArrowUpRightFromSquare
    }
}
