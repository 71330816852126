import { useState } from "react"
import DividerLine from "../common/DividerLine"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faArrowLeft,
    faArrowRight,
    faDownload,
} from "@fortawesome/free-solid-svg-icons"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import axios from "axios"

export interface CaseStudyContent {
    id: string
    filename: string
    uploader_user_id: string
    organization_id: string
    title: string
    summary: string
    problem: string
    approach: string
    outcome: string
    reason?: string
}

export function CaseStudies({
    caseStudies,
}: {
    caseStudies: CaseStudyContent[]
}) {
    const [activeCaseId, setActiveCaseId] = useState(0)

    if (caseStudies.length === 0) {
        return (
            <div className=" text-gray-500">
                No relevant case studies found.
            </div>
        )
    }

    const canClickNext = activeCaseId !== caseStudies.length - 1
    const canClickPrev = activeCaseId !== 0
    const handleNextClicked = () => {
        setActiveCaseId((prev) => prev + 1)
    }
    const handlePrevClicked = () => {
        setActiveCaseId((prev) => prev - 1)
    }

    return (
        <div className="bg-white border border-gray-300 rounded-lg p-3">
            <div className="space-x-3 flex justify-end">
                <ArrowButton
                    canClick={canClickPrev}
                    handleClick={handlePrevClicked}
                    icon={faArrowLeft}
                />
                <ArrowButton
                    canClick={canClickNext}
                    handleClick={handleNextClicked}
                    icon={faArrowRight}
                />
            </div>
            <CaseStudy caseStudy={caseStudies[activeCaseId]} />
        </div>
    )
}

function CaseStudy(props: { caseStudy: CaseStudyContent }) {
    return (
        <div className="space-y-3">
            <div className="font-bold text-lg">{props.caseStudy.title}</div>
            <div className="font-bold">Summary</div>
            <div>{props.caseStudy.summary}</div>
            <DividerLine />
            <div className="space-y-3">
                <div className="font-bold">The Problem</div>
                <div>{props.caseStudy.problem}</div>
            </div>
            <DividerLine />
            <div className="space-y-3">
                <div className="font-bold">What we did</div>
                <div>{props.caseStudy.approach}</div>
            </div>
            <DividerLine />
            <div className="space-y-3">
                <div className="font-bold">The Outcome</div>
                <div>{props.caseStudy.outcome}</div>
            </div>
            {props.caseStudy.reason && (
                <>
                    <DividerLine />
                    <div className=" text-amber-500">
                        ✦ {props.caseStudy.reason}
                    </div>
                </>
            )}
            <DividerLine />
            <SourceItem caseStudy={props.caseStudy} />
        </div>
    )
}

function ArrowButton(props: {
    canClick: boolean
    handleClick: () => void
    icon: IconProp
}) {
    return (
        <button
            className={`border border-gray-300 rounded-xl py-1 px-3  ${
                props.canClick
                    ? "transition-colors hover:bg-gray-100 text-gray-600"
                    : "text-gray-300"
            }`}
            onClick={props.handleClick}
            disabled={!props.canClick}
        >
            <FontAwesomeIcon icon={props.icon} />
        </button>
    )
}

function SourceItem(props: { caseStudy: CaseStudyContent }) {
    return (
        <button
            onClick={() => downloadCaseStudy(props.caseStudy.id)}
            className="flex flex-row justify-start items-center w-full gap-2"
        >
            <FontAwesomeIcon
                icon={faDownload}
                className="text-gray-500 hover:text-gray-700"
            />
            <p className="flex text-black">{props.caseStudy.filename}</p>
        </button>
    )
}

export async function downloadCaseStudy(caseStudyId: string) {
    // Fetch presigned S3 url for document
    const response = await axios.get(
        `${process.env.REACT_APP_API_DOMAIN}/case-studies/${encodeURIComponent(
            caseStudyId
        )}`
    )

    // Navigate to url to download document
    const url = response.data
    if (url) {
        window.open(url, "_blank")
    }
}
