import { ParticipantContact } from "../../../types/Call"
import { getParticipantDisplayName } from "../../../utils/getParticipantDisplayName"

type PartyComparator = (a: ParticipantContact, b: ParticipantContact) => number

/**
 * Sorts participants based on a set of relevance rules.
 *
 * The rules are as follows:
 * - Parties without an email (guests) are considered least relevant and moved
 *   to the end of the list.
 * - For parties with an email, external participants are preferred over
 *   internal ones.
 * - If a party's email domain matches the `currentDomain`, it's considered
 *   internal, else external.
 * - If both parties have the same email, they are sorted alphabetically by
 *   their display name.
 *
 * @param {IParticipant[]} parties - The array of participants to be sorted.
 * @param {string} [currentDomain] - The domain used to classify participants as
 *                                   internal or external.
 *                                   If omitted, all participants are treated as
 *                                   external.
 * @returns {IParticipant[]} - The array of participants sorted by relevance.
 */
export function partyComparatorByRelevance(
    currentDomain?: string
): PartyComparator {
    const lowerDomain = currentDomain?.toLowerCase()

    return (a: ParticipantContact, b: ParticipantContact) => {
        // We put parties without email at the end of the list (guests)
        if (!a.email && b.email) return 1
        if (a.email && !b.email) return -1

        // Check if emails belong to the current domain (internal users)
        // This assumes all internal users have the same domain (that seems to
        // be the case for now)
        const isAInternal = isInternalEmail(a.email, lowerDomain)
        const isBInternal = isInternalEmail(b.email, lowerDomain)

        // We prefer external participants over internal ones
        if (isAInternal && !isBInternal) return 1
        if (!isAInternal && isBInternal) return -1

        // If both emails belong to the same domain (either both internal or
        // both external or unknown) then sort by participant display name
        const aName = getParticipantDisplayName(a)
        const bName = getParticipantDisplayName(b)

        return aName.localeCompare(bName)
    }
}

function isInternalEmail(
    email?: string | null,
    currentDomain?: string | null
): boolean {
    return email?.toLowerCase().endsWith(currentDomain ?? "") ?? false
}
