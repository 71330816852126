import axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLock, faLockOpen } from "@fortawesome/pro-regular-svg-icons"

import { ICallPreview } from "../../types/Call"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"
import { useUser } from "../../providers/UserProvider"
import { getHeapInstance } from "../../utils/heap"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { IUser } from "../../types/User"
import clsx from "clsx"
import { queries } from "../../api/queries"

export function TogglePrivacyButton({ call }: { call: ICallPreview }) {
    const { addNotification } = useNotification()
    const queryClient = useQueryClient()
    const user = useUser()

    const togglePrivacy = useMutation({
        mutationFn: async () => {
            getHeapInstance()?.track("call-toggle-privacy-clicked", {
                new_privacy_value: !call.is_private,
            })
            await axios.patch(
                `${process.env.REACT_APP_API_DOMAIN}/calls/${call.id}/privacy`,
                null,
                {
                    params: {
                        is_private: !call.is_private,
                    },
                }
            )
        },
        onSuccess: () => {
            const is_private = !call.is_private
            if (is_private) {
                addNotification(
                    "This call is now private",
                    "Only attendees can view this call.",
                    NotificationType.Success
                )
            } else {
                addNotification(
                    "This call is now public",
                    "Anyone in your organization can view this call.",
                    NotificationType.Success
                )
            }
            queryClient.invalidateQueries({
                queryKey: queries.calls.byId(call.id).queryKey,
            })
            queryClient.invalidateQueries({
                queryKey: queries.calls.media(call.id).queryKey,
            })
        },
        onError: () => {
            addNotification(
                "Failed to update call privacy!",
                "Please try again later.",
                NotificationType.Error
            )
        },
    })

    if (!call.can_view) {
        // Only users with permission should be able to make public
        return null
    }

    const canTogglePrivacy = user && userIsAttendee(call, user)

    return (
        <button
            onClick={() => togglePrivacy.mutate()}
            className={clsx(
                "flex flex-col items-center text-sm",
                canTogglePrivacy
                    ? "group text-gray-600 hover:text-gray-800"
                    : "text-gray-400 cursor-not-allowed"
            )}
            disabled={!canTogglePrivacy}
            data-tooltip-id="tooltip-explanation"
            data-tooltip-content={
                canTogglePrivacy
                    ? ""
                    : "Only attendees of the call can toggle privacy"
            }
        >
            <AnimatedLockIcon isPrivate={call.is_private} />
            <span className="hidden md:block">
                {call.is_private ? "Make public" : "Make private"}
            </span>
        </button>
    )
}

function userIsAttendee(call: ICallPreview, user: IUser) {
    return call.parties.some((party) => party.email === user.email)
}

export function AnimatedLockIcon({ isPrivate }: { isPrivate: boolean }) {
    return (
        <div className="relative w-4 h-4">
            <FontAwesomeIcon
                icon={isPrivate ? faLock : faLockOpen}
                className="absolute inset-0 group-hover:opacity-0 duration-300"
            />
            <FontAwesomeIcon
                icon={isPrivate ? faLockOpen : faLock}
                className="absolute inset-0 opacity-0 group-hover:opacity-100 duration-300"
            />
        </div>
    )
}
