import _ from "lodash"
import { IAnnotation, IAnnotationSection } from "../types/Annotation"

/**
 * A list of all the annotation types that are supported by the UI.
 *
 * The first element of each tuple is the tag that is used to identify the
 * annotation type in the database. The second element is the heading that is
 * displayed in the UI.
 *
 * We should update or delete these tags only if all the annotations in the
 * database have been migrated to the new tag or deleted.
 */

export interface IAnnotationType {
    tag: string
    label: string
    acronym?: string
    emoji?: string
}

const spicedTags: IAnnotationType[] = [
    { tag: "spiced_situation", label: "Situation", acronym: "S", emoji: "🔍" },
    { tag: "spiced_problem", label: "Problem", acronym: "P", emoji: "❗" }, // Deprecated in favor of "Pain"
    { tag: "spiced_pain", label: "Pain", acronym: "P", emoji: "💊" },
    { tag: "ranked_spiced_pain", label: "Pain", acronym: "P", emoji: "💊" },
    { tag: "spiced_impact", label: "Impact", acronym: "I", emoji: "📈" },
    { tag: "ranked_spiced_impact", label: "Impact", acronym: "I", emoji: "📈" },

    {
        tag: "spiced_critical_event",
        label: "Critical Event",
        acronym: "C",
        emoji: "⏰",
    },
    { tag: "spiced_decision", label: "Decision", acronym: "D", emoji: "✍️" },
]

const meddpiccTags: IAnnotationType[] = [
    { tag: "meddpicc_metrics", label: "Metrics", acronym: "M", emoji: "📊" },
    {
        tag: "meddpicc_economic_buyer",
        label: "Economic Buyer",
        acronym: "E",
        emoji: "💼",
    },
    {
        tag: "meddpicc_decision_criteria",
        label: "Decision Criteria",
        acronym: "D",
        emoji: "✍️",
    },
    {
        tag: "meddpicc_decision_process",
        label: "Decision Process",
        acronym: "D",
        emoji: "✍️",
    },
    {
        tag: "meddpicc_paper_process",
        label: "Paper Process",
        acronym: "P",
        emoji: "📝",
    },
    {
        tag: "meddpicc_identify_pain",
        label: "Identify Pain",
        acronym: "I",
        emoji: "💊",
    },
    { tag: "meddpicc_champion", label: "Champion", acronym: "C", emoji: "🏆" },
    {
        tag: "meddpicc_competition",
        label: "Competition",
        acronym: "C",
        emoji: "🥊",
    },
]

const ntaboTags: IAnnotationType[] = [
    { tag: "need", label: "Need", acronym: "N", emoji: "❗" },
    { tag: "timelines", label: "Timelines", acronym: "T", emoji: "⏳" },
    { tag: "authority", label: "Authority", acronym: "A", emoji: "✍️" },
    { tag: "budget", label: "Budget", acronym: "B", emoji: "💰" },
    { tag: "obstacles", label: "Obstacles", acronym: "O", emoji: "⛔️" },
    { tag: "ranked_obstacles", label: "Obstacles", acronym: "O", emoji: "⛔️" },
]

export const obstaclesTagNames: Set<string> = new Set([
    "obstacles",
    "ranked_obstacles",
])

const ascendTags: IAnnotationType[] = [
    {
        tag: "ascend_next_step",
        label: "Next Steps",
        emoji: "➡️",
    },
    { tag: "ascend_metrics", label: "Metrics", acronym: "M", emoji: "📊" },
    {
        tag: "ascend_economic_buyer",
        label: "Economic Buyer",
        acronym: "E",
        emoji: "💼",
    },
    {
        tag: "ascend_decision_criteria",
        label: "Decision Criteria",
        acronym: "D",
        emoji: "✍️",
    },
    {
        tag: "ascend_decision_process",
        label: "Decision Process",
        acronym: "D",
        emoji: "✍️",
    },
    {
        tag: "ascend_pain_gain",
        label: "Pain/Gain",
        acronym: "P",
        emoji: "💊",
    },
    { tag: "ascend_champion", label: "Champion", acronym: "C", emoji: "🏆" },
    {
        tag: "ascend_competition",
        label: "Competition",
        acronym: "C",
        emoji: "🥊",
    },
    {
        tag: "ascend_compelling_event",
        label: "Compelling Event",
        acronym: "C",
        emoji: "⏰",
    },

    { tag: "ascend_risks", label: "Risks", acronym: "R", emoji: "⛔️" },
]

export const annotationTypes: IAnnotationType[] = [
    { tag: "next_steps", label: "Next Steps", emoji: "➡️" },
    ...meddpiccTags,
    ...spicedTags,
    ...ntaboTags,
    ...ascendTags,
    {
        tag: "buyer_background",
        label: "Buyer Background",
        emoji: "👥",
    },
    // Buyer questions are at the very end because they can be quite long.
    {
        tag: "buyer_questions",
        label: "Buyer Questions",
        emoji: "❓",
    },
]

/**
 * Returns a list of annotation sections that can be used to render the
 * annotations in the UI.
 * @param annotations A dictionary of annotations where the keys are the
 * annotation tags and the values are the annotations.
 * @returns A list of annotation sections.
 */
export function getAnnotationSections(
    annotations: Record<string, IAnnotation[]>
): IAnnotationSection[] {
    const sections = annotationTypes
        .filter((annotationType) => annotationType.tag in annotations)
        .map((annotationType) => {
            const annotationsWithTag = annotations[annotationType.tag] || []
            return {
                heading: annotationType.label,
                annotations: annotationsWithTag,
                key: annotationType.tag,
            }
        })
    const [covered, notCovered] = _.partition(
        sections,
        (section) => section.annotations.length > 0
    )
    return [...covered, ...notCovered]
}
