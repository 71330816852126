import { SecondaryButton } from "./common/Buttons"
import { Link } from "react-router-dom"

export function InviteBotBanner() {
    return (
        <div className="flex flex-wrap w-full items-center gap-2 bg-blue-100 py-3 px-3 rounded-lg max-w-full">
            <div className="text-sm md:text-base flex-col text-blue-700">
                Meeting already started? Send the copilot to record it from the
            </div>
            <Link to="/schedule">
                <SecondaryButton>Schedule page</SecondaryButton>
            </Link>
        </div>
    )
}
