export function LightbulbIcon(props: { className?: string }) {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M10 19H14"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M11 22H13"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M12.0003 16.0005H10.1254C9.76746 16.0005 9.43423 15.8181 9.24127 15.5167L5.87694 10.2612C4.03963 7.80155 4.65227 1.65234 12.0003 1.65234"
                stroke="currentColor"
                strokeWidth="2.09944"
            />
            <path
                d="M12.0002 16.0005H13.8751C14.233 16.0005 14.5663 15.8181 14.7592 15.5167L18.1235 10.2612C19.9609 7.80155 19.3482 1.65234 12.0002 1.65234"
                stroke="currentColor"
                strokeWidth="2.09944"
            />
        </svg>
    )
}
