import { Link } from "react-router-dom"
import logo from "../../assets/logomark_sun_logotype_cotton.svg"
import { ReactNode } from "react"

export function AboutWrapper(props: { title: string; children: ReactNode }) {
    return (
        <div className="flex flex-col items-center p-16 bg-delta min-h-screen space-y-10 text-white">
            <AboutHeader />
            <div className="space-y-5">
                <h1 className="text-3xl font-bold">{props.title}</h1>
                {props.children}
            </div>
            <Link to="/about" className="underline">
                Home
            </Link>
        </div>
    )
}

export function AboutHeader() {
    return (
        <div className="text-center space-y-4">
            <img src={logo} alt="Logo" className="h-12 mx-auto" />
        </div>
    )
}
