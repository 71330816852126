import { ResourceType } from "./types/ResourceTypes"

export const GLOBAL_QUESTION_SUGGESTIONS = [
    "🚧 What are some objections we struggle with?",
    "👂 How do people usually hear about us?",
    "🎭 What challenges have buyers faced with our competitors?",
    "🔑 What are the main pain points mentioned by buyers?",
    "🌟 What positive outcomes have clients reported after implementing our product?",
    "📢 How have we articulated our value proposition to enterprise-level clients?",
    "💰 Which customers mentioned concerns on pricing?",
    "🔨 What questions have prospects asked about our implementation process?",
]

const CALL_QUESTION_SUGGESTIONS = [
    "💭 What other solutions is the buyer considering?",
    "🤝 How can we close the deal?",
    "🎭 Summarize the buyer's sentiment",
    "📋 Provide a call timeline summary",
    "📝 Write a proposal following the Situation, Solution, Impact format",
    "👥 What are the people and teams involved?",
    "💊 What were the top three challenges or pain points the client mentioned, and how can our solution address them?",
    "🥊 What other solutions or competitors did the client mention, and how does our offering compare?",
    // Coaching Section
    "🤔 What could I have done better in this call?",
    "👂 How did I do on this call?",
    "🤕 What questions should I have asked to better identify the prostpect's pain points?",
    "📈 What specific areas for improvement can we identify in our pitch or approach based on this call?",
    "️🧩 Break down the call into actionable insights and learning points for the team.",
    "💡 What specific phrases or approaches seemed to resonate most with the client, and how can we replicate this in future calls?",
    "🎓 Give me some coachable moments from this call",
    "❓ Suggest questions for a follow-up call",
]

const COMPANY_QUESTION_SUGGESTIONS = [
    "👥 Who are the key stakeholders involved?",
    "💊 What were the top three challenges or pain points the client mentioned, and how can our solution address them?",
    "💭 What other solutions is the buyer considering?",
    "💸 Have we agreed on a price?",
    "🤝 How can we close the deal?",
    "🕳️ What are the risks to the deal?",
    "🎭 Summarize the buyer's sentiment",
    "❓ Suggest questions for a follow-up call",
    "🥅 Suggest 3 open-ended questions to uncover the prospect's goals",
    "🪜 What are the agreed next steps?",
    "🛑 Are there any blockers to the deal?",
    "🎯 How do stakeholders influence the buying decision, and how should we tailor our communication with them?",
    "✨ Which features or aspects of our product/service got the most positive response, and how can we emphasize these in future discussions?",
    "🛡️ How can we better anticipate and address common objections or concerns raised by clients in this sector?",
    "🧠 Which areas of product knowledge need strengthening based on the questions and feedback from the client?",
    "🚧 In response to our solution, what were the client's three main concerns or objections, and how can we address them?",
    "🎯 What closing strategies are most likely to resonate with this client based on our discussion?",
    "💰 Did the client express any specific preferences or aversions towards pricing models or contract terms?",
    "⏱️ What is the client’s timeline for decision-making, and what factors could accelerate or delay this?",
    "💡 How did the client perceive the value proposition compared to their current solution or competitor offerings?",
]

const SHARED_CALL_QUESTION_SUGGESTIONS = [
    // These are questions from the buyer's perspective
    "📋 Provide a call timeline summary",
    "👥 What are the people and teams involved?",
    "🔑 What were the key points discussed?",
    "🪜 What are the next steps following this call?",
    "💊 How does the solution solve my pain points?",
    "🚧 Give me a summary of the implementation process.",
]

export const QUESTION_SUGGESTIONS = {
    [ResourceType.Calls]: CALL_QUESTION_SUGGESTIONS,
    [ResourceType.Companies]: COMPANY_QUESTION_SUGGESTIONS,
    [ResourceType.SharedCalls]: SHARED_CALL_QUESTION_SUGGESTIONS,
}
