import { ReactNode } from "react"

export function LightEmptyArea({ children }: { children: ReactNode }) {
    return (
        <div className="text-sm h-40 rounded-xl flex items-center justify-center text-center text-gray-600 bg-gray-100 p-4">
            {children}
        </div>
    )
}

export function DarkEmptyArea({ children }: { children: ReactNode }) {
    return (
        <div className="text-sm h-40 rounded-xl flex items-center justify-center text-center text-gray-700 bg-gray-200 p-4">
            {children}
        </div>
    )
}
