import { useMemo, useState } from "react"
import { GLOBAL_QUESTION_SUGGESTIONS } from "./question-answering/question-suggestions"
import { Thread } from "./question-answering/types/ThreadTypes"
import _ from "lodash"
import { getFormattedDateTime } from "../utils/datetime"
import { useQuery } from "@tanstack/react-query"
import { useThreadsApi } from "./question-answering/hooks/useThreadsApi"

const NUMBER_OF_SUGGESTIONS = 3
const DEFAULT_VISIBLE_THREADS = 5

interface SearchEmptyProps {
    selectThread: (thread: Thread) => void
    startThread: (message: string) => void
}

export function SearchEmptyState(props: SearchEmptyProps) {
    const { getThreads } = useThreadsApi()
    const [expandHistory, setExpandHistory] = useState(false)

    const suggestions = useMemo(() => {
        return _.sampleSize(GLOBAL_QUESTION_SUGGESTIONS, NUMBER_OF_SUGGESTIONS)
    }, [])

    const { data: allThreads = [] } = useQuery({
        queryKey: ["organization/askglyphic/threads"],
        queryFn: getThreads,
        initialData: [],
    })
    const threads = useMemo(() => {
        return allThreads
            .sort((a, b) => b.created_at_utc.localeCompare(a.created_at_utc))
            .filter((t) => t.messages.length > 0)
    }, [allThreads])

    const visibleThreads = expandHistory
        ? threads
        : threads.slice(0, DEFAULT_VISIBLE_THREADS)

    const showExpandHistory = threads.length > DEFAULT_VISIBLE_THREADS
    return (
        <div className="space-y-8">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 animate-fadeInUp">
                {suggestions.map((question) => (
                    <SuggestedQuestion
                        key={question}
                        question={question}
                        setQuestion={(question) => props.startThread(question)}
                    />
                ))}
            </div>
            {visibleThreads.length > 0 && (
                <div className="space-y-4">
                    <div className="flex justify-between items-center">
                        <span className="text-lg font-semibold text-gray-800">
                            Recently asked questions
                        </span>
                        {showExpandHistory && (
                            <button
                                onClick={() => setExpandHistory(!expandHistory)}
                                className="text-gray-600 hover:text-gray-800 transition-colors"
                            >
                                {expandHistory ? "Show Less" : "Show All"}
                            </button>
                        )}
                    </div>
                    <div className="space-y-3">
                        {visibleThreads.map((thread) => (
                            <PastThread
                                key={thread.id}
                                thread={thread}
                                selectThread={props.selectThread}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

function SuggestedQuestion({
    question,
    setQuestion,
}: {
    question: string
    setQuestion: (question: string) => void
}) {
    return (
        <div
            className="bg-white border border-gray-200 rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow cursor-pointer"
            onClick={() => setQuestion(question)}
        >
            <p className="text-center font-medium text-gray-800">{question}</p>
        </div>
    )
}

function PastThread(props: {
    thread: Thread
    selectThread: (thread: Thread) => void
}) {
    return (
        <div
            key={props.thread.id}
            onClick={() => props.selectThread(props.thread)}
            className="cursor-pointer bg-white border border-gray-200 p-4 rounded-lg hover:bg-gray-50 transition-colors shadow-sm animate-fadeInUp"
        >
            <div className="flex justify-between items-center">
                <p className="text-gray-800 flex-grow">
                    {props.thread.messages[0]?.content}
                </p>
                <span className="text-xs text-gray-500 ml-2 whitespace-nowrap">
                    {getFormattedDateTime(props.thread.created_at_utc)}
                </span>
            </div>
        </div>
    )
}
