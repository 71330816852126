import { MouseEvent } from "react"
import { faInfoCircle, faWarning } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export enum NotificationType {
    Success,
    Error,
    Warning,
}

export interface NotificationProps {
    notificationId: string
    highlight: string
    message: string
    type: NotificationType
    onClose: (notificationId: string) => void
}

export function Notification(props: NotificationProps) {
    const handleClose = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        props.onClose(props.notificationId)
    }

    let className = "border-green-400 border bg-green-50 text-green-700"
    let icon = faInfoCircle
    if (props.type === NotificationType.Error) {
        className = "border-red-400 border bg-red-50 text-red-700"
        icon = faWarning
    } else if (props.type === NotificationType.Warning) {
        className = "border-yellow-400 border bg-yellow-50 text-yellow-700"
        icon = faWarning
    }

    return (
        <div
            className={
                "flex flex-row justify-between items-center p-4 gap-4 rounded-md max-w-2xl " +
                className
            }
        >
            <FontAwesomeIcon icon={icon} className="text-lg" />
            <p className="flex-shrink break-words overflow-hidden first-letter:capitalize text-lg max-w-fit">
                {props.highlight && <strong>{props.highlight} &nbsp;</strong>}
                {props.message}
            </p>
            <button
                className="hover:font-bold bg-transparent"
                onClick={handleClose}
            >
                &#10005;
            </button>
        </div>
    )
}
