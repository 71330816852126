import { mergeQueryKeys } from "@lukemorales/query-key-factory"
import { callQueries } from "./calls"
import { upcomingCallQueries } from "./upcomingCalls"
import { userQueries } from "./users"
import { crmQueries } from "./crm"
import { organizationQueries } from "./organization"
import { callTagQueries } from "./callTags"

export const queries = mergeQueryKeys(
    organizationQueries,
    callQueries,
    callTagQueries,
    upcomingCallQueries,
    userQueries,
    crmQueries
)
