import Badge from "../common/Badge"
import { ICompanyPreview } from "../../types/Call"

interface CompanyListProps {
    companies: ICompanyPreview[]
    className?: string
}

export function CompanyList({ companies, className }: CompanyListProps) {
    return (
        <div
            className={`flex flex-wrap gap-1 overflow-hidden ${
                className || ""
            }`}
        >
            {companies.map((company: ICompanyPreview) => (
                <Company key={company.id} company={company} />
            ))}
        </div>
    )
}

interface CompanyProps {
    company: ICompanyPreview
}

export function Company({ company }: CompanyProps) {
    const href = `/companies/${company.domain}`

    return (
        <Badge
            className="text-sm text-gray-800 border-gray-200 bg-gray-50 hover:bg-gray-200 border transition-colors"
            label={company.name || company.domain}
            image_url={company.image_url}
            href={href}
        />
    )
}
