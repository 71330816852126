import { useCallback, useEffect, useState } from "react"
import { useNotification } from "../../providers/NotificationProvider"
import { IResource } from "./types/ResourceTypes"
import axios from "axios"
import { NotificationType } from "../common/Notifcations"
import { faTrashCan as deleteIcon } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import FileUploader from "./FileUpload"
import { downloadCaseStudy } from "../call-prep/CaseStudies"
import ContentLoader from "react-content-loader"
import { SimpleCard } from "../common/SimpleCard"

function ResourceItem(props: {
    resource: IResource
    handleResourceClick: (resource?: IResource) => void
    handleResourceDelete: (resource?: IResource) => void
}) {
    const [showDelete, setShowDelete] = useState(false)

    // Set showDelete to true when the mouse enters the item
    const handleMouseEnter = useCallback(() => {
        setShowDelete(true)
    }, [])

    // Set showDelete to false when the mouse leaves the item
    const handleMouseLeave = useCallback(() => {
        setShowDelete(false)
    }, [])

    useEffect(() => {
        document.title = "Resources - " + process.env.REACT_APP_DOCUMENT_TITLE

        return () => {
            // Reset the title when the component unmounts
            document.title = process.env.REACT_APP_DOCUMENT_TITLE!
        }
    })

    return (
        <li
            key={props.resource.id}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="flex items-center space-x-2 py-2 underline cursor-pointer"
        >
            <FontAwesomeIcon
                icon={deleteIcon}
                onClick={() => props.handleResourceDelete(props.resource)}
                className={`text-red-600 hover:text-red-400 transition-all ${
                    showDelete ? "opacity-100" : "opacity-0"
                }`}
            />
            <span
                onClick={() => props.handleResourceClick(props.resource)}
                className="font-medium text-gray-700 hover:text-gray-900"
            >
                {props.resource.name}
            </span>
        </li>
    )
}

interface ResourceListProps {
    resources: IResource[]
    refreshResourceList: () => void
    isLoading: boolean
}

export default function ResourceList(props: ResourceListProps) {
    const { addNotification } = useNotification()
    const refreshResourceList = props.refreshResourceList

    const [isDeleting, setIsDeleting] = useState<boolean>(false)
    const handleResourceClick = useCallback(async (resource?: IResource) => {
        if (!resource) return
        downloadCaseStudy(resource.id)
    }, [])

    const handleResourceDelete = useCallback(
        async (resource?: IResource) => {
            if (!resource) return

            try {
                setIsDeleting(true)
                const response = await axios.delete(
                    `${
                        process.env.REACT_APP_API_DOMAIN
                    }/case-studies/${encodeURIComponent(resource.id)}`
                )
                console.log("Deleted resource: ", {
                    resource: resource.id,
                    response: response.data,
                })
                refreshResourceList()
            } catch (error) {
                addNotification(
                    "",
                    "Error occurred while deleting resources",
                    NotificationType.Error
                )
            }
            setIsDeleting(false)
        },
        [addNotification, refreshResourceList]
    )

    const showLoading = props.isLoading || isDeleting

    return (
        <SimpleCard className="p-4">
            <div className="text-base flex flex-col gap-4 w-full">
                <p className="text-lg font-bold">Document List</p>
                {showLoading && <LoadingResources />}
                {!showLoading && (
                    <div>
                        <div className="max-h-96 overflow-y-auto">
                            <ul className="space-y-1">
                                {props.resources.map((resource: IResource) => (
                                    <ResourceItem
                                        key={resource.id}
                                        resource={resource}
                                        handleResourceClick={
                                            handleResourceClick
                                        }
                                        handleResourceDelete={
                                            handleResourceDelete
                                        }
                                    />
                                ))}
                            </ul>
                        </div>
                        {props.resources.length === 0 && (
                            <div className="flex flex-col p-4 gap-2 items-center text-center text-gray-500">
                                No documents uploaded
                                <FileUploader
                                    onFileUpload={refreshResourceList}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </SimpleCard>
    )
}

function LoadingResources() {
    return (
        <ContentLoader
            title=""
            height="85"
            backgroundColor="#D1D5DB"
            foregroundColor="#E5E7EB"
        >
            <rect x="0" y="10" rx="4" ry="4" width="25%" height="16" />
            <rect x="0" y="35" rx="4" ry="4" width="35%" height="16" />
            <rect x="0" y="60" rx="4" ry="4" width="30%" height="16" />
        </ContentLoader>
    )
}
