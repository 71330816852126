import _ from "lodash"
import { useEffect, useState } from "react"
import { Modal } from "../common/Modal"
import { PrimaryButton } from "../common/Buttons"
import axios from "axios"
import { ResourceType } from "./types/ResourceTypes"
import LoadingSpinner from "../common/LoadingSpinner"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"
import { AccessLevel } from "./types/QuickActionTypes"
import Select from "react-select"
import colors from "tailwindcss/colors"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSparkles } from "@fortawesome/pro-solid-svg-icons"

interface SimpleQuickActionModalProps {
    isOpen: boolean
    onClose: () => void
    saveQuickAction: (
        title: string,
        accessLevel: AccessLevel,
        question: string
    ) => Promise<void>
}

interface NewQuickActionModalProps extends SimpleQuickActionModalProps {
    threadId: string | null
    resourceType: ResourceType
}

export function NewQuickActionModal(props: NewQuickActionModalProps) {
    const { addNotification } = useNotification()

    const [loading, setLoading] = useState<boolean>(props.threadId !== null)
    const [title, setTitle] = useState("")
    const [question, setQuestion] = useState("")

    useEffect(() => {
        async function fetchSuggestion() {
            if (!props.threadId) return

            setLoading(true)
            try {
                const { data } = await axios.get(
                    `${process.env.REACT_APP_API_DOMAIN}/templates/${props.threadId}/actions/${props.resourceType}`
                )

                setTitle(data.title)
                setQuestion(data.question)
            } catch (error) {
                console.error("Failed to fetch action suggestion: ", error)
                addNotification(
                    "Failed to create an action. Please try again later.",
                    `${error}`,
                    NotificationType.Error
                )
            } finally {
                setLoading(false)
            }
        }

        fetchSuggestion()
    }, [props.threadId, props.resourceType, addNotification])

    if (loading) {
        return (
            <Modal isOpen={props.isOpen} onClose={props.onClose}>
                <div className="bg-gray-50 m-auto max-w-5xl rounded-lg border flex flex-col p-12 space-y-6">
                    <div className="relative mt-4">
                        <LoadingSpinner />
                    </div>
                    <span className="text-base text-gray-500">
                        Should take less than a minute
                    </span>
                </div>
            </Modal>
        )
    }

    return (
        <QuickActionModal
            isOpen={props.isOpen}
            title={title}
            question={question}
            accessLevel={AccessLevel.Personal}
            actionVerb="create"
            onClose={props.onClose}
            saveQuickAction={props.saveQuickAction}
            showCreatedWithoutThreadWarning={!props.threadId}
        />
    )
}

interface EditQuickActionModalProps extends SimpleQuickActionModalProps {
    title: string
    accessLevel: AccessLevel
    question: string
}

export function EditQuickActionModal(props: EditQuickActionModalProps) {
    return (
        <QuickActionModal
            isOpen={props.isOpen}
            title={props.title}
            question={props.question}
            accessLevel={props.accessLevel}
            actionVerb="update"
            onClose={props.onClose}
            saveQuickAction={props.saveQuickAction}
        />
    )
}

interface QuickActionModalProps extends EditQuickActionModalProps {
    actionVerb: string
    showCreatedWithoutThreadWarning?: boolean
}

function QuickActionModal(props: QuickActionModalProps) {
    const [title, setTitle] = useState(props.title)
    const [accessLevel, setAccessLevel] = useState<AccessLevel>(
        props.accessLevel
    )
    const [question, setQuestion] = useState(props.question)

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        setTitle(e.target.value)

    const handleQuickActionChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ) => setQuestion(e.target.value)

    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
            <div className="bg-gray-50 m-auto max-w-5xl rounded-lg border flex flex-col">
                <div className="flex flex-col p-8 space-y-8">
                    <div className="space-y-1">
                        <h2 className="font-bold text-xl">
                            🪄 {_.capitalize(props.actionVerb)} quick action
                        </h2>
                        <h4 className="text-gray-500">
                            Save an Ask Glyphic prompt as a quick action to
                            easily reuse in future conversations.
                        </h4>
                    </div>
                    {props.showCreatedWithoutThreadWarning && (
                        <div className="bg-blue-50 border border-blue-200 p-4 rounded-md space-x-2 text-blue-600">
                            <FontAwesomeIcon icon={faSparkles} />
                            <span>
                                You can also automatically generate a quick
                                action from an Ask Glyphic conversation,
                                <br />
                                by asking a question and then clicking the
                                "Create a quick action" button.
                            </span>
                        </div>
                    )}
                    <div className="flex grow flex-auto flex-col justify-between gap-6">
                        <div className="flex flex-col md:flex-row gap-6">
                            <div className="flex flex-col gap-1">
                                <span className="font-bold">Title</span>
                                <input
                                    type="text"
                                    className="border rounded-md p-2 flex-grow w-64"
                                    value={title}
                                    onChange={handleTitleChange}
                                    placeholder="Enter a title"
                                />
                            </div>
                            <div className="flex flex-col gap-1">
                                <span className="font-bold">Access</span>
                                <AccessLevelSelect
                                    accessLevel={accessLevel}
                                    setAccessLevel={setAccessLevel}
                                />
                            </div>
                        </div>

                        <div className="flex flex-col w-full gap-1">
                            <span className="font-bold">Quick action</span>
                            <textarea
                                className="border rounded-md p-2 flex-grow max-h-screen overflow-auto outline-none w-full h-full"
                                value={question}
                                onChange={handleQuickActionChange}
                                rows={5}
                                cols={76}
                                style={{ resize: "none" }}
                                placeholder="For example: 'Summarize the key takeaways into an email to my colleague. Use an informal tone.'"
                            />
                        </div>
                    </div>
                    <div className="flex justify-end">
                        <PrimaryButton
                            id="frigade-save-quick-action"
                            className="w-fit"
                            onClick={() =>
                                props.saveQuickAction(
                                    title,
                                    accessLevel,
                                    question
                                )
                            }
                            disabled={!title || !question}
                        >
                            {_.capitalize(props.actionVerb)} quick action
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

function AccessLevelSelect(props: {
    accessLevel: AccessLevel
    setAccessLevel: (accessLevel: AccessLevel) => void
}) {
    const accessLevelOptions = [
        { label: "👤 Personal", value: AccessLevel.Personal },
        { label: "👥 Team", value: AccessLevel.Team },
        { label: "🌎 Organization", value: AccessLevel.Organization },
    ]

    return (
        <div className="flex gap-2">
            <Select
                value={accessLevelOptions.find(
                    (option) => option.value === props.accessLevel
                )}
                options={accessLevelOptions}
                className="w-48 text-base"
                onChange={(selectedOption) => {
                    if (!selectedOption) return
                    props.setAccessLevel(selectedOption.value)
                }}
                styles={{
                    control: (provided) => ({
                        ...provided,
                        borderColor: colors.gray[200],
                        borderRadius: "0.375rem",
                    }),
                }}
            />
            <span className="text-sm text-gray-500 max-w-[250px]">
                {props.accessLevel === AccessLevel.Personal &&
                    "This Quick Action will only be visible to yourself"}
                {props.accessLevel === AccessLevel.Team &&
                    "This Quick Action will be visible to anyone in your team"}
                {props.accessLevel === AccessLevel.Organization &&
                    "This Quick Action will be visible to everyone in your Glyphic organization"}
            </span>
        </div>
    )
}
