import { createQueryKeys } from "@lukemorales/query-key-factory"
import axios from "axios"
import { ICallTag } from "../../types/Call"

export const callTagQueries = createQueryKeys("callTags", {
    list: () => ({
        queryKey: ["call_tags"],
        queryFn: async () => {
            const response = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/call_tags`
            )
            return response.data as ICallTag[]
        },
    }),
})
