import axios from "axios"
import { useCallback, useState } from "react"
import ContentLoader from "react-content-loader"
import { CaseStudies, CaseStudyContent } from "../call-prep/CaseStudies"
import AskGlyphicTextBox from "../question-answering/AskGlyphicTextBox"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"
import { SimpleCard } from "../common/SimpleCard"

export default function ResourceSearch() {
    const { addNotification } = useNotification()
    const [query, setQuery] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false)
    const [caseStudies, setCaseStudies] = useState<CaseStudyContent[]>([])

    const handleSearch = useCallback(
        async (query: string): Promise<undefined> => {
            setLoading(true)
            try {
                setCaseStudies(await searchCaseStudies(query))
            } catch (error) {
                addNotification(
                    "",
                    "Error occurred while searching case studies",
                    NotificationType.Error
                )
            }
            setLoading(false)
            // QuestionAnsweringForm interface requires we return undefined
            return undefined
        },
        [addNotification]
    )

    return (
        <SimpleCard className="p-4">
            <div className="text-base flex flex-col space-y-4 w-full">
                <span className="space-y-3">
                    <p className="font-bold">Find any case studies</p>
                    <p className="text-gray-600 text-base">
                        Example: Fintech case studies
                    </p>
                </span>
                <AskGlyphicTextBox
                    setInput={setQuery}
                    submit={handleSearch}
                    input={query}
                    placeholder="Describe what you're looking for"
                />
                {loading && <LoadingContent />}
                {!loading && caseStudies.length > 0 && (
                    <CaseStudies caseStudies={caseStudies} />
                )}
            </div>
        </SimpleCard>
    )
}

async function searchCaseStudies(query: string): Promise<CaseStudyContent[]> {
    const response = await axios.post(
        process.env.REACT_APP_API_DOMAIN + "/case-studies/search/",
        { query }
    )
    const caseStudies = response.data.cases
    console.log("Found case studies: ", caseStudies)
    return caseStudies
}

function LoadingContent() {
    return (
        <div className="bg-white border border-gray-300 rounded-lg p-3">
            <ContentLoader
                title="Documents"
                height="85"
                backgroundColor="#D1D5DB"
                foregroundColor="#E5E7EB"
            >
                <rect x="0" y="10" rx="4" ry="4" width="15%" height="16" />

                <rect x="0" y="35" rx="4" ry="4" width="60%" height="16" />
                <rect x="0" y="60" rx="4" ry="4" width="42%" height="16" />
            </ContentLoader>
        </div>
    )
}
