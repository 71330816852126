import { createQueryKeys } from "@lukemorales/query-key-factory"
import axios from "axios"
import { getOrgSettings } from "../Settings"
import { OrgSettingsResponse } from "../../types/Settings"
import { ICrmField } from "../../components/crm/types/Crm"

export const organizationQueries = createQueryKeys("organization", {
    settings: () => ({
        queryKey: ["organization/settings"],
        queryFn: async () => {
            return (await getOrgSettings()) as OrgSettingsResponse
        },
    }),
    customInsights: () => ({
        queryKey: ["organization/custom_insights"],
        queryFn: async () => {
            const response = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/organization/custom_insights`
            )
            return response.data as ICrmField[]
        },
    }),
})
