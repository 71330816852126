import { Fragment, useEffect } from "react"
import { Table } from "@tanstack/react-table"
import { getPagesToDisplay } from "./utils/getPagesToDisplay"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { useSearchParam } from "../common/hooks/useSearchParam"

interface TablePageNavigatorProps<T extends object> {
    table: Table<T>
}

export function TablePageNavigator<T extends object>({
    table,
}: TablePageNavigatorProps<T>) {
    return (
        <PageNavigator
            currentPage={table.getState().pagination.pageIndex}
            setCurrentPage={table.setPageIndex}
            pageCount={table.getPageCount()}
        />
    )
}

interface PageNumberProps {
    pageNumber: number
    selectedPage: number
    onClick: (newPageSelection: number) => void
}

function PageNumber(props: PageNumberProps) {
    const isSelected = props.pageNumber === props.selectedPage
    const onPageChange = () => props.onClick(props.pageNumber)

    return (
        <button
            className={`border leading-8 w-8 h-8 min-w-min rounded-lg
            border-none transition duration-100 ${
                isSelected ? "bg-gray-200" : "hover:bg-gray-100"
            }`}
            onClick={onPageChange}
        >
            {props.pageNumber + 1}
        </button>
    )
}

export function PageNavigator(props: {
    currentPage: number
    setCurrentPage: (page: number) => void
    pageCount: number
}) {
    const [pageUrlParam, setPageUrlParam] = useSearchParam("page")
    const pageCount = props.pageCount
    const currentPage = props.currentPage
    const setCurrentPage = props.setCurrentPage
    const pages = getPagesToDisplay(currentPage, pageCount)

    const handlePageSelection = (pageNumber: number) => {
        setPageUrlParam((pageNumber + 1).toString())
    }

    useEffect(() => {
        const pageIndex = parseInt(pageUrlParam ?? "") - 1 || 0
        const page = Math.min(Math.max(pageIndex, 0), pageCount - 1)
        const validPageUrlParam = (page + 1).toString()
        if (pageUrlParam !== validPageUrlParam) {
            // If we got here means the page parameter was invalid
            setPageUrlParam(validPageUrlParam)
        }

        if (page !== currentPage) {
            setCurrentPage(page)
        }
    }, [setPageUrlParam, currentPage, pageCount, pageUrlParam, setCurrentPage])

    if (pageCount <= 1) {
        return null
    }

    const canGetPreviousPage = currentPage > 0
    const canGetNextPage = currentPage < pageCount - 1

    return (
        <div className="flex flex-row justify-center items-center gap-2">
            <button
                className={`border rounded-lg py-1 px-3 ${
                    canGetPreviousPage
                        ? "bg-white text-gray-600 transition duration-100 hover:bg-gray-100"
                        : "bg-none text-gray-300"
                }`}
                onClick={() => handlePageSelection(currentPage - 1)}
                disabled={!canGetPreviousPage}
            >
                <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <div className="flex gap-2 w-52 justify-center">
                {pages.map((pageNumber, index) => (
                    <Fragment key={pageNumber}>
                        {/* If current pageNumber is more than 1 greater than
                            the previous one, insert "...".
                            For example, if pages == [0, 3, 4, 5, 9] it means
                            we need to show "..." between 0 and 3 and 5 and 9*/}
                        {index > 0 && pageNumber - pages[index - 1] > 1 && (
                            <div className="leading-8 h-8 min-w-min align-text-bottom">
                                ...
                            </div>
                        )}
                        <PageNumber
                            key={pageNumber}
                            pageNumber={pageNumber}
                            selectedPage={currentPage}
                            onClick={handlePageSelection}
                        />
                    </Fragment>
                ))}
            </div>
            <button
                className={`border rounded-lg py-1 px-3 ${
                    canGetNextPage
                        ? "bg-white text-gray-600 ransition duration-100 hover:bg-gray-100"
                        : "bg-none text-gray-300"
                }`}
                onClick={() => handlePageSelection(currentPage + 1)}
                disabled={!canGetNextPage}
            >
                <FontAwesomeIcon icon={faArrowRight} />
            </button>
        </div>
    )
}
