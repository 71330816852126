import { useEffect, useState } from "react"
import { TabHead } from "../common/Tabs"
import ToggleButton from "../common/ToggleButton"
import { getCacheValue, setCacheValue } from "../../utils/localStorageCache"

enum DateTabs {
    OneWeek = "1 week",
    OneMonth = "1 month",
    ThreeMonths = "3 months",
    SixMonths = "6 months",
    OneYear = "1 year",
    All = "All",
}

const DATE_TABS = Object.values(DateTabs).map((tab) => ({
    type: tab,
    label: tab,
}))

enum UserTabs {
    Everybody = "Everybody",
    MyTeam = "My Team",
}

const USER_TABS = Object.values(UserTabs).map((tab) => ({
    type: tab,
    label: tab,
}))

export const ACTIVITY_DATE_TAB_CACHE_KEY = "activity-filters-date-tab-index"
export const ACTIVITY_USER_TAB_CACHE_KEY = "activity-filters-user-tab-index"

export function ActivityFilters(props: {
    setSinceDate: (date: Date | null) => void
    setShowMyTeam: (showMyTeam: boolean) => void
    includeInternalCalls: boolean
    setIncludeInternalCalls: (includeInternal: boolean) => void
}) {
    const [activeDateTab, setActiveDateTab] = useState(
        getCacheValue(ACTIVITY_DATE_TAB_CACHE_KEY, 0)
    )
    const [activeUsersTab, setActiveUsersTab] = useState(
        getCacheValue(ACTIVITY_USER_TAB_CACHE_KEY, 0)
    )
    const setSinceDate = props.setSinceDate
    const setShowMyTeam = props.setShowMyTeam

    useEffect(() => {
        const sinceDate = calculateActivitySinceDate(activeDateTab)
        setSinceDate(sinceDate)
    }, [activeDateTab, setSinceDate])

    useEffect(() => {
        setShowMyTeam(showMyTeamFromTab(activeUsersTab))
    }, [activeUsersTab, setShowMyTeam])

    return (
        <div className="flex flex-row justify-between items-center">
            <TabHead
                tabs={DATE_TABS}
                activeTab={activeDateTab}
                onTabChange={(index: number) => {
                    setActiveDateTab(index)
                    setCacheValue(ACTIVITY_DATE_TAB_CACHE_KEY, index)
                }}
            />
            <div className="flex items-center space-x-4">
                <TabHead
                    tabs={USER_TABS}
                    activeTab={activeUsersTab}
                    onTabChange={(index: number) => {
                        setActiveUsersTab(index)
                        setCacheValue(ACTIVITY_USER_TAB_CACHE_KEY, index)
                    }}
                />
                <div className="flex items-center space-x-2">
                    <ToggleButton
                        checked={props.includeInternalCalls}
                        onChange={props.setIncludeInternalCalls}
                    />
                    <span className="text-base">Include internal calls</span>
                </div>
            </div>
        </div>
    )
}

export function showMyTeamFromTab(activeUsersTab: number): boolean {
    return USER_TABS[activeUsersTab].type === UserTabs.MyTeam
}

export function calculateActivitySinceDate(activeDateTab: number): Date | null {
    let sinceDate: Date | null = new Date()
    // set time to midnight, so that the same date value is returned each time this is called
    sinceDate.setHours(0, 0, 0, 0)

    switch (DATE_TABS[activeDateTab].type) {
        case DateTabs.OneWeek:
            sinceDate.setDate(sinceDate.getDate() - 7)
            break
        case DateTabs.OneMonth:
            sinceDate.setMonth(sinceDate.getMonth() - 1)
            break
        case DateTabs.ThreeMonths:
            sinceDate.setMonth(sinceDate.getMonth() - 3)
            break
        case DateTabs.SixMonths:
            sinceDate.setMonth(sinceDate.getMonth() - 6)
            break
        case DateTabs.OneYear:
            sinceDate.setFullYear(sinceDate.getFullYear() - 1)
            break
        case DateTabs.All:
            sinceDate = null
            break
        default:
            console.error("Invalid date tab value")
    }
    return sinceDate
}
